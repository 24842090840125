import { PlayIcon } from "@heroicons/react/solid";
import React from "react";

export default function PartnerFred() {
    return (
        <section class="border-t border-b border-gray-100 py-24 bg-white overflow-hidden">
            <div class="container px-4 mx-auto">
                <div class="flex">
                    <div class="xl:w-2/3 relative overflow-hidden rounded-3xl">
                        <img class="transform w-full object-cover h-[500px]" src={require("../../../assets/images/baba-from-andie-on-the-fred-vanvleet-podcast.jpg")} alt="" />
                        <div class="absolute left-0 top-0 px-14 py-11 w-3/4 md:w-1/2 bg-black bg-opacity-50 overflow-y-auto h-full" >
                        <svg class="mb-20" width="47" height="36" viewbox="0 0 47 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 36V25.6999C0 22.7377 0.554721 19.6578 1.66416 16.46C2.80722 13.2286 4.35372 10.1823 6.30365 7.32118C8.2872 4.42637 10.5061 1.98598 12.9603 0L21.4324 5.5035C19.4489 8.4993 17.7847 11.6297 16.4399 14.8948C15.1288 18.1262 14.49 21.6943 14.5236 25.5989V36H0ZM25.5676 36V25.6999C25.5676 22.7377 26.1223 19.6578 27.2318 16.46C28.3748 13.2286 29.9213 10.1823 31.8712 7.32118C33.8548 4.42637 36.0737 1.98598 38.5279 0L47 5.5035C45.0165 8.4993 43.3523 11.6297 42.0075 14.8948C40.6964 18.1262 40.0576 21.6943 40.0912 25.5989V36H25.5676Z" fill="#4F46E5"></path>
                        </svg>
                        <h3 class="mb-8 text-3xl font-productsans-medium text-white">I love how Andie is helping restaurants get the customers they need every day.</h3>
                        <h4 class="mb-1 text-blue-600 font-productsans-black">Fred Vanvleet</h4>
                        <p class="text-gray-400 font-roboto-medium text-xs">NBA Champion</p>
                        </div>
                    </div>
                    <div className="hidden lg:inline-flex xl:pl-40 xl:w-1/3">
                        <div className="flex flex-col justify-between pt-5">
                            <div>
                            <h5 className="font-productsans-medium text-right">Listen to Andie CEO, Baba Ajayi, on Fred Vanvleet's podcast <italic>Bet on Yourself</italic></h5>

                            <div className="flex justify-end">
                            <a href="https://open.spotify.com/episode/6sXGquCGboVxrKMV2bzgzd?si=776de3a1ffde400c" target="_blank" rel="noreferrer" className="mt-8 bg-blue-600 inline-flex text-right items-center px-2 h-7 w-20 rounded-full">
                                <PlayIcon className="h-5 text-white" />
                                <h6 className="text-sm font-productsans-medium ml-1.5 text-white">Play</h6>
                            </a>
                            </div>
                            </div>
                            <span class="inline-block mb-4 text-right text-xs text-blue-600 font-roboto-bold uppercase tracking-widest">Podcast</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}