import React from "react";
import PartnerStory from "./items/PartnerStory";

export default function PartnersTestimonials() {
  const stories = [
    {
      "cuisine": "Juices",
      "image": require("../../../assets/images/reslogos/testimonials/ase-juicery-full.jpg"),
      "logo": require("../../../assets/images/reslogos/ase-logo.webp"),
      "image1": require("../../../assets/images/reslogos/testimonials/asejuicery1.jpeg"),
      "image2": require("../../../assets/images/reslogos/testimonials/asejuicery2.jpg"),
      "image3": require("../../../assets/images/reslogos/testimonials/asejuicery3.jpg"),
      "image4": require("../../../assets/images/reslogos/testimonials/asejuicery4.jpg"),
      "specialties": "Corporate catering",
      "partner_since": "Oct. 2022",
      "success": "Ase Juicery went from 0 to 150 corporate orders every month.",
      "introduction": "Ase Juicery is a black-owned juicery and cafe in Toronto that's well-known in the neighborhood for its fresh, small-batch, cold-pressed juices in a variety of flavors. While the business had a loyal local following, the team struggled to secure corporate bookings due to limited resources and time.",
      "restaurant": "Ase Juicery",
      "location": "Toronto",
      "headache": "Before we came across Andie, we were struggling to get corporate organizations where we can promote our healthy juices. It was mostly people who knew us who would recommend us to the companies they work for.",
      "quote": "We are very happy with Andie CGE. They have given us another outlet to get our tasty, healthy juices in front of a larger audience! We are constantly looking for opportunities to get into corporate spaces and Andie does just that!.",
      "owner": "Corey Glass Smith",
      "title": "Owner",
      "specialty": "Ase Juicery and Cafe specializes in fresh small batch cold-pressed juices.",
      "challenges": "Breaking into the corporate catering market is tough for small business owners with fewer resources than larger restaurants that have dedicated catering and event management teams. As such, reaching out to potential corporate customers was a major barrier which meant any of the previous orders Ase Juicery was fulfilling came from those who had known it locally.",
      "solution": "Andie came to the rescue by providing Ase Juicery with a platform to access corporate opportunities and connect with organizations beyond its local following. The platform’s execution process also provided a seamless set of steps to manage and streamline their corporate catering process. The user-friendly platform made it easy for Ase Juicery to receive bid requests, submit responses, process bulk orders from multiple, track payments, and manage deliveries. With Andie's support, Ase Juicery was able to focus on producing high-quality, delicious food and drink while simultaneously expanding its corporate offerings.",
      "results": [
        "Increased revenue from corporate orders",
        "Improved efficiency in managing bulk orders",
        "Boosted reputation among corporate clients"
      ],
      "solution": "With Andie's help, Ase Juicery was able to expand its reach and secure corporate bookings. The streamlined process allowed the business to handle large orders with ease, leading to increased revenue and a growing reputation in the corporate catering market. Ase Juicery was able to provide its signature fresh juices and healthy snacks to a wider audience, expanding its loyal customer base and generating more repeat business.",
      "highlight": "350 orders in 3 months.",
      "conclusion": "Thanks to Andie, Ase Juicery was able to overcome the challenges of breaking into the corporate catering market and establish itself as a leading provider of fresh, healthy food and drink. The team is now able to focus on what they do best, creating delicious and healthy juices and snacks, and scale its corporate services rapidly. With Andie's support, Ase Juicery is poised for continued success in the future."
    },
    {
      "cuisine": "Filipino",
      "image": require("../../../assets/images/reslogos/testimonials/casa-manila-full.jpg"),
      "logo": require("../../../assets/images/reslogos/manila-logo.png"),
      "image1": require("../../../assets/images/reslogos/testimonials/manila1.jpg"),
      "image2": require("../../../assets/images/reslogos/testimonials/manila2.jpg"),
      "image3": require("../../../assets/images/reslogos/testimonials/manila3.jpg"),
      "image4": require("../../../assets/images/reslogos/testimonials/manila4.jpg"),
      "specialties": "Corporate catering, Corporate dining",
      "partner_since": "Feb. 2023",
      "success": "Casa Manila gets connected with organizations that support women-led businesses.",
      "introduction": "Casa Manila is a chic, women-owned Filipino restaurant that takes pride in its flavorful dishes, especially its adobo chicken, which is free from MSG, artificial flavors, and preservatives. The restaurant was given a chance to showcase its delicious food to corporate organizations that support women-led businesses during International Women's Month.",
      "restaurant": "Casa Manila",
      "location": "Toronto",
      "owned_by": "Mila",
      "specialty": "Casa Manila is known for its flavorful adobo chicken, free from MSG, artificial flavors, and preservatives.",
      "challenges": "Expanding into the corporate catering market can be difficult for small restaurants without the resources to manage event management teams and handle bulk orders. It is even harder if you are a female-led business that has to be selective on how to deploy resources. Yet there are countless of organizations who are waiting to support businesses led by women and provide the business they need to thrive.",
      "solution": "With Andie's expertise, Casa Manila was able to break into the corporate catering market and establish connections with organizations that support women-led businesses. Andie provided the restaurant with a streamlined platform that made it easy to manage and fulfill large orders, process payments, and handle deliveries. Casa Manila was able to focus on what they do best: crafting delicious, flavorful dishes that delight their customers.",
      "results": [
        "Increased revenue from corporate orders",
        "Expanded customer base and brand recognition",
        "Established connections with organizations that support women-led businesses"
      ],
      "highlight": "Turned 1 CGE order with 205 guests into 7 new requests from attendees.",
      "conclusion": "Thanks to Andie, Casa Manila was able to overcome the challenges of expanding into the corporate catering market and establish itself as a leading provider of authentic Filipino cuisine. The team is now able to showcase their flavorful dishes to a wider audience and establish valuable connections in the business world. With Andie's support, Casa Manila is set for continued growth and success in the future as it is on pace to increase its revenue from corporate orders by 200%."
    },
    {
      "cuisine": "Mexican",
      "image": require("../../../assets/images/reslogos/testimonials/burrito-boyz-full.jpg"),
      "logo": require("../../../assets/images/reslogos/burritoboyz-logo.jpg"),
      "image1": require("../../../assets/images/reslogos/testimonials/burritoboyz1.jfif"),
      "image2": require("../../../assets/images/reslogos/testimonials/burritoboyz2.jpg"),
      "image3": require("../../../assets/images/reslogos/testimonials/burritoboyz3.jpg"),
      "image4": require("../../../assets/images/reslogos/testimonials/burritoboyz4.jpg"),
      "specialties": "Corporate catering",
      "partner_since": "Apr. 2023",
      "success": "Andie helps new Burrito Boyz owners find their footing.",
      "restaurant": "Burrito Boyz",
      "location": "Toronto",
      "owned_by": "",
      "specialty": "Burrito Boyz is a popular and renowned burrito and Mexican food chain.",
      "introduction": "Burrito Boyz is a household name in Toronto, known for its mouth-watering burritos and authentic Mexican cuisine. In April 2023, Andie began working with the new owners who took over one of the flagship locations downtown. The team faced many challenges during the transition, but Andie provided the support they needed to ease into the business.",
      "challenges": "Starting a new business is never easy, and the Burrito Boyz team faced a number of challenges during the transition period. One of the biggest hurdles was attracting corporate guests to the restaurant, as the new owners lacked the connections and resources to secure large orders.",
      "solution": "Andie stepped in to help drive corporate guest revenue, connecting Burrito Boyz with a wide network of potential customers. With Andie's support, the team was able to secure over 400 corporate guests, generating the revenue they needed to keep the business running smoothly. Andie's streamlined process made it easy for Burrito Boyz to manage large orders, from initial bid requests to delivery and payment.",
      "results": [
        "400 corporate guests secured within the first week",
        "Increased revenue and stability during the early days of the new ownership",
        "A growing reputation as a leading provider of delicious, authentic Mexican cuisine"
      ],
      "highlight": "400 corporate guests secured within the first week",
      "conclusion": "Thanks to Andie's help, the new owners of Burrito Boyz were able to overcome the challenges of starting a new business and establish themselves as a leading provider of delicious, authentic Mexican cuisine in Toronto. With a growing reputation and a loyal customer base, Burrito Boyz is poised for continued success in the future."
    },
    {
      "cuisine": "Poke Bowls",
      "image": require("../../../assets/images/reslogos/testimonials/ono-poke-full.jpg"),
      "logo": require("../../../assets/images/reslogos/ono-poke.png"),
      "image1": require("../../../assets/images/reslogos/testimonials/onopoke1.webp"),
      "image2": require("../../../assets/images/reslogos/testimonials/onepoke-2.webp"),
      "image3": require("../../../assets/images/reslogos/testimonials/onpoke-3.png"),
      "image4": require("../../../assets/images/reslogos/testimonials/onopoke-4.jpeg"),
      "specialties": "Corporate catering",
      "partner_since": "Oct. 2022",
      "success": "Ono Poke gets connected to repeat business and exposure to more organizations.",
      "introduction": "Ono Poke is a restaurant in Toronto that specializes in delicious poke bowls. While they had a loyal local following, they wanted to break into the corporate catering business. After each booking, at least 25 people in the audience requested coupon codes for them to try out the meals during their spare time.",
      "restaurant": "Ono Poke",
      "location": "Toronto",
      "owned_by": "",
      "specialty": "Ono Poke specializes in delicious poke bowls.",
      "challenges": "Breaking into the corporate catering market was a challenge for Ono Poke as there are many poke bowl providers in the city. Furthermore, corporate organizations are often fixated on ordering from restaurants that have a presence in their food courts.",
      "solution": "Andie came to the rescue by providing Ono Poke with a platform to access corporate opportunities and connect with organizations beyond its local following. The platform’s execution process also provided a seamless set of steps to manage and streamline their corporate catering process. The user-friendly platform made it easy for Ono Poke to receive bid requests, submit responses, process bulk orders from multiple organizations, track payments, and manage deliveries. With Andie's support, Ono Poke was able to focus on producing high-quality, delicious food while simultaneously expanding its corporate offerings.",
      "results": [
        "Increased revenue from corporate orders",
        "Gained repeat orders from at least three organizations with its first five orders",
        "Increased brand exposure and awareness"
      ],
      "highlight": "At least 25 people in the audience requested coupon codes after each booking.",
      "conclusion": "Thanks to Andie, Ono Poke was able to overcome the challenges of breaking into the corporate catering market and establish itself as a leading provider of delicious poke bowls. The team is now able to focus on what they do best, creating high-quality, healthy and flavorful meals, and scale its corporate services rapidly. With Andie's support, Ono Poke is now on the radar of over 1,000 corporates." 
    },
    {
      "cuisine": "Lebanese",
      "image": require("../../../assets/images/reslogos/testimonials/lebanese-garden-full.jpg"),
      "logo": require("../../../assets/images/reslogos/lebanese-garden-logo.png"),
      "image1": require("../../../assets/images/reslogos/testimonials/lebanesegarden-1.jpg"),
      "image2": require("../../../assets/images/reslogos/testimonials/lebanesegarden-2.avif"),
      "image3": require("../../../assets/images/reslogos/testimonials/lebanesegarden-3.jpg"),
      "image4": require("../../../assets/images/reslogos/testimonials/lebanesegarden-4.jpg"),
      "specialties": "Corporate catering, Corporate dining",
      "partner_since": "Jan. 2023",
      "success": "Lebanese Garden grows corporate guest experiences with the help of Andie.",
      "introduction": "Lebanese Garden is a top-rated Lebanese restaurant that has built a reputation for providing high-quality food and a premium dining experience. While the restaurant had a loyal local following and was already receiving corporate orders, the team needed a partner to take their corporate catering to the next level.",
      "restaurant": "Lebanese Garden",
      "location": "Toronto",
      "owned_by": "",
      "specialty": "Lebanese Garden is known for their delicious Lebanese cuisine and premium dining experience.",
      "challenges": "The restaurant faced challenges in growing their corporate orders due to the competition from other Lebanese food providers and the tendency of corporate clients to order from the food courts within their buildings.",
      "solution": "Andie provided Lebanese Garden with a platform to access more corporate opportunities and connect with organizations beyond its local following. The platform's streamlined process made it easy for the restaurant to receive bid requests, submit responses, process bulk orders from multiple clients, track payments, and manage deliveries. With Andie's support, Lebanese Garden was able to focus on providing high-quality food and a premium dining experience while simultaneously expanding its corporate offerings.",
      "results": [
        "Significant growth in corporate orders",
        "Increased revenue from corporate catering",
        "Boosted reputation among corporate clients"
      ],
      "highlight": "26% growth in corporate orders within three months",
      "conclusion": "Thanks to Andie, Lebanese Garden was able to overcome the challenges of growing their corporate catering business and establish themselves as a leading provider of high-quality Lebanese cuisine and premium dining experience. The team is now able to focus on what they do best, creating delicious food and providing an exceptional dining experience, while expanding their corporate services rapidly. With Andie's support, Lebanese Garden is poised for continued success in the future."
    },
    {
      "restaurant": "La Prep",
      "location": "Downtown Toronto",
      "specialty": "La Prep specializes in healthy breakfast and lunch options, using fresh and quality ingredients to create delicious and nutritious meals.",
      "success": "Thanks to Andie's help, La Prep now has a consistent pipeline of corporate orders.",
      "introduction": "La Prep is a staple in downtown Toronto, known for its fresh and healthy breakfast and lunch options. While the restaurant had a loyal following, it struggled to tap into the corporate catering market and reach new customers because it is located in a competitive location with many options.",
      "owned_by": "",
      "cuisine": "Healthy meals",
      "image": require("../../../assets/images/reslogos/testimonials/laprep-1.jpg"),
      "logo": require("../../../assets/images/reslogos/laprep-logo.png"),
      "image1": require("../../../assets/images/reslogos/testimonials/laprep-1.jpg"),
      "image2": require("../../../assets/images/reslogos/testimonials/laprep-2.jpg"),
      "image3": require("../../../assets/images/reslogos/testimonials/laprep-3.jpeg"),
      "image4": require("../../../assets/images/reslogos/testimonials/laprep-4.avif"),
      "specialties": "Corporate catering",
      "partner_since": "Mar. 2023",
      "challenges": "One of the biggest challenges for La Prep was reaching new corporate clients and expanding their customer base. While the restaurant had a great reputation in the local community, they struggled to break into the corporate catering market and compete with other established players in the industry.",
      "solution": "That's where Andie came in. By providing La Prep with a platform to access corporate opportunities and connect with organizations beyond their local following, Andie helped the restaurant expand their reach and secure new bookings. The user-friendly platform made it easy for La Prep to receive bid requests, submit responses, process bulk orders, track payments, and manage deliveries. With Andie's support, La Prep was able to focus on producing high-quality, delicious food while simultaneously expanding its corporate offerings.",
      "results": [
        "Consistent pipeline of 200+ orders",
        "Increased revenue from corporate orders",
        "Expanded customer base",
        "Improved efficiency in managing bulk orders"
      ],
      "highlight": "La Prep now has a consistent pipeline of 200+ orders.",
      "conclusion": "Thanks to Andie, La Prep has been able to overcome the challenges of breaking into the corporate catering market and establish itself as a leading provider of high-quality, delicious food for corporate events. With a consistent pipeline of 200+ orders and a growing reputation in the industry, La Prep is poised for continued success with increased revenue and new customers added to its audience."
    },
    {
      "restaurant": "Si Lom Thai",
      "location": "Toronto",
      "introduction": "Si Lom Thai is a renowned restaurant in Toronto known for its warm and delicious Thai cuisine. While the restaurant had a loyal following, they were struggling to expand their corporate catering offerings and reach new customers. That's where Andie came in to help.",
      "owned_by": "",
      "specialty": "Serving warm and delicious Thai cuisine, Si Lom thai is a staple of Church street in Toronto and the items on the menu always leave you asking for more.",
      "cuisine": "Thai",
      "image": require("../../../assets/images/reslogos/testimonials/silom-full.webp"),
      "logo": require("../../../assets/images/reslogos/silom-logo.png"),
      "image1": require("../../../assets/images/reslogos/testimonials/silom1.webp"),
      "image2": require("../../../assets/images/reslogos/testimonials/silom2.jpg"),
      "image3": require("../../../assets/images/reslogos/testimonials/silom3.jpg"),
      "image4": require("../../../assets/images/reslogos/testimonials/silom4.png"),
      "specialties": "Corporate catering, Corporate dining",
      "partner_since": "Dec. 2022",
      "challenges": "One of the biggest challenges for Si Lom Thai was expanding their corporate ordering program and reaching new customers beyond their existing base. Many organizations were fixated on ordering from restaurants in their food courts and they had a hard time standing out in a crowded market.",
      "solution": "Andie provided Si Lom Thai with a platform to expand their corporate catering offerings and connect with new customers. The platform made it easy for Si Lom Thai to receive bid requests, submit responses, process bulk orders, track payments, and manage deliveries. Andie also helped Si Lom Thai to market their offerings to a wider audience and establish a strong reputation in the industry.",
      "results": [
        "Expanded corporate ordering program",
        "Increased revenue from corporate orders",
        "Established strong reputation in the industry"
      ],
      "highlight": "Si Lom Thai expanded its corporate ordering program.",
      "success": "Andie opens doors to new corporate clients for Si Lom Thai.",
      "conclusion": "Thanks to Andie's support, Si Lom Thai was able to expand its corporate catering offerings and establish a strong reputation in the industry. They are now well-positioned to continue growing their business and serving delicious Thai cuisine to new customers."
    },
    {
      "restaurant": "JSan",
      "location": "Toronto",
      "introduction": "JSan is widely recognized as one of the best sushi restaurants in Toronto, known for its fresh and delicious sushi offerings. However, catering to corporate clients presented a unique challenge, as the delicate nature of sushi meant that it needed to be delivered as fresh as possible.",
      "owned_by": "",
      "specialty": "Sushi",
      "cuisine": "Japanese",
      "image": require("../../../assets/images/reslogos/testimonials/jsan1.jpg"),
      "logo": require("../../../assets/images/reslogos/jsan-logo.jfif"),
      "image1": require("../../../assets/images/reslogos/testimonials/jsan1.jpg"),
      "image2": require("../../../assets/images/reslogos/testimonials/jsan2.jpg"),
      "image3": require("../../../assets/images/reslogos/testimonials/jsan3.webp"),
      "image4": require("../../../assets/images/reslogos/testimonials/jsan4.webp"),
      "specialties": "Corporate catering, Corporate dining",
      "partner_since": "Nov. 2022",
      "challenges": "The challenge for JSan was to provide a catered experience that lived up to the high standards of their restaurant, while ensuring that the sushi remained fresh and delicious during transportation. Finding a solution to this challenge was crucial in order to expand their corporate catering offerings and compete with other established players in the industry.",
      "solution": "Andie provided JSan with the perfect solution to their challenge. With a user-friendly platform that streamlines the entire catering process, from order placement to delivery, Andie made it easy for JSan to provide their customers with a seamless and hassle-free experience. Andie's team also worked closely with JSan to ensure that the delicate sushi was transported and delivered in the best possible way, preserving its freshness and quality.",
      "results": [
        "Delighted CGE customers",
        "First 5.0 rating on Andie",
        "Increased revenue from corporate orders",
        "Expanded customer base"
      ],
      "highlight": "JSan continues to delight with every CGE order and earned the first 5.0 rating on Andie.",
      "success": "JSan overcomes the challenge of delivering fresh sushi for catering orders, and successfully expands its corporate catering offerings.",
      "conclusion": "With Andie's help, JSan was able to maintain the high standards of their restaurant in their catering offerings, and expand their reach in the corporate catering market. With delighted CGE customers and the first 5.0 rating on Andie, JSan is now well-positioned for continued success and growth in the future."
    }
  ]
  return (
    <section class="py-10overflow-hidden">
      <div class="container mx-auto px-4">
        <div class="py-24 px-8 rounded-3xl">
          <div class="max-w-7xl mx-auto">
            <div class="mb-10 md:max-w-2xl"><span class="inline-block mb-4 text-xs text-blue-600 font-roboto-medium uppercase tracking-widest">How Andie makes a difference</span>
              <h2 class="mb-6 andie-section-title">Success stories from restaurants like yours.</h2>
            </div>
            <div class="grid md:grid-cols-2 gap-6 -m-4">
              {stories.map((story, index) => (
                <PartnerStory story={story} />
              ))}  
            </div>
            {/* <div className="mt-14">
              <div class="w-full">
                <div class="flex flex-wrap h-full bg-blue-200 overflow-hidden rounded-xl">
                  <div class="w-full md:w-1/3">
                    <img class="w-full h-full object-cover" src={require("../../../assets/images/andie-helps-create-great-corporate-guest-experiences.jpg")} alt=""/>
                  </div>
                  <div class="flex-1">
                    <div class="md:max-w-lg p-10 h-full">
                      <div class="flex flex-col justify-between h-full">
                        <div class="flex-initial mb-8">
                          <p class="mb-3 text-sm text-gray-500 font-bold">Design process &bull; 4 min read</p><a class="group inline-block mb-4" href="#">
                            <h3 class="text-2xl text-gray-900 hover:text-gray-700 leading-none font-productsans-bold">You will never believe these bizarre truth of travel.</h3></a>
                          <p class="text-gray-500 font-bold">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Malesuada tellus vestibulum, commodo pulvinar.</p>
                        </div>
                        <div class="flex-initial">
                          <div class="flex flex-wrap -m-2">
                            <div class="w-full md:w-auto p-2"><a class="block w-full px-4 py-2.5 text-sm text-center text-white font-productsans-medium bg-gray-900 hover:bg-gray-800 focus:ring-4 focus:ring-gray-600 rounded-sm" href="#">Read More</a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div class="flex flex-wrap md:justify-center -m-2">
              <RequestDemoButton />
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}
