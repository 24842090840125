import React, { useState } from "react";
import {
  LightningBoltIcon,
  SearchIcon,
  UserCircleIcon,
  PlayIcon,
} from "@heroicons/react/solid";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

export default function CGEPlatformHighlights() {
  const [viewModal, setViewModal] = useState(false);

  return (
    <>
      {/*Vid Modal*/}
      <section class="py-24 bg-white andie-padding-horizontal overflow-hidden">
        <div class="container px-4 mx-auto">
          <div className="max-w-3xl mx-auto">
            <h2 class="mb-7 andie-section-title text-center">
              A new approach to corporate dining and catering
            </h2>
            <p class="mb-14 andie-section-description w-full text-center mx-auto">
              Andie CGE is a digital platorm where restaurants bid for dining
              and catering opportunities from organizations like yours.
            </p>
          </div>
          <div class="flex max-w-5xl mx-auto justify-center">
            <div class="inline-block border-4 rounded-3xl border-gray-200">
              <img
                class="transform rounded-3xl hover:translate-y-3 transition ease-in-out duration-1000"
                src={require("../../../../../assets/images/corporate-cge/andie-cge-screenshot.png")}
                alt=""
              />
            </div>
            <div class="text-blue-600 text-base space-x-1 ml-2 font-productsans-medium">
              <div
                onClick={() => {
                  setViewModal(true);
                }}
                class=" relative flex items-center text-base space-x-1 hover:cursor-pointer h-9 w-9"
              >
                <PlayIcon className="ml-1 absolute inline-flex h-9 w-9 animate-ping text-blue-600 opacity-75" />
                <PlayIcon className="pl-1 relative inline-flex h-9 w-9 text-blue-600" />
              </div>
              <ModalVideo
                autoplay
                channel="custom"
                isOpen={viewModal}
                url={require("../../../../../assets/videos/meet-andie-cge.mp4")}
                onClose={() => setViewModal(false)}
              />
            </div>
          </div>
        </div>
      </section>

      <section class="relative pb-24 bg-white overflow-hidden">
        <img
          class="absolute left-1/2 top-0 transform -translate-x-1/2"
          src="flaro-assets/images/how-it-works/gradient2.svg"
          alt=""
        />
        <div class="relative z-10 container px-4 mx-auto">
          <div class="flex flex-wrap -m-8">
            <div class="w-full md:w-1/2 lg:w-1/4 p-8">
              <div class="text-center">
                <div class="relative z-10 bg-white w-12 h-12 mb-8 mx-auto border border-blueGray-200 rounded-full">
                  <div class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <LightningBoltIcon className="h-5 text-blue-600" />
                  </div>
                  <div class="hidden lg:block absolute left-12 top-1/2 transform -translate-y-1/2 w-96 h-px bg-gray-200"></div>
                </div>
                <div class="md:max-w-xs mx-auto">
                  <h3 class="mb-4 andie-section-subheader">Quicker and stress-free planning</h3>
                  <p class="text-gray-600 andie-paragraph xl:mx-6">
                    Cut down the time you spend on restaurant searches, email
                    inquiries, selections, and bookings by up to 90%.
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/4 p-8">
              <div class="text-center">
                <div class="relative z-10 bg-white w-12 h-12 mb-8 mx-auto border border-blueGray-200 rounded-full">
                  <div class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <SearchIcon className="h-5 text-blue-600" />
                  </div>
                  <div class="hidden lg:block absolute left-12 top-1/2 transform -translate-y-1/2 w-96 h-px bg-gray-200"></div>
                </div>
                <div class="md:max-w-xs mx-auto">
                  <h3 class="mb-4 andie-section-subheader">
                    Discover new options
                  </h3>
                  <p class="text-gray-600 andie-paragraph">
                    With hundreds of featured restaurants, caterers, and
                    hospitality businesses, Andie CGE helps you explore new and
                    previously unknown places.
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/4 p-8">
              <div class="text-center">
                <div class="relative z-10 bg-white w-12 h-12 mb-8 mx-auto border border-blueGray-200 rounded-full">
                  <div class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <UserCircleIcon className="h-5 text-blue-600" />
                  </div>
                  <div class="hidden lg:block absolute left-12 top-1/2 transform -translate-y-1/2 w-96 h-px bg-gray-200"></div>
                </div>
                <div class="md:max-w-xs mx-auto">
                  <h3 class="mb-4 andie-section-subheader">
                    Personalized to your needs
                  </h3>
                  <p class="text-gray-600 andie-paragraph">
                    From a-la-carte to pre-fixes and discounts, Andie CGE
                    ensures your booking is curated and customized to your
                    needs.
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/4 p-8">
              <div class="text-center">
                <div class="relative z-10 bg-indigo-600 w-12 h-12 mb-8 mx-auto border border-blueGray-200 rounded-full">
                  <div class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <svg
                      width="22"
                      height="16"
                      viewbox="0 0 22 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M20.5734 0.93934C21.1591 1.52513 21.1591 2.47487 20.5734 3.06066L8.57336 15.0607C7.98757 15.6464 7.03782 15.6464 6.45204 15.0607L0.452035 9.06066C-0.133751 8.47487 -0.133751 7.52513 0.452035 6.93934C1.03782 6.35355 1.98757 6.35355 2.57336 6.93934L7.5127 11.8787L18.452 0.93934C19.0378 0.353553 19.9876 0.353553 20.5734 0.93934Z"
                        fill="white"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div class="md:max-w-xs mx-auto">
                  <h3 class="mb-4 andie-section-subheader font-productsans-black">
                    Deliver joyful moments
                  </h3>
                  <p class="text-gray-600 andie-paragraph xl:mx-6">
                    Whether it weekly lunches or a client dinner, create
                    experiences that you and your colleagues will cherish for a
                    long time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
