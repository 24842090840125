import React from "react";
import AndieHeaderImage from "../../../../assets/images/landingslideshow/pexels-fauxels-3184180.jpg";
import { RequestDemoButton } from "../../../common";

export default function LandingMeetCGE() {
  return (
    <div class="flex flex-wrap justify-center">
      <section class="relative xl:px max-w-7xl">
        <div class="relative z-20 overflow-hidden py-10 md:py-20">
          <div class="container px-4 mx-auto">
            <div class="flex flex-wrap xl:items-center -m-8">
              <div class="w-full md:w-1/2 xl:flex-1 p-8 lg:p-12">
                <div class="xl:max-w-2xl md:pl-4">
                  <h1 class="mb-7 andie-sectionheader max-w-sm text-gray-900">
                    The Andie Corporate Guest Experience
                  </h1>
                  <p class="mb-7 font-normal text-gray-600 font-pj max-w-md">
                    Streamline your Corporate Experiences with Andie. Andie CGE
                    will provide you with curated menus and delicious dine in
                    packages offered by our top rated restaurant partners to
                    guarantee a great corporate experience for you.{" "}
                  </p>
                  <div class="pt-2 flex flex-wrap items-center">
                    <div class="w-full xl:w-auto">
                      <div class="block items-center justify-center sm:flex-none">
                        {/* <button class="py-4 px-7 w-full text-white font-semibold rounded-xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200" type="button">Request Demo</button> */}
                        <RequestDemoButton />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full md:w-1/2 xl:w-1/2 p-8 lg:p-12 align-middle">
                <div class="overflow-hidden rounded-lg drop-shadow-xl">
                  <img
                    class="align-middle w-full md:w-auto rounded-lg transform hover:scale-105 transition ease-in-out duration-1000"
                    src={AndieHeaderImage}
                    alt=""
                  />
                </div>
                <div class="p-8 absolute bottom-0 left-0 w-full"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
