import { Link } from "react-router-dom"

export default function LandingBlog({ blogData }) {
    return (
        <div>
            <section class="py-12 bg-white sm:py-16 lg:py-20">
                <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div class="max-w-md mx-auto text-center">
                        <h2 class="andie-sectionheader">
                            Read our blog
                        </h2>
                        <p class="mt-5 font-normal text-gray-600 font-pj">
                        Stay up-to-date with the latest news and trends in corporate catering, event planning, and culinary innovations with our blog
                        </p>
                    </div>
                    {blogData.slice(0, 1).map((item, index) => (
                        <div class="grid items-center mt-8 sm:mt-16 max-w-4xl grid-cols-1 mx-auto md:grid-cols-2 md:gap-x-16">
                            <div class="md:order-2">
                                <div class="max-w-md mx-auto">
                                    <div class="aspect-w-4 aspect-h-3">
                                        <img class="object-cover w-full h-full rounded-xl" src={item?.coverImage?.url} alt="" />
                                    </div>
                                </div>
                            </div>

                            <div class="px-6 py-8 text-center md:text-left md:p-0 md:order-1">
                                <h2 class="text-lg font-bold text-gray-900 sm:text-3xl lg:text-4xl line-clamp-3 font-pj">
                                    {item.title}
                                </h2>
                                <p class="mt-6 andie-page-text text-gray-600 font-pj line-clamp-4">
                                    {item.summary}
                                </p>

                                <Link to={"/blog/post/?title=" + item.title} class="inline-flex items-center mt-4 font-bold text-gray-900 rounded hover:text-gray-600 font-pj group focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2">
                                    Continue Reading
                                    <svg class="w-5 h-5 ml-2 transition-all duration-200 group-hover:translate-x-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                                    </svg>
                                </Link>
                            </div>
                        </div>
                    ))
                    }

                    <div class="grid max-w-4xl grid-cols-1 mx-auto mt-8 text-center sm:mt-16 sm:text-left sm:grid-cols-2 md:grid-cols-3 gap-y-8 gap-x-8 lg:gap-x-20">
                        {blogData.slice(0, 4).map((item, index) => {
                            if (index === 0) {
                                return <></>
                            } else {
                                return (
                                    <div class="relative group">
                                        <div class="overflow rounded-lg aspect-[16/9]">
                                            {item?.coverImage == null ?
                                                <img class="object-cover w-full h-full transition-all duration-300 transform group-hover:scale-125" src={require('../../../../assets/images/art/3-old-women-staring-at-phone-blog-1.png')} alt="" />
                                                :
                                                <img class="object-cover w-full h-full transition-all duration-300 transform group-hover:scale-125" src={item?.coverImage?.url} alt="" />
                                            }
                                        </div>
                                        <p class="mt-6 text-xs font-semibold text-gray-600 font-pj">
                                            {item.date}
                                        </p>
                                        <p class="mt-2 andie-page-text line-clamp-2 font-bold text-gray-900 font-pj">
                                            {item.title}
                                        </p>
                                        <Link to={"/blog/post/?title=" + item.title}>
                                            <span class="absolute inset-0" aria-hidden="true"></span>
                                        </Link>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>
            </section>
        </div>
    )
}
