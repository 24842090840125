import { useState } from 'react';
export default function PrivacyBody() {
    return (
        <div>
            <section class="andie-padding-horizontal xl:w-3/4 xl:border-r">
                <div class="justify-center">
                    <p className="andie-paragraph"><em><u>Please read this policy carefully</u> to understand our policies and practices for collecting, processing, and storing your information. If you do not agree with our policies and practices, your choice is not to use our Site. By assessing the Site, you indicate that you understand, accept and consent to the practices described in this policy. This policy may be change from time to time. Your continued use of the Site after we make changes indicates that you accept and consent to those changes, so please check the policy periodically for updates. We will notify you in advance of any material changes to this policy and obtain your consent to any new ways that we collect, use, and disclose your personal information.</em></p>
                    <p className="andie-paragraph"><em>&nbsp;</em></p>
                    <h1><u>Personal Information We Collect About You</u></h1>
                    <p className="andie-paragraph">We may collect and use several types of information from and about you, including:</p>
                    <p class="mt-2 sm:mt-8"><strong>Personal Information:&nbsp;</strong>When you create a Company ID, we collect limited Personal Information that we can reasonably use to directly or indirectly identify you, such as your name, postal code, email, and phone number and any other identifier we may use to contact you (&ldquo;<strong>personal information</strong>&rdquo;).</p>
                    <p class="mt-4"><em>We provide an opportunity for any user to unsubscribe from our collection, or an ability to opt-out of contact for marketing purposes on an ongoing basis by accessing the Site or using the unsubscribe mechanism at the bottom of our emails.</em></p>
                    <p class="mt-2 sm:mt-8"><strong>Non-personal information:&nbsp;</strong>We do not collect other information directly from you, but we may derive information that does not directly or indirectly reveal your identity or directly relate to an identifiable individual, such as demographic information, or statistical or aggregated information. Statistical or aggregated data does not directly identify a specific person, but we may derive non-personal statistical or aggregated data from personal information. &nbsp;This information does not, on its own, permit direct association with any specific individual. &nbsp;</p>
                    <p class="mt-2 sm:mt-8"><strong>Non-personal information about your Site interactions:</strong> We also derive non-personal information about your Site interactions including the full Uniform Resource Locators (URLs), clickstream to, through and from our Site (including date and time), products you viewed or searched for, page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks and mouse-overs), methods used to browse away from the page.</p>
                    <p class="mt-2 sm:mt-8"><strong>Location</strong>: Your physical location while you are using our services or interacting with the Site.
                    </p>
                    <p class="mt-2 sm:mt-8"><strong><u>How We Collect Information About You</u></strong></p>
                    <p className="andie-paragraph">We use different methods to collect your information, including:</p>
                    <ul class="list-disc ml-10 mt-2">
                        <li className="andie-paragraph">direct interactions with you when you provide it to us (for example, by filling in forms or corresponding to us by phone, email or otherwise);</li>
                        <li className="andie-paragraph">automated technologies or interactions, as you navigate through our Site, including Device Information (defined below); and</li>
                        <li className="andie-paragraph">third party or publicly available sources (for example, our business partners).</li>
                    </ul>
                    <p class="mt-2 sm:mt-8"><strong>Information You Provide Us</strong></p>
                    <p className="andie-paragraph">The information we collect directly from you on or through our Site may include:</p>
                    <ul class="list-disc ml-10">
                        <li className="andie-paragraph">information you provide by filling in forms on our Site, which may include information provided at the time of registering to use our Site, subscribing to our service, posting material, and/or requesting further service; we may also ask you for information when you report a problem with the Site;</li>
                        <li className="andie-paragraph">records and copies of your correspondence, if you contact us; and</li>
                        <li className="andie-paragraph">details of transactions you carry out through the Site and of the fulfillment of any service requests.</li>
                    </ul>
                    <p class="mt-2 sm:mt-8"><strong>Information We Collect Through Cookies and other Automatic Data Collection Technologies</strong></p>
                    <p className="andie-paragraph">When you visit the Site, we may use cookies or other automatic data collection technologies to collect certain information about your device, equipment, browsing actions and patterns, including information about your web browser, IP address, time zone, logs, web beacons, tags, pixels, operating system, traffic data, location data, other communication data and the resources that you access and use on the Site and some of the cookies that are installed on your device. Additionally, as you browse the Site, we may collect information about the individual web pages or features that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site over time. We refer to all automatically collected data as &ldquo;<strong>Device Information</strong>.&rdquo;</p>
                    <p class="mt-8">Collecting Device Information helps us improve our Site and to deliver a better, more personalize service, enabling us to estimate our audience size and usage pattern; store information about preferences; speed up your searches; and recognize you when you return to the Site.</p>
                    <p className="andie-paragraph">The technologies we use to collect Device Information may include:</p>
                    <ul class="list-disc ml-10">
                        <li className="andie-paragraph">&ldquo;<strong>Cookies</strong>&rdquo; which are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit&nbsp;<a href="http://www.allaboutcookies.org" class="text-blue-600 visited:text-purple-600">http://www.allaboutcookies.org</a>. &nbsp;</li>
                        <li className="andie-paragraph">&ldquo;<strong>Log files</strong>&rdquo; which track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</li>
                        <li className="andie-paragraph">&ldquo;<strong>Web beacons</strong>,&rdquo; &ldquo;<strong>tags</strong>,&rdquo; and &ldquo;<strong>pixels</strong>&rdquo; which are electronic files used to record information about how you browse the Site.</li>
                    </ul>
                    <p class="mt-2 sm:mt-8">We may also use technologies to collect information about your online activities over time and across third party websites or other online services (behaviour tracking). To learn more or to opt-out of tailored advertising please visit <a href="https://youradchoices.ca/en/tools" class="text-blue-600 visited:text-purple-600">https://youradchoices.ca/en/tools</a> and/or the other links below for information on how your can opt out of behavioral tracking on the Site and how we respond to web browser signals and other mechanism that enable customers to exercise choice about behaviour tracking.</p>
                    <p className="andie-paragraph"><span>&nbsp;</span></p>
                    <h1><u>How We Use Your Information</u></h1>
                    <p className="andie-paragraph">We use information that we collect about you or that you provide to us, including any personal information:</p>
                    <ul class="list-disc ml-10">
                        <li className="andie-paragraph">to present our Site and its contents to you;</li>
                        <li className="andie-paragraph">to provide you access to the Site;</li>
                        <li className="andie-paragraph">to provide you with information about the services that you request from us;</li>
                        <li className="andie-paragraph">to fulfill the purposes for which you provided the information or that were described when collected, or any other purpose for which you provide it;</li>
                        <li className="andie-paragraph">to communicate with you;</li>
                        <li className="andie-paragraph">to improve our Site, services, technology features, marketing or customer relationships and experiences;</li>
                        <li className="andie-paragraph">to notify you about changes to our Site, or any services we offer or provide through it;</li>
                        <li className="andie-paragraph">to screen our userbases for potential risk or fraud;</li>
                        <li className="andie-paragraph">to measure or understand the effectiveness of the advertising we serve to you and others, and to deliver relevant advertising to you;</li>
                        <li className="andie-paragraph">in any other way we may describe when you provide the information; and</li>
                        <li className="andie-paragraph">for any other purpose with your consent.</li>
                    </ul>
                    <p class="mt-2 sm:mt-8">We may also use your information to contact you about our own and third-parties&apos; goods and services that may be of interest to you, as permitted by law. If you do not want us to use your information in this way, please adjust your user preferences in your account profile.</p>
                    <p class="mt-2">We may use the information we have collected from you to enable us to display advertisements to our advertisers&apos; target audiences. Even though we do not disclose your personal information for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria. &nbsp;</p>
                    <p class="mt-2">Additionally, we may provide information to advertisers and advertising networks that require the information to select and serve relevant advertisements to you and others. We do not, however, disclose data about identifiable individuals to our advertisers, but we may provide them with aggregate information about our users (for example, we may inform them that 400 women between 30 and 45 have clicked on their advertisement on a specific day). We may also use such aggregate information to help advertisers target a specific audience (for example, men in a specific location). We may make use of the personal information we have collected from you to enable us to display our advertisers&apos; advertisement to that target audience;</p>
                    <p className="andie-paragraph"><span>&nbsp;</span></p>
                    <h1><u>Sharing Your Personal Information</u></h1>
                    <p class="mt-2">We may disclose aggregate information about users and information that does not identify any individual without restriction.</p>
                    <p class="mt-2">We may disclose your personal information or any information that includes personal information that we collect or you provide us (as described in this privacy policy):</p>
                    <ul class="list-disc ml-10">
                        <li className="andie-paragraph">internally and to our subsidiaries or affiliates;</li>
                        <li className="andie-paragraph">to comply with applicable laws and regulations, or other lawful request for information we receive;</li>
                        <li className="andie-paragraph">to third parties to market their products or services to you if you have not opted out of these disclosures. We contractually require these third parties to keep personal information confidential and use it only for the purposes for which we disclose it to them;</li>
                        <li className="andie-paragraph">in accordance with applicable law, to a buyer or other successor in the event of a merger, restructuring, reorganization, dissolution or other sale or transfer of some or all of the Company&rsquo;s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by the Company about our customers or users is among the assets transferred;</li>
                        <li className="andie-paragraph">to fulfil the purpose for which you provide it;</li>
                        <li className="andie-paragraph">in relation to a business transaction whereby the Company sells its business assets or is involved in a bankruptcy, merger, acquisition, or reorganization. &nbsp;In such an event it is possible your personal information may be among the assets sold or transferred as part of a business transaction. &nbsp;</li>
                        <li className="andie-paragraph">for any other purpose disclosed by us when you provide the information; and</li>
                        <li className="andie-paragraph">with your consent.</li>
                    </ul>
                    <p class="mt-6">We may also disclose your personal information:</p>
                    <ul class="list-disc ml-10">
                        <li className="andie-paragraph">to comply with any court order, law, or legal process, including to respond to any government or regulatory request, in accordance with applicable law;</li>
                        <li className="andie-paragraph">if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers or others.</li>
                    </ul>
                    <p class="mt-2 sm:mt-8">We also use Google Analytics to help us understand how our customers use the Site. You can read more about how Google uses your Personal Information here: <a href="https://www.google.com/intl/en/policies/privacy/" class="text-blue-600 visited:text-purple-600">https://www.google.com/intl/en/policies/privacy/</a>.</p>
                    <p className="andie-paragraph">In the event we disclose any personal information, we will do so in accordance with any relevant laws. &nbsp;</p>
                    <p className="andie-paragraph">&nbsp;</p>
                    <p className="andie-paragraph"><strong><u>Transferring Your Personal Information</u></strong></p>
                    <p className="andie-paragraph">&nbsp;</p>
                    <p className="andie-paragraph">We may transfer your personal information that we collect or that you provide as described in this policy to contractors, service providers and other third parties we use to support our business (such as analytics and search engine providers that assist us with Site improvement and optimization) and who are contractually obligated to keep personal information confidential, use it only for the purposes for which we disclose it to them, and to process personal information with the same standards set out in this policy.</p>
                    <p className="andie-paragraph">&nbsp;</p>
                    <p className="andie-paragraph">We may process, store, transfer your personal information in and to a foreign country, with different privacy laws that may or may not be as comprehensive as Canadian law. In these circumstances, the governments, courts, law enforcement or regulatory agencies of that country may be able to obtain access to your personal information through the laws of the foreign country. Whenever we engage a service provider, we require that its privacy and security standards adhere to this policy and applicable Canadian privacy legislation.</p>
                    <p className="andie-paragraph">&nbsp;</p>
                    <p className="andie-paragraph">By submitting your personal information or engaging with the Site, you consent to this transfer, storage and processing.</p>
                    <p className="andie-paragraph">&nbsp;</p>
                    <p className="andie-paragraph"><strong><u>Choices About How We Use and Disclose Your Information</u></strong></p>
                    <p className="andie-paragraph"><strong>&nbsp;</strong></p>
                    <p className="andie-paragraph">We strive to provide you with choices regarding the personal information you provide to us. We have created (or provided information below) to provide you with the following control over your personal information:</p>
                    <ul class="list-disc ml-10">
                        <li className="andie-paragraph">For more information, please visit Network Advertising Initiative&rsquo;s education page: <a href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work" class="text-blue-600 visited:text-purple-600">http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work</a></li>
                        <li className="andie-paragraph">You can set your browser to refuse all or some browser cookies, or alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on Adobe&rsquo;s website. If you disable or refuse cookies, please note that some parts of the Site may not be accessible or may not function properly.</li>
                        <li className="andie-paragraph">If you do not want us to share your personal information with unaffiliated or non-agent third parties for promotion purposes, you can opt-out of targeted advertising by visiting:</li>
                        <ul class="list-disc ml-10">
                            <li className="andie-paragraph">Facebook: <a href="https://www.facebook.com/settings/?tab=ads" class="text-blue-600 visited:text-purple-600">https://www.facebook.com/settings/?tab=ads</a></li>
                            <li className="andie-paragraph">Google: <a href="https://www.google.com/settings/ads/anonymous" class="text-blue-600 visited:text-purple-600">https://www.google.com/settings/ads/anonymous</a></li>
                            <li className="andie-paragraph">Bing: <a href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads" class="text-blue-600 visited:text-purple-600">https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads</a></li>
                            <li className="andie-paragraph">Digital Advertising Alliance: <a href="http://optout.aboutads.info/" class="text-blue-600 visited:text-purple-600">http://optout.aboutads.info/</a></li>
                        </ul>
                        <li className="andie-paragraph">If you no longer want to receive emails or other promotional materials from us, you can opt-out by emailing us stating your request to <a href="mailto:privacy@andie.work" class="text-blue-600 visited:text-purple-600">privacy@andie.work</a>, or by changing your settings by logging into the Site and adjusting your user preferences by checking or unchecking the relevant boxes.</li>
                        <li className="andie-paragraph">If you wish to opt-out of Google Analytics: <a href="https://tools.google.com/dlpage/gaiptout" class="text-blue-600 visited:text-purple-600">https://tools.google.com/dlpage/gaiptout</a><span>.</span></li>
                        <li className="andie-paragraph">If you do not want us to use information that we collect or that you provide to us to deliver advertisements according to our advertisers&rsquo; target-audience preferences, you can opt-out by logging into the Site and adjusting your preferences.</li>
                    </ul>
                    <p className="andie-paragraph"><strong>&nbsp;</strong></p>
                    <p className="andie-paragraph"><strong><u>Data Security</u></strong></p>
                    <p className="andie-paragraph">&nbsp;</p>
                    <p className="andie-paragraph">The security of your personal information is very important to us. We use physical, electronic and administrative measures designed to secure your personal information from accidental loss and from unauthorized use, alternation and disclosure. We store all information you provide to us behind firewalls on our secure servers and other information will be encrypted using SSL technology.</p>
                    <p className="andie-paragraph">&nbsp;</p>
                    <p className="andie-paragraph">The safety and security of your information also depends on you. Where we have given you (or where you have a chosen) a password for access to certain parts of the Site, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.</p>
                    <p className="andie-paragraph">&nbsp;</p>
                    <p className="andie-paragraph">Unfortunately, the transmission of information via the Internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Site. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Site.</p>
                    <p className="andie-paragraph">&nbsp;</p>
                    <p className="andie-paragraph"><strong><u>Data Retention&nbsp;</u></strong></p>
                    <p className="andie-paragraph">&nbsp;</p>
                    <p className="andie-paragraph">Except as otherwise permitted or required by applicable law, we will only retain your personal information for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements consistent with and subject to our Terms of Use. Under some circumstances we may anonymize your personal information so that it can no longer be associated with you. We reserve the right to use such anonymous and re-identified data for any legitimate business purpose without further notice to you or your consent.</p>
                    <p className="andie-paragraph">&nbsp;</p>
                    <p className="andie-paragraph"><strong><u>California</u></strong></p>
                    <p className="andie-paragraph"><span>&nbsp;</span></p>
                    <p className="andie-paragraph">At such time we become subject to the California Consumer Privacy Act (the &ldquo;Act&rdquo;), the Act will provide California consumer with the right to obtain information about the personal information we collect, use, and disclose. &nbsp;At such time, we will update this Privacy Policy to permit you to exercise your rights through an authorized agent by providing relevant details and submitting a request to us. &nbsp;Regardless, we will not discriminate against you or provide fewer or reduced services because you choose to exercise your privacy rights created by law or set forth herein.</p>
                    <p className="andie-paragraph"><span>&nbsp;</span></p>
                    <p className="andie-paragraph"><strong><u>Sale of Personal Information</u></strong></p>
                    <p className="andie-paragraph"><span>&nbsp;</span></p>
                    <p className="andie-paragraph">In some jurisdictions, you may have the right to opt-out of the sale of your personal information. We do not sell your personal information. &nbsp;</p>
                    <p className="andie-paragraph">&nbsp;</p>
                    <p className="andie-paragraph"><strong><u>Individuals Under 18</u></strong></p>
                    <p className="andie-paragraph">&nbsp;</p>
                    <p className="andie-paragraph">The Site is not intended for individuals under the age of 18. Persons under the age of 18 are not permitted to create their own Company ID, and no one under the age of 18 may provide any information to or on the Site. We do not knowingly collect personal information from individuals under 18. If you use the Site, you are representing and warrantying to the Company that you are at least 18 years of age. If you are under 18, you are not permitted to and shall not use or provide information on this Site or on any or through any of its features/register on the Site, subscribe to any services, or provide any information about yourself, including your name, address, telephone number, email address, or any screen name or user name you may use. If we learn we have collected or received personal information from an individual under 18 without verification of parental consent, we will delete that information. If you believe we might have information from or about an individual under 18, please contact us at <a href="mailto:privacy@andie.work" class="text-blue-600 visited:text-purple-600">privacy@andie.work</a>. If we learn that we have collected the personal information of any person under the age of 18, we will take reasonable steps to delete the information as soon as possible. &nbsp;</p>
                    <p className="andie-paragraph">&nbsp;</p>
                    <p className="andie-paragraph"><strong><u>Accessing and Correcting Your Personal Information</u></strong></p>
                    <p className="andie-paragraph">&nbsp;</p>
                    <p className="andie-paragraph">It is important that the personal information we hold about you is accurate and current. Please keep us informed if your personal information changes. By law, you have the right to request access to and to correct the personal information that we hold about you. You can also log into the Site and update your personal information on your dashboard. If you would like us to delete any personal information, please send your request via email at <a href="mailto:privacy@andie.work" class="text-blue-600 visited:text-purple-600">privacy@andie.work</a>.</p>
                    <p className="andie-paragraph">&nbsp;</p>
                    <p className="andie-paragraph">We may request specific information from you to help us confirm your identity and your right to access, and to provide you with the personal information that we hold about you or make your requested changes. Applicable law may allow or require us to refuse to provide you with access to some or all of the personal information that we hold about you, or we may have destroyed, erased, or made your personal information anonymous in accordance with our record retention obligations and practices. If we cannot provide you with access to your personal information, we will inform you of the reasons why, subject to any legal or regulatory restrictions.</p>
                    <p className="andie-paragraph">&nbsp;</p>
                    <p className="andie-paragraph"><strong><u>Withdrawing Your Consent&nbsp;</u></strong></p>
                    <p className="andie-paragraph">&nbsp;</p>
                    <p className="andie-paragraph">Where you have provided your consent to the collection, use and transfer of your personal information, you may have the legal right to withdraw your consent under certain circumstances. To withdraw your consent, contact us at <a href="mailto:privacy@andie.work" class="text-blue-600 visited:text-purple-600">privacy@andie.work</a>. &nbsp;Please note that if you withdraw your consent we may not be able to provide you with a particular product or service. We will explain the impact to you at the time to help you with your decision.</p>
                    <p className="andie-paragraph">&nbsp;</p>
                    <p className="andie-paragraph"><strong><u>Changes to Our Privacy Policy</u></strong></p>
                    <p className="andie-paragraph">&nbsp;</p>
                    <p className="andie-paragraph">It is our policy to post any changes we make to our privacy policy on this page. If we make any material changes to how we treat our users&rsquo; personal information, we will notify you by email to the email address you provided through the Site. We include the date the privacy policy was last revised at the top of the page. You are responsible for ensuring we have an up-to-date, active, and deliverable email address for you, and for periodically visiting our Site and this privacy policy to check for any changes.</p>
                    <p className="andie-paragraph">&nbsp;</p>
                    <p className="andie-paragraph"><strong><u>Contact Information and Challenging Compliance</u></strong></p>
                    <p className="andie-paragraph">&nbsp;</p>
                    <p className="andie-paragraph">We welcome your questions, comments, and requests regarding this privacy policy and our privacy practices. Please contact us at <a href="mailto:privacy@andie.work" class="text-blue-600 visited:text-purple-600">privacy@andie.work</a>.</p>
                    <p className="andie-paragraph">&nbsp;</p>
                    <p className="andie-paragraph">&nbsp;</p>
                    <p className="andie-paragraph">&nbsp;</p>
                </div>
            </section>

        </div>
    )
}
