import React from "react";
import Highlight1 from "./items/Highlight1";
import Highlight2 from "./items/Highlight2";
import Highlight3 from "./items/Highlight3";

export default function HomePlatformHighlights() {
    return (
        <>
            <Highlight1 />
            <Highlight2 />
            <Highlight3 />
        </>
    )
}