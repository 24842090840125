import React from "react";

export default function FAQAdditional() {
    const resources = [
        {
            "title": "In-person assistance",
            "details": "We are always happy to send a representative from our team to meet with you in-person and offer on-site assistance.",
            "action": "Request visit",
            "link": "hospitality@andie.work"
        },
        {
            "title": "24/7 Support",
            "details": "No problem is too small for our support team. We are available throughout the year to answer your call, email, or web chat.",
            "action": "Contact us",
            "link": "hospitality@andie.work"
        },
        {
            "title": "Technical issues",
            "details": "Report the bug you experienced and a member of our technical will work immediately on fixing the situation. We apologize for any incovenience.",
            "action": "Submit a bug request",
            "link": "engineering@andie.work"
        }
    ]
    return (
        <section class="py-28 bg-white overflow-hidden">
            <div class="container px-4 mx-auto">
                <div class="mb-16 pb-20 border-b border-gray-200">
                    <h2 class="andie-section-title md:max-w-xl">Need more support and additional resources?</h2>
                </div>
                <div class="flex flex-wrap -m-8">
                    {resources.map((resource, index) => (
                        <div class="w-full md:w-1/3 p-8">
                            <div class="md:max-w-xs">
                                <div class="flex flex-col justify-between h-full">
                                    <div class="mb-7">
                                        <h3 class="mb-7 text-xl andie-section-heading leading-snug">{resource.title}</h3>
                                        <p class="andie-paragraph">{resource.details}</p>
                                    </div>
                                    <a class="inline-flex items-center max-w-max text-blue-600 hover:text-blue-700" href={`mailto:${resource.link}`}>
                                        <span class="mr-2 font-productsans-medium">{resource.action}</span>
                                        <svg width="18" height="18" viewbox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.5 3.75L15.75 9M15.75 9L10.5 14.25M15.75 9L2.25 9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}