import { useState } from "react";
import AccountInformation from "../../components/pages/signup/AccountInformation";

export default function Signup() {
    const [data, setData] = useState({
        email: '',
        first_name: '',
        last_name: '',
        phone: '',
        job_title: '',
        organization: '',
        industry: '',
        additional_users: [],
    });

    return (
        <>
            <div className="flex andie-padding-horizontal min-h-[90vh]">
                <div className="flex-grow ">
                    <div className="grid md:grid-cols-2 grid-cols-1 h-full py-4">
                        <div className="md:flex flex-col hidden justify-evenly">
                            <h1 className="andie-page-title andie-padding">
                                <span>Lorem ipsum dolor sit amet consectetur adipisicing elit.</span> <span className="text-blue-600">Totam, repellat.</span>
                            </h1>
                            <p className="andie-padding-horizontal andie-padding-vertical andie-page-description flex items-center">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos enim tempore modi eum consectetur iure est? Ratione perferendis qui architecto.</p>
                        </div>

                        <div className="flex items-center w-full justify-center">
                            <AccountInformation data={data} setData={setData} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}