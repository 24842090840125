import { Link } from "react-router-dom";

export default function TalkToSalesButton() {
    return (
        <div>
           <Link to="/request-a-demo" className="andie-outline-button" role="button">
                Contact sales
            </Link> 
        </div>
    )
}
