import React from "react";

export default function CGEGetStarted() {
    return (
        <section class="pt-16 pb-28 bg-white overflow-hidden">
            <div class="container px-4 mx-auto">
                <div class="text-center max-w-xl mx-auto">
                    <h2 class="mb-5 andie-section-title">Book your first corporate guest experience</h2>
                    <p class="mb-7 text-gray-600 mx-auto andie-section-description text-center">Discover how we can deliver great Andie CGE experiences for you in as little as four clicks.</p>
                    <div class="mb-11 md:inline-block">
                    <div className="andie-dark-button">Learn about our curated experience</div>
                    </div>
                    <div class="flex flex-wrap items-center justify-center -m-1">
                        <div class="w-auto p-1">
                            <div class="flex flex-wrap">
                            <div class="w-auto">
                            <img className="w-10 h-10 rounded-full" src={require('../../../../../assets/images/corporate-cge/logo/nikolina.jpg')} alt="" />
                            </div>
                            <div class="w-auto -ml-3">
                                <img className="w-10 h-10 rounded-full" src={require('../../../../../assets/images/corporate-cge/logo/alyssa.png')} alt="" />
                            </div>
                            <div class="w-auto -ml-3">
                                <img className="w-10 h-10 rounded-full" src={require('../../../../../assets/images/corporate-cge/logo/jaydeh.jpg')} alt="" />
                            </div>
                            </div>
                        </div>
                        <div class="w-auto p-1">
                            <p class="text-gray-600 text-sm font-productsans-medium">
                            <span>Join over</span>
                            <span class="font-bold"> 20,000+ </span>
                            <span>guest experiences</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}