import React from 'react'
import AndieLogo from '../../assets/images/andielogo.png';
import { Link } from 'react-router-dom';


export default function Footer() {
  const links = [
    {
      title: "Solutions",
      sub: [
        { "title": "Andie for restaurants", "url": "/restaurant"},
        { "title": "Corporate guest experience", "url": "/cge"},
      ]
    },
    {
      title: "Resources",
      sub: [
        { "title": "Pricing", "url": "/pricing"},
        { "title": "Success stories", "url": "/andie-success-stories"},
        { "title": "Help & FAQ", "url": "/customer-success-center"}
      ]
    },
    {
      title: "Company",
      sub: [
        { "title": "The Andie Promise", "url": "/the-andie-promise"},
        { "title": "Blog", "url": "/blog"},
        { "title": "Privacy", "url": "/privacy"},
        { "title": "Terms and conditions", "url": "/terms-and-conditions"}
      ]
    },
    {
      title: "External links",
      sub: [
        { "title": "Fred VanVleet podcast", "url": "https://open.spotify.com/episode/6sXGquCGboVxrKMV2bzgzd?si=776de3a1ffde400c" },
      ]
    },
  ]
  return (
    <section class="pt-16 bg-blue-50 andie-padding-horizontal overflow-hidden">
      <div class="container px-4 mx-auto">
        <div class="pb-8 border-b border-blue-200">
          <div class="flex flex-wrap">
            {links.map((item, index) => (
              <div class="w-full sm:w-1/2 lg:w-2/12">
                <h3 class="mb-4 font-productsans-bold text-gray-800 leading-normal">{item.title}</h3>
                <ul className="space-y-2">
                  {item.sub.map((link, index) => (
                    <li>
                      <Link to={link.url} class="text-gray-500 hover:text-gray-700 text-sm font-roboto-medium">
                        {link.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}

            <div class="w-full sm:w-1/2 lg:w-4/12 pb-16">
              <div class="xl:ml-5">
                <h3 class="mb-4 font-productsans-black text-base leading-normal">Subscribe to our newsletter</h3>
                <p class="mb-5 andie-paragraph">Stay up-to-date on everything Andie including the last insights on hospitality and the world around us.</p>
                <div class="mt-4 mb-3 xl:pl-3 inline-block md:max-w-xl w-full overflow-hidden border bg-blue-200 border-gray-200 rounded-sm focus-within:ring focus-within:ring-indigo-300">
                  <div class="flex flex-wrap items-center">
                    <div class="w-full xl:flex-1">
                      <input class="p-3 xl:p-0 xl:pr-6 w-full text-sm font-roboto-regular bg-blue-200 text-gray-500 placeholder-gray-500 outline-none" id="footerInput1-1" type="text" placeholder="Enter your e-mail address" />
                    </div>
                    <div class="w-full xl:w-auto">
                      <div class="block">
                        <button class="py-2 px-8 w-full text-white font-productsans-medium border border-indigo-700 focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200" type="button">Subscribe</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap justify-between items-center py-6 -m-4">
          <div class="w-auto p-4">
            <a href="#">
              <img src={AndieLogo} className="h-9" alt="" />
            </a>
          </div>
          <div class="w-auto p-4">
            <p class="text-sm text-gray-600 font-roboto-medium">Copyright &copy; {new Date().getFullYear()} Andie Connected Technologies Inc. All Rights Reserved.</p>
          </div>
          <div class="w-auto p-4">
            <div class="flex flex-wrap -m-4">
              <ul class="flex items-center justify-start space-x-3 sm:order-2 sm:justify-end">
                <li>
                    <a href="https://www.linkedin.com/company/andie-works/mycompany/?viewAsMember=true" target="_blank" rel="noreferrer" title="Andie on Instagram" class="inline-flex items-center justify-center w-10 h-10 text-gray-900 transition-all duration-200 rounded-full hover:bg-blue-200 focus:outline-none focus:bg-gray-200 focus:ring-2 focus:ring-offset-2 focus:ring-gray-200" r>
                        <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
                            <path d="M 5.585938 7.722656 L 0.769531 7.722656 C 0.554688 7.722656 0.382812 7.894531 0.382812 8.109375 L 0.382812 23.585938 C 0.382812 23.800781 0.554688 23.972656 0.769531 23.972656 L 5.585938 23.972656 C 5.800781 23.972656 5.972656 23.800781 5.972656 23.585938 L 5.972656 8.109375 C 5.972656 7.894531 5.800781 7.722656 5.585938 7.722656 Z M 5.585938 7.722656 " />
                            <path d="M 3.179688 0.0273438 C 1.425781 0.0273438 0 1.449219 0 3.203125 C 0 4.953125 1.425781 6.378906 3.179688 6.378906 C 4.929688 6.378906 6.355469 4.953125 6.355469 3.203125 C 6.355469 1.449219 4.929688 0.0273438 3.179688 0.0273438 Z M 3.179688 0.0273438 " />
                            <path d="M 17.839844 7.335938 C 15.90625 7.335938 14.476562 8.167969 13.609375 9.113281 L 13.609375 8.109375 C 13.609375 7.894531 13.433594 7.722656 13.222656 7.722656 L 8.605469 7.722656 C 8.394531 7.722656 8.21875 7.894531 8.21875 8.109375 L 8.21875 23.585938 C 8.21875 23.800781 8.394531 23.972656 8.605469 23.972656 L 13.414062 23.972656 C 13.628906 23.972656 13.800781 23.800781 13.800781 23.585938 L 13.800781 15.929688 C 13.800781 13.347656 14.503906 12.34375 16.300781 12.34375 C 18.261719 12.34375 18.417969 13.953125 18.417969 16.0625 L 18.417969 23.585938 C 18.417969 23.800781 18.589844 23.972656 18.804688 23.972656 L 23.613281 23.972656 C 23.828125 23.972656 24 23.800781 24 23.585938 L 24 15.097656 C 24 11.257812 23.269531 7.335938 17.839844 7.335938 Z M 17.839844 7.335938 " />
                        </svg>
                    </a>
                </li>
                <li>
                    <a href="https://www.tiktok.com/@theandieapp" target="_blank" title="Andie on TikTok" class="inline-flex items-center justify-center w-10 h-10 text-gray-900 transition-all duration-200 rounded-full hover:bg-blue-200 focus:outline-none focus:bg-gray-200 focus:ring-2 focus:ring-offset-2 focus:ring-gray-200" rel="noreferrer">
                        <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M 19.320312 5.5625 C 19.167969 5.484375 19.023438 5.398438 18.878906 5.304688 C 18.460938 5.027344 18.078125 4.703125 17.742188 4.335938 C 16.890625 3.367188 16.574219 2.382812 16.457031 1.691406 L 16.464844 1.691406 C 16.367188 1.121094 16.40625 0.75 16.414062 0.75 L 12.546875 0.75 L 12.546875 15.691406 C 12.546875 15.894531 12.546875 16.089844 12.539062 16.289062 C 12.539062 16.3125 12.539062 16.335938 12.535156 16.359375 C 12.535156 16.371094 12.535156 16.382812 12.535156 16.394531 C 12.535156 16.398438 12.535156 16.398438 12.535156 16.402344 C 12.449219 17.492188 11.832031 18.46875 10.882812 19.007812 C 10.394531 19.285156 9.84375 19.429688 9.285156 19.429688 C 7.484375 19.429688 6.023438 17.960938 6.023438 16.148438 C 6.023438 14.335938 7.484375 12.867188 9.285156 12.867188 C 9.625 12.867188 9.964844 12.917969 10.289062 13.023438 L 10.292969 9.089844 C 8.304688 8.832031 6.296875 9.421875 4.761719 10.707031 C 4.09375 11.289062 3.535156 11.976562 3.105469 12.75 C 2.941406 13.03125 2.328125 14.160156 2.253906 15.996094 C 2.207031 17.035156 2.519531 18.113281 2.667969 18.558594 L 2.667969 18.570312 C 2.761719 18.832031 3.125 19.726562 3.714844 20.484375 C 4.195312 21.089844 4.757812 21.621094 5.390625 22.0625 L 5.390625 22.054688 L 5.398438 22.0625 C 7.269531 23.332031 9.34375 23.25 9.34375 23.25 C 9.703125 23.234375 10.90625 23.25 12.273438 22.601562 C 13.789062 21.886719 14.648438 20.816406 14.648438 20.816406 C 15.203125 20.175781 15.640625 19.449219 15.945312 18.664062 C 16.296875 17.742188 16.414062 16.640625 16.414062 16.199219 L 16.414062 8.273438 C 16.460938 8.300781 17.082031 8.714844 17.082031 8.714844 C 17.082031 8.714844 17.984375 9.289062 19.386719 9.667969 C 20.394531 9.933594 21.75 9.988281 21.75 9.988281 L 21.75 6.152344 C 21.273438 6.203125 20.308594 6.054688 19.320312 5.5625 Z M 19.320312 5.5625 "></path>
                            {/* Original image was found here: https://www.svgrepo.com/show/327400/logo-tiktok.svg
                            It was then resized from 512 by 512 down to 24 by 24 using https://www.iloveimg.com/resize-image/resize-svg
                            because if the SVG size is too big, it will be blank when inserted */}
                        </svg>
                    </a>
                </li>

                <li>
                    <a href="https://twitter.com/theandieapp" target="_blank" title="" class="inline-flex items-center justify-center w-10 h-10 text-gray-900 transition-all duration-200 rounded-full hover:bg-blue-200 focus:outline-none focus:bg-gray-200 focus:ring-2 focus:ring-offset-2 focus:ring-gray-200" rel="noreferrer">
                        <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M19.633 7.997c.013.175.013.349.013.523 0 5.325-4.053 11.461-11.46 11.461-2.282 0-4.402-.661-6.186-1.809.324.037.636.05.973.05a8.07 8.07 0 0 0 5.001-1.721 4.036 4.036 0 0 1-3.767-2.793c.249.037.499.062.761.062.361 0 .724-.05 1.061-.137a4.027 4.027 0 0 1-3.23-3.953v-.05c.537.299 1.16.486 1.82.511a4.022 4.022 0 0 1-1.796-3.354c0-.748.199-1.434.548-2.032a11.457 11.457 0 0 0 8.306 4.215c-.062-.3-.1-.611-.1-.923a4.026 4.026 0 0 1 4.028-4.028c1.16 0 2.207.486 2.943 1.272a7.957 7.957 0 0 0 2.556-.973 4.02 4.02 0 0 1-1.771 2.22 8.073 8.073 0 0 0 2.319-.624 8.645 8.645 0 0 1-2.019 2.083z"></path>
                        </svg>
                    </a>
                </li>

                <li>
                    <a href="https://www.facebook.com/andieworkapp/" target="_blank" rel="noreferrer" title="" class="inline-flex items-center justify-center w-10 h-10 text-gray-900 transition-all duration-200 rounded-full hover:bg-blue-200 focus:outline-none focus:bg-gray-200 focus:ring-2 focus:ring-offset-2 focus:ring-gray-200">
                        <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path>
                        </svg>
                    </a>
                </li>

                <li>
                    <a href="https://www.instagram.com/theandieapp/" target="_blank" rel="noreferrer" title="Andie on Instagram" class="inline-flex items-center justify-center w-10 h-10 text-gray-900 transition-all duration-200 rounded-full hover:bg-blue-200 focus:outline-none focus:bg-gray-200 focus:ring-2 focus:ring-offset-2 focus:ring-gray-200">
                        <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M11.999 7.377a4.623 4.623 0 1 0 0 9.248 4.623 4.623 0 0 0 0-9.248zm0 7.627a3.004 3.004 0 1 1 0-6.008 3.004 3.004 0 0 1 0 6.008z"></path>
                            <circle cx="16.806" cy="7.207" r="1.078"></circle>
                            <path d="M20.533 6.111A4.605 4.605 0 0 0 17.9 3.479a6.606 6.606 0 0 0-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 0 0-2.184.42 4.6 4.6 0 0 0-2.633 2.632 6.585 6.585 0 0 0-.419 2.186c-.043.962-.056 1.267-.056 3.71 0 2.442 0 2.753.056 3.71.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632 6.584 6.584 0 0 0 2.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.615 6.615 0 0 0 2.186-.419 4.613 4.613 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.186.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.581 6.581 0 0 0-.421-2.217zm-1.218 9.532a5.043 5.043 0 0 1-.311 1.688 2.987 2.987 0 0 1-1.712 1.711 4.985 4.985 0 0 1-1.67.311c-.95.044-1.218.055-3.654.055-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 0 1-1.669-.311 2.985 2.985 0 0 1-1.719-1.711 5.08 5.08 0 0 1-.311-1.669c-.043-.95-.053-1.218-.053-3.654 0-2.437 0-2.686.053-3.655a5.038 5.038 0 0 1 .311-1.687c.305-.789.93-1.41 1.719-1.712a5.01 5.01 0 0 1 1.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 0 1 1.67.311 2.991 2.991 0 0 1 1.712 1.712 5.08 5.08 0 0 1 .311 1.669c.043.951.054 1.218.054 3.655 0 2.436 0 2.698-.043 3.654h-.011z"></path>
                        </svg>
                    </a>
                </li>

                {/* <li>
                <a href="#" target="_blank" title="" class="inline-flex items-center justify-center w-10 h-10 text-gray-900 transition-all duration-200 rounded-full hover:bg-blue-200 focus:outline-none focus:bg-gray-200 focus:ring-2 focus:ring-offset-2 focus:ring-gray-200" rel="noreferrer">
                    <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.026 2c-5.509 0-9.974 4.465-9.974 9.974 0 4.406 2.857 8.145 6.821 9.465.499.09.679-.217.679-.481 0-.237-.008-.865-.011-1.696-2.775.602-3.361-1.338-3.361-1.338-.452-1.152-1.107-1.459-1.107-1.459-.905-.619.069-.605.069-.605 1.002.07 1.527 1.028 1.527 1.028.89 1.524 2.336 1.084 2.902.829.091-.645.351-1.085.635-1.334-2.214-.251-4.542-1.107-4.542-4.93 0-1.087.389-1.979 1.024-2.675-.101-.253-.446-1.268.099-2.64 0 0 .837-.269 2.742 1.021a9.582 9.582 0 0 1 2.496-.336 9.554 9.554 0 0 1 2.496.336c1.906-1.291 2.742-1.021 2.742-1.021.545 1.372.203 2.387.099 2.64.64.696 1.024 1.587 1.024 2.675 0 3.833-2.33 4.675-4.552 4.922.355.308.675.916.675 1.846 0 1.334-.012 2.41-.012 2.737 0 .267.178.577.687.479C19.146 20.115 22 16.379 22 11.974 22 6.465 17.535 2 12.026 2z"></path>
                    </svg>
                </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
