import React from "react";
import AndieHeaderImage from "../../../../../assets/images/andie-cge-for-restaurants.jpeg";
import { RequestDemoButton, TalkToSalesButton } from "../../../../common";
import { PhotographIcon } from "@heroicons/react/solid";

export default function RestaurantCGEHeader() {
  return (

    <section class="bg-blueGray-50">

  <div class="overflow-hidden pt-16">
    <div class="container px-4 mx-auto">
      <div class="flex flex-wrap -m-8">
        <div class="w-full md:w-1/2 p-8">
          <div class="inline-block mb-6 px-2 py-1 font-semibold bg-green-100 rounded-full">
            <div class="flex flex-wrap items-center -m-1">
              <div class="w-auto p-1"><a class="text-sm" href="">&#x1F44B; We are hiring! View open roles</a></div>
              <div class="w-auto p-1">
                <svg width="15" height="15" viewbox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.66667 3.41675L12.75 7.50008M12.75 7.50008L8.66667 11.5834M12.75 7.50008L2.25 7.50008" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </div>
            </div>
          </div>
          <h1 class="mb-6 andie-page-title md:max-w-xl">Say hello to bigger check sizes.</h1>
          <p class="mb-8 andie-page-description text-gray-500 font-medium md:max-w-md">Andie Corporate Guest Experiences allows restaurants like yours to bid on corporate catering and dining opportunities that offer significant check sizes and revenue. </p>
          <div class="flex flex-wrap -m-2.5">
            <div class="w-full md:w-auto p-2.5">
              <div class="block">
                <RequestDemoButton />
              </div>
            </div>
            <div class="w-full md:w-auto p-2.5">
              <div class="block">
                <TalkToSalesButton />
              </div>
            </div>
          </div>
          <p class="text-xs text-gray-500 font-roboto-bold uppercase mt-7 mb-3">Trusted and loved by 200+ restaurants</p>
          <div class="grid grid-cols-5 gap-5">
            <div class="w-auto">
              <img src={require("../../../../../assets/images/reslogos/hothouse.png")}  className="h-10 w-full grayscale object-contain hover:grayscale-0" alt="" />
            </div>
            <div class="w-auto">
              <img src={require("../../../../../assets/images/reslogos/rasta.png")}  className="h-10 w-full grayscale object-contain hover:grayscale-0" alt="" />
            </div>
            <div class="w-auto">
              <img src={require("../../../../../assets/images/reslogos/brasa.jpg")}  className="h-10 w-full grayscale object-contain hover:grayscale-0" alt="" />
            </div>
            <div class="w-auto">
              <img src={require("../../../../../assets/images/reslogos/freshiii.png")}  className="h-10 w-full grayscale object-contain hover:grayscale-0" alt="" />
            </div>
          </div>
        </div>
        <div class="w-full md:w-1/2 p-8 flex justify-between">
          <img class="rounded-full border-8 border-blue-100" src={AndieHeaderImage} alt="" />
          <a href="https://www.instagram.com/theandieapp/?hl=en" target="_blank" rel="noreferrer" className="h-10 w-10 bg-blue-600 flex items-center justify-center rounded-full">
            <PhotographIcon className="h-6 text-white" />
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
  );
}