import React, { useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";

export default function FAQItems({ faqs }) {
    const [selected, setSelected] = useState(faqs[0]);

    return (
        <div class="max-w-4xl mx-auto">
            <ul>
                {faqs.map((faq, index)  => (
                    <li class="px-6 py-8 border-b">
                        <button onClick={()=> setSelected(faq)} class="w-full flex justify-between items-center text-left">
                            <span class="text-xl font-semibold andie-section-heading">{faq.question}</span>

                            {selected === faq ?
                                <div className="h-6 w-6 flex items-center justify-center border-2 border-blue-200 rounded-full">
                                    <ChevronUpIcon className="text-blue-600 h-5" />
                                </div>
                                :
                                <div className="h-6 w-6 flex items-center justify-center border-2 border-gray-200 rounded-full">
                                    <ChevronDownIcon className="text-gray-600 h-5" />
                                </div>
                            }
                        </button>
                        {selected === faq && <p class="transtion duration-1000 mt-4 text-gray-500 andie-paragraph w-3/4">{faq.answer}</p>}
                    </li>
                ))}
            </ul>
        </div>
    )
}