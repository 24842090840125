import React from "react";

import BlogHighlight from "./items/BlogHighlight";
import BlogGrid from "./items/BlogGrid";

export default function BlogPost({data}) {
  return (
    <div>
      <BlogHighlight posts={[data[0]]} />
      <BlogGrid data={data} />
    </div>
  );
}
