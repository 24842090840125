import React from "react";
import { Link } from "react-router-dom";

export default function PricingRestaurantFAQ() {
    const faqs = [
        {
            "question": "Can I bid for more than one opportunity at once?",
            "answer": "Yes. You can bid for multiple experiences at once.",
            "important_point": "If you are selected for multiple bids, you have to guarantee your ability to execute them all with a drop in quality."
        },
        {
            "question": "How many bid responses can I submit for a bid?",
            "answer": "You can only submit one response to a bid. Once you have submitted a bid response, you can make edit 3 times after which you will be unable to change the response."
        },
        {
            "question": "How quickly will I get paid?",
            "answer": "Restaurants are paid for corporate guest experiences within 3 business days of completing the booking. You can discuss payment advances with your dedicated support specialist."
        },
        {
            "question": "What happens if a booking does not go as planned?",
            "answer": "We understand that issues may arise during the execution of a dining or catering opportunity. That is all bookings are backed by the Andie promise.",
        }
    ]
    return (
        <section class="pb-32 bg-white overflow-hidden">
            <div class="container mx-auto">
                <p class="mb-5 text-sm text-blue-600 font-roboto-bold uppercase tracking-px">Frequently Asked questions</p>
                <h2 class="mb-16 andie-section-title leading-none">What you need to know</h2>
                <div class="mb-8 md:max-w-5xl">
                <div class="flex flex-wrap -m-4">
                    {faqs.map((faq, index) => (
                        <div class="w-full md:w-1/2 p-4">
                            <div class="p-6 border border-gray-50 rounded-2xl shadow-lg">
                                <h3 class="mb-4 text-lg font-productsans-medium leading-normal">{index + 1}. {faq.question}</h3>
                                <p class="andie-paragraph text-gray-600 mr-5">{faq.answer} <span className="font-semibold text-gray-600">{faq.important_point}</span></p>
                            </div>
                        </div>
                    ))}
                </div>
                </div>
                <Link to="/customer-success-center" class="font-productsans-medium text-blue-600 hover:text-blue-700 border-b border-blue-600 hover:border-blue-700">See all questions &amp; answers</Link>
            </div>
        </section>
    )
}