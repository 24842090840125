import React from "react";
import { FAQAndiePromise, FAQBlog, FAQAdditional, FAQHeader, FAQMoneyBack, FAQSection, FAQCallToAction } from "../../components/pages/general/faq";

export default function FAQ({ blogData }) {
    return (
        <div className="andie-padding-horizontal">
            <FAQHeader />
            <FAQAndiePromise />
            <FAQMoneyBack />
            <FAQSection />
            <FAQBlog blogData={blogData} />
            <FAQCallToAction />
            <FAQAdditional />
        </div>
    )
}