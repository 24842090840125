import { Link } from "react-router-dom";

export default function BlogHighlight({ posts }) {
    console.log(posts)
    return (
        <div>
            <section class="bg-white pt-8">
                {posts.map((item, index) => (
                    <div class="mx-auto max-w-5xl">
                        <div class="grid items-center bg-blue-100 p-5 rounded-xl grid-cols-1 mx-auto md:grid-cols-2 md:gap-x-16">
                            <div class="md:order-2">
                                <div class="max-w-md mx-auto">
                                    <div class="aspect-w-4 aspect-h-3">
                                        <img class="object-cover w-full h-72 rounded-xl" src={item?.coverImage?.url} alt={item.title} />
                                    </div>
                                </div>
                            </div>

                            <div class="px-6 py-8 text-center md:text-left md:p-0 md:order-1">
                                <h2 class="text-xl text-gray-900 sm:text-3xl lg:text-4xl line-clamp-3 font-productsans-bold">
                                    {item?.title}
                                </h2>
                                <p class="mt-6 andie-paragraph line-clamp-3 text-gray-600">
                                    {item?.content.html.replace('<p>', "")}
                                </p>
                                <Link to={"/blog/post/?title=" + item?.title} class="inline-flex items-center mt-4 font-productsans-medium text-gray-900 rounded hover:text-gray-600 group focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2">
                                    Continue Reading
                                    <svg class="w-5 h-5 ml-2 transition-all duration-200 group-hover:translate-x-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                                    </svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                ))}
            </section>
        </div>
    )
}
