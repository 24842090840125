import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { FormikField } from './FormikField'
import { useNavigate } from 'react-router-dom';
import { SignupGenericUser } from '../../../api/Authentication'
export default function AccountInformation({ }) {

    const navigate = useNavigate();

    const handleSubmit = async (values) => {
        const data = {
            email: values.email,
            first_name: values.first_name,
            last_name: values.last_name,
            password: values.password,
            re_password: values.re_password,
        }
        // console.log(data);
        await SignupGenericUser(data);
        navigate('/redirect');
    }

    const AccountInformationValidationSchema = Yup.object({
        email: Yup.string().required().email().label('Email'),
        first_name: Yup.string().required().label('First name'),
        last_name: Yup.string().required().label('Last name'),
        password: Yup.string().required().label("Password"),
        re_password: Yup.string()
            .required()
            .label("This")
            .test('passwords-match', 'Passwords must match', function (value) {
                return this.parent.password === value;
            })
    });

    return (
        <>
            <div className="andie-padding-horizontal flex flex-col justify-center mx-4">
                <h1 className="andie-section-title">Welcome to Andie,</h1>
                <p className="andie-section-description andie-padding-vertical">Lets begin by getting your account information.</p>
                <Formik
                    validationSchema={AccountInformationValidationSchema}
                    initialValues={{email: '', first_name: '', last_name: '', password: '', re_password: ''}}
                    onSubmit={handleSubmit}
                >
                    <Form className='space-y-4'>
                        <div>
                            <FormikField fieldName={'Email'} fieldInformation={'(Account ID)'} name={'email'} type={'email'} />
                            <FormikField fieldName={'First name'} name={'first_name'} />
                            <FormikField fieldName={'Last name'} name={'last_name'} />
                            <FormikField fieldName={'Password'} name={'password'} type={'password'} />
                            <FormikField  fieldName={'Re-enter password'} name={'re_password'} type={'password'} />

                        </div>
                        <div className="flex andie-padding-vertical">
                            <button className='py-2 bg-green-600 hover:bg-green-900 hover:shadow-md text-white transition duration-200 w-full font-semibold' type='submit'>Create</button>
                        </div>

                    </Form>
                </Formik>
            </div>
        </>
    )
}