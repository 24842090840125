import React, { useState } from 'react';
import { AiFillMinusSquare, AiOutlineClose } from 'react-icons/ai';
import AdditionalUsersModal from './AdditionalUsersModal';

export const ListView = ({ data, setData }) => {
  const [additionalUsersModal, setAdditionalUsersModal] = useState(false);

  const toggleAdditionalUsersModal = () => {
    setAdditionalUsersModal(!additionalUsersModal);
  };

  const removeUser = (index) => {
    const updatedUsers = [...data.additional_users];
    updatedUsers.splice(index, 1);
    setData({ ...data, additional_users: updatedUsers });
  };

  const addUser = (newUser) => {
    const updatedUsers = [...data.additional_users, newUser];
    setData({ ...data, additional_users: updatedUsers });
  };

  return (
    <>
      <div className='flex mt-5 font-semibold'>Additional users</div>
      <div className={`ring-1 ring-slate-200 p-2 bg-white max-h-[200px] ${0 > 1 ? 'overflow-y-auto overflow-scroll' : ''}`}>
        <div className='mx-1'>
          <button type='button' className='andie-dark-button sticky top-0 mb-2' onClick={toggleAdditionalUsersModal}>Add user</button>
        </div>
        {data.additional_users.map((user, index) => (
          <div key={index} className='flex justify-between text-sm border-y-[1px]'>
            <div  className='flex flex-col w-full gap-x-2'>
              <div className='font-semibold'>
                {`Name: ${user.first_name} ${user.last_name}`}
              </div>
              <div className='font-semibold'>
                <span>{`Phone: ${user.phone}`}</span>
              </div>
              <div className='font-semibold'>
                <span>{`Email: ${user.email}`}</span>
              </div>
            </div>
            <div className='flex items-center mx-auto px-2 py-1' onClick={() => removeUser(index)}>
              <AiOutlineClose className='text-red-500 font-bold cursor-pointer' size={16} />
            </div>
          </div>
        ))}
      </div>
      {additionalUsersModal ? (
        <AdditionalUsersModal
          data={data}
          setData={setData}
          toggle={toggleAdditionalUsersModal}
        />
      ) : null}
    </>
  );
};