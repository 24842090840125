import React from "react";
import { Link } from "react-router-dom";

export default function RestaurantCGEBlog({ blogData }) {
    return (
        <section class="py-24 bg-white overflow-hidden">
            <div class="container px-4 mx-auto">
                <div class="flex flex-wrap -m-8">
                <div class="w-full md:w-5/12 p-8">
                    <div class="flex flex-col justify-between h-full">
                    <div class="mb-8">
                        <h2 class="mb-5 andie-section-title tracking-px-n">The Andie Blog</h2>
                        <p class="text-gray-600 andie-section-description max-w-md">Read the latest articles on growing your restaurant, increasing sales, and improving your restaurant’s marketing.</p>
                    </div>
                    <Link class="w-28 inline-block font-productsans-medium border-b-2 pb-1 border-blue-600 text-blue-600 text-sm overflow-hidden" to="/blog">
                        <p class="mb-1">Latest blog posts</p>
                    </Link>
                    </div>
                </div>
                <div class="w-full md:flex-1 p-8">
                    <div class="xl:ml-20 grid grid-cols-2 gap-12">
                        {blogData.slice(0,2).map((blog, index) => (
                            <div class="w-full">
                                <div class="mb-6 overflow-hidden rounded-xl">
                                    <img class="transform h-52 w-full flex-grow object-cover hover:scale-105 transition ease-in-out duration-1000" src={blog.coverImage.url} alt="" />
                                </div>
                                <p class="mb-4 font-roboto-medium max-w-max tracking-wide px-3 py-1 text-xs text-indigo-600 bg-indigo-50 uppercase rounded-md">{blog.category}</p>
                                <a class="mb-2 inline-block  hover:text-blue-600" href="#">
                                    <h3 class="andie-section-heading leading-tight">{blog.title}</h3>
                                </a>
                                <p class="text-gray-600 font-medium leading-relaxed">{blog.summary}</p>
                            </div>
                        ))}
                    </div>
                </div>
                </div>
            </div>
        </section>
    )
}