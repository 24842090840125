import React from "react";
import { useLocation } from "react-router-dom";
import { CalendarIcon, CheckCircleIcon, LocationMarkerIcon, SparklesIcon } from "@heroicons/react/solid";
import { RequestDemoButton } from "../../../common";

export default function PartnerStoryPage() {
    const location = useLocation();
    const story = location.state.story
    return (
        <section class="relative py-20 overflow-hidden">
            <div class="relative container px-4 mx-auto">
                <div class="max-w-lg lg:max-w-3xl xl:max-w-5xl mx-auto">
                    <div className="flex justify-between border-b border-blue-100 mb-10">
                        <span class="inline-block py-1 px-3 mb-4 text-sm font-roboto-bold text-blue-900 bg-blue-50 rounded-full">{story.cuisine}</span>
                        <div className="flex space-x-5 flex-wrap">
                            <span className="font-productsans-medium">#Corporate guest experiences</span>
                        </div>
                    </div>
                    <div class="flex flex-wrap -mx-4 mb-8">
                        <div class="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
                            <div class="max-w-lg">
                                <h2 class="font-productsans-bold text-5xl text-blue-600">{story.success}</h2>
                            </div>
                        </div>
                        <div class="w-full lg:w-1/2 flex justify-end">
                            <img class="block rounded-3xl w-auto h-[200px]" src={story.logo} alt="" />
                        </div>
                    </div>
                    <div className="w-3/5">
                        <p class="andie-paragraph text-base font-semibold text-gray-600 mb-5">{story.specialty}</p>
                        <div className="mt-2 mb-7 border-b border-gray-100" />
                        <p class="andie-paragraph text-base mb-5">{story.introduction}</p>
                    </div>
                    <div className="grid grids-col-2 xl:flex xl:items-center xl:justify-between border-t-2 border-b-2 py-3 gap-6 border-blue-600 my-12">
                        <div className="flex space-x-4 text-sm font-productsans-medium text-gray-600">
                            <div className="rounded-full bg-blue-50 h-9 w-9 flex items-center justify-center"><CalendarIcon className="h-5 text-blue-600" /></div>
                            <div>
                                <h6>Partner since:</h6>
                                <p className="andie-paragraph">{story.partner_since}</p>
                            </div>
                        </div>
                        <div className="flex space-x-4 text-sm font-productsans-medium text-gray-600">
                            <div className="shrink-0 rounded-full bg-blue-50 h-9 w-9 flex items-center justify-center"><SparklesIcon className="h-5 text-blue-600" /></div>
                            <div>
                                <h6>Andie Specialty:</h6>
                                <p className="andie-paragraph">{story.specialties}</p>
                            </div>
                        </div>
                        <div className="flex space-x-4 text-sm font-productsans-medium text-gray-600">
                            <div className="rounded-full bg-blue-50 h-9 w-9 flex items-center justify-center"><LocationMarkerIcon className="h-5 text-blue-600" /></div>
                            <div>
                                <h6>Partner since:</h6>
                                <p className="andie-paragraph">{story.location}</p>
                            </div>
                        </div>
                        <div className="flex items-center justify-end text-sm font-productsans-medium text-gray-600">
                            <a href={`mailto:cge@andie.work?subject=Book Andie CGE ${story.restaurant}`} className="h-9 inline-flex items-center px-4 bg-blue-100 text-blue-600 font-productsans-medium text-sm rounded-sm">
                                Ask for a bid response
                            </a>
                        </div>
                    </div>
                    <div>
                        <img class="block w-full rounded-3xl object-cover h-[500px]" src={story.image1} alt="" />
                    </div>
                    <div className="grid grid-cols-2 rounded-3xl gap-5 mt-8 xl:gap-10">
                        <img class="w-full rounded-3xl object-cover h-[400px]" src={story.image2} alt="" />
                        <img class="w-full rounded-3xl object-cover h-[400px]" src={story.image3} alt="" />
                    </div>
                    <div class="container px-4 mx-auto">
                        <div class="mt-14 pt-4 border-t border-gray-100 md:max-w-4xl mx-auto">
                            <h3 class="mt-6 mb-4 text-xl font-productsans-bold text-gray-700">The challenge</h3>
                            <p class="andie-paragraph text-base mb-5">{story.challenges}</p>
                            <div class="mb-6 w-3/5">
                                {story.headache &&
                                    <div>
                                    <svg viewBox="0 0 24 24" className="h-16" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M9.62231 6.78278C10.0546 6.43909 10.1265 5.81001 9.78277 5.3777C9.43908 4.94539 8.81001 4.87354 8.37769 5.21724C4.73471 8.11342 4 11.8784 4 16C4 17.6569 5.34315 19 7 19C8.65685 19 10 17.6569 10 16C10 14.3432 8.65685 13 7 13C6.71233 13 6.43412 13.0405 6.17076 13.1161C6.5162 10.5872 7.45738 8.50391 9.62231 6.78278ZM20 16C20 17.6569 18.6569 19 17 19C15.3431 19 14 17.6569 14 16C14 11.8784 14.7347 8.11342 18.3777 5.21724C18.81 4.87354 19.4391 4.94539 19.7828 5.3777C20.1265 5.81001 20.0546 6.43909 19.6223 6.78278C17.4574 8.50391 16.5162 10.5872 16.1708 13.1161C16.4341 13.0405 16.7123 13 17 13C18.6569 13 20 14.3432 20 16Z" fill="#2562eb"></path> </g></svg>
                                        <p class="mb-4 text-xl md:text-2xl leading-tight font-productsans-bold text-blue-600">{story.headache}</p>
                                    </div>
                                }
                                <p class="andie-paragraph text-base mt-14 mb-5">That is why {story.restaurant} was looking to work with a partner like Andie.</p>
                            </div>
                        
                            <h3 class="mt-14 mb-4 text-xl font-productsans-bold text-gray-600">Working with Andie</h3>
                            <p class="andie-paragraph text-base mb-5">{story.solution}</p>

                            {story.quote && 
                                <div class="w-4/5 mb-6 p-6 border-l-4 border-blue-500">
                                    <p class="mb-4 text-xl md:text-2xl leading-tight font-productsans-bold text-gray-700">"{story.quote}"</p>
                                    <span class="text-base md:text-lg text-coolGray-400 font-medium">&mdash; {story.owner}, {story.title}</span>
                                </div>
                            }
                            
                            <div className="mt-14 flex space-x-4 text-sm font-productsans-medium text-gray-600 justify-between">
                                <div className="border-b w-1/3" />
                                <h6 className="text-xl font-productsans-bold text-blue-600">Results</h6>
                                <div className="border-b w-1/3" />
                            </div>
                            <div className="flex flex-col items-center my-10 mx-auto">
                                <div className="space-y-3">
                                    {story.results.map((result, index) => (
                                        <div className="flex">
                                            <CheckCircleIcon className="h-7 text-blue-600" />
                                            <p class="ml-3 andie-paragraph text-base">{result}</p>
                                        </div>
                                    ))}
                                </div>
                                <div className="mt-8 w-1/2 py-3 px-4 border-b-2 border-t-2 border-blue-600">
                                    <h6 className="text-center font-productsans-black text-2xl text-blue-600">
                                        {story.highlight}
                                    </h6>
                                </div>

                            </div>
                            <h3 class="mt-14 mb-4 text-xl font-productsans-bold text-gray-700">What's next for {story.restaurant}</h3>
                            <img class="block w-full rounded-3xl object-cover h-[450px]" src={story.image4} alt="" />
                            <p class="mt-10 mb-10 pb-10 andie-paragraph text-base border-b border-coolGray-100">
                                {story.conclusion}
                            </p>
                            <div class="mx-auto py-12 p-6 md:p-12 bg-yellow-100 rounded-3xl">
                                <div class="flex flex-wrap -mx-4">
                                    <div class="w-full md:w-1/2 lg:w-8/12 px-4 mb-6 md:mb-10 lg:mb-0">
                                        <h2 class="text-3xl sm:text-4xl font-productsans-bold text-gray">Interested in a 15 minute demo on how Andie could work for your restaurant?</h2>
                                    </div>
                                    <div class="w-full lg:w-4/12 xl:pl-10 px-4 text-center lg:text-right">
                                        <RequestDemoButton />
                                        <div className="flex flex-col mt-5 items-center">
                                            <div className="flex">
                                                <CheckCircleIcon className="h-5 text-blue-600 mr-1.5" />
                                                <p className="text-sm text-gray-700 font-roboto-medium mr-1.5">Get started for free</p>
                                            </div>
                                            <div className="flex mt-2">
                                                <CheckCircleIcon className="h-5 text-blue-600 mr-1.5" />
                                                <p className="text-sm text-gray-700 font-roboto-medium">Quick setup process</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}