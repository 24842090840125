import React from "react";
import { HomeBlog, HomeCallToAction, HomeHeader, HomeIntroducingAndie, HomePlatformHighlights, HomePricing } from "../../components/pages/home";

export default function Home({ blogData }) {
    return (
        <div className="z-10 andie-padding-horizontal">
            <HomeHeader />
            <HomeIntroducingAndie />
            <HomePlatformHighlights />
            <HomePricing />
            <HomeBlog blogData={blogData} />
            <HomeCallToAction />
        </div>
    )
}