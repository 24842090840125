import React from "react";
import { RequestDemoButton, TalkToSalesButton } from "../../../common";
import { StarIcon } from "@heroicons/react/solid";

import smilingheart from "../../../../assets/images/header-icons/smile-heart.svg";

export default function AndiePromiseHeader() {
    return (
        <section class="overflow-hidden">
            <div class="relative container mx-auto px-4">
                <div class="relative z-10 flex flex-wrap justify-between lg:pt-28 lg:pb-28">
                    <div class="w-full lg:w-auto p-6 flex flex-col items-center">
                        <img class="xl:relative border-8 border-blue-100 xl:-top-20 h-52 w-52 object-cover rounded-full mx-auto" src={require("../../../../assets/images/Card2-Dine-In-Experiences.jpg")} alt="" />
                        <div className="flex justify-center items-center h-28 w-28 bg-blue-200 rounded-full">
                            <img class="h-20 mx-auto" src={smilingheart} alt="" />
                        </div>
                    </div>
                    <div className="xl:w-1/2">
                        <div class="mb-6 font-heading flex items-center max-w-max mx-auto px-4 py-1.5 uppercase text-xs font-roboto-bold bg-blue-50 rounded-full">
                            <StarIcon className="h-4 mr-0.5 text-blue-600" />
                            <p>The Andie Promise</p>
                        </div>
                        <h1 class="mb-14 font-heading text-center andie-page-title">Great experiences are at the heart of what we do.</h1>
                        <p class="mb-10 andie-page-description max-w-xl mx-auto text-center">At Andie, we believe in only making money when our customers are happy and successful. That is why we strive to create seamless and memorable experiences for guests and partners.</p>
                        <div class="flex mx-auto justify-center flex-wrap -m-2.5">
                            <div class="w-full md:w-auto p-2.5">
                                <div class="block">
                                <RequestDemoButton />
                                </div>
                            </div>
                            <div class="w-full md:w-auto p-2.5">
                                <div class="block">
                                <TalkToSalesButton />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-full lg:w-auto p-6 self-start">
                    <img class="xl:relative border-8 mb-5 border-blue-100 xl:-top-20 h-40 w-40 object-cover rounded-full mx-auto" src={require("../../../../assets/images/happy-restaurant-owner.png")} alt="" />
                        <img class="xl:relative border-8 border-blue-100 xl:-top-20 h-52 w-52 xl:h-72 xl:w-72 object-cover rounded-full mx-auto" src={require("../../../../assets/images/happy-team-dinner.png")} alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}