import React from "react";
import { Link } from "react-router-dom";

export default function PartnersStats() {
  const numbers = [
    {
      "stats": "600k+",
      "title": "Restaurant wait times delivered",
      "description": "Since 2020, Andie has helped restaurants like yours provide live information to potential guests."
    },
    {
      "stats": "15.1k+",
      "title": "Corporate guest experiences",
      "description": "Andie connects restaurants with great corporate catering and dining opportunities."
    },
    {
      "stats": "4.9 / 5",
      "title": "Combined guest rating",
      "description": "Our restaurant partners continually deliver fantastic walk-in and corporate guest experiences."
    },
    {
      "stats": "600k",
      "title": "Restaurant wait times delivered",
      "description": "Since 2020, Andie has helped restaurants like yours provide live information to potential guests nearby."
    }
  ]
  return (
    <section class="py-20 xl:py-24 bg-white">
      <div class="container px-4 mx-auto">
        <h3 class="mb-4 andie-section-title">By the numbers</h3>
        <p class="andie-section-description text-gray-500 max-w-xl">Flex is the only business platform that lets you run your business on one platform, seamlessly across all digital channels.</p>
        <div class="flex flex-wrap items-center text-center">
          <div class="w-full md:w-1/2 mb-16 md:mb-0">
            <div class="grid md:grid-cols-2 gap-x-8 gap-y-14 -mx-4">
              {numbers.map((number, index) => (
                <div class="w-full">
                  <h2 class="mb-4 text-4xl lg:text-5xl text-blue-600 andie-section-subheader tracking-tighter">{number.stats}</h2>
                  <h3 class="mb-2 andie-section-heading">{number.title}</h3>
                  <p class="andie-paragraph">{number.description}</p>
                </div>
              ))}
            </div>
            <div className="flex space-x-5 text-left mt-12">
              <Link to="/restaurant" className="border-b-2 border-blue-600 pb-2 text-blue-600 text-sm font-productsans-medium">
                Learn more about Andie corporate guest experiences
              </Link>
            </div>
            <div className="flex space-x-5 text-left mt-6">
              <Link to="/app" className="border-b-2 border-blue-600 pb-2 text-blue-600 text-sm font-productsans-medium">
                Drive traffic with the Andie app
              </Link>
            </div>
          </div>
          <div class="w-full md:w-1/2 px-4">
            <img class="mx-auto md:mr-0 rounded-2xl" src={require("../../../assets/images/andie-is-helping-restaurants-get-great-results.png")} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}
