import React from 'react'
import { BlogPost, BlogHeader, BlogResources, BlogMediaHighlights } from '../../components/pages/blog'

export default function Blog({blogData}) {
  return (
    <div class='andie-padding-horizontal'>
        <BlogHeader />
        <div className="flex">
          <div className="hidden xl:inline-block w-1/3">
            <h3 className='mt-10 andie-section-title max-w-xs'>Latest posts and insights</h3>
            <div className='mt-5 w-20 h-2 bg-blue-100' />
            <div className='mt-5 w-14 h-2 bg-blue-100' />
          </div>
          <BlogPost data={blogData}/>
        </div>
        <BlogMediaHighlights />
        <BlogResources />
    </div>
  )
}
