import React, { useState } from "react";
import { PlusIcon } from "@heroicons/react/solid";
import AndiePromiseRestaurants from "./items/AndiePromiseRestaurants";
import AndiePromiseOrganizations from "./items/AndiePromiseOrganizations";
import AndiePromiseGuests from "./items/AndiePromiseGuests";

export default function AndiePromiseValues() {
    const [selected, setSelected] = useState("Restaurants");
    const tabs = ["Restaurants", "Organizations", "Guests"];
    const reasons = [
        {
            "reason": "We always, and we will, answer any time and every time you call.",
            "explanation": "The Andie is always available to answer questions, troubleshoot issues, and help our guests, partner restaruants, and organizations."
        },
        {
            "reason": "We provide easy-to-use products and clear steps.",
            "explanation": "We don't believe in making our applications, software, or transactions complicated. That is why everything is clear."
        },
        {
            "reason": "We believe your time is valuable.",
            "explanation": "From guests to restaurants and organizations, we beleive your time valuable. That is why we take care of mundane steps in your dining process."
        },
        {
            "reason": "We believe in letting our experiences do the talking.",
            "explanation": "We believe the best way to grow Andie is to deliver a great experience that you would want to talk about to your friends, family, and colleagues."
        }
    ];
    return (
        <section class="overflow-hidden">
            <div class="relative container mx-auto px-4">
                <div class="relative z-10 flex items-center">
                    <div className="max-w-4xl mx-auto">
                        <div className="max-w-2xl mx-auto h-1 border-b-4 border-blue-100" />
                        <h3 className="andie-section-title text-center my-8">The name Andie comes from our believe that our platfrom should always be handy <span className="text-blue-600">(Or Andie)</span> when you need us.</h3>
                        <div className="max-w-2xl mx-auto h-1 border-b-4 border-blue-100" />

                        <div className="mt-8 max-w-3xl mx-auto">
                            {reasons.map((item, index) => (
                                <div className="flex items-center py-6 border-b border-blue-50">
                                    <div className="md:w-2/5 xl:pr-8">
                                        <h6 className="text-lg leading-tight font-productsans-medium">
                                            {item.reason}
                                        </h6>
                                    </div>
                                    <div className="md:w-3/5">
                                        <p className="andie-paragraph">{item.explanation}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="flex my-16 justify-center">
                            <div className="bg-blue-100 h-14 w-14 flex justify-center items-center rounded-full">
                                <PlusIcon className="h-9 text-blue-600" />
                            </div>
                        </div>
                        <div className="bg-blue-600 p-5 xl:p-8 rounded-3xl">
                            <div className="pb-3 mb-7 flex space-x-3 border-b border-blue-400">
                                {tabs.map((tab, index) => (
                                    <div onClick={() => setSelected(tab)} className={`cursor-pointer py-0.5 px-3.5 ${selected === tab && "bg-white rounded-full" }`}>
                                        <h6 className={`font-productsans-regular text-base ${selected === tab ? 'text-blue-600' : 'text-gray-100'}`}>
                                            {tab}
                                        </h6>
                                    </div>
                                ))}
                            </div>
                            {selected === "Restaurants" && <AndiePromiseRestaurants />}
                            {selected === "Organizations" && <AndiePromiseOrganizations />}
                            {selected === "Guests" && <AndiePromiseGuests />}
                        </div>
                        <div className="mt-12 mb-10 bg-blue-50 rounded-3xl h-52"></div>
                    </div>
                </div>
            </div>
        </section>
    )
}