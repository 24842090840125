import React from "react";
import AndieHeaderImage from "../../../../assets/images/andie-app-screenshot.png";
import { RequestADemoButton } from "../../../common";

export default function LandingApp() {
  return (
    <div class="flex flex-wrap justify-center bg-gray-100">
      <section class="py-10 md:py-20 overflow-hidden w-full max-w-7xl">
        <div class="flex flex-col">
          <div class="block md:hidden pb-5 object-top">
            <img
              class="object-cover h-[22rem] sm:h-[22rem] w-full object-bottom"
              src={AndieHeaderImage}
              alt="The Andie app helps restaruants drive real-time traffic to restaurants"
            />
            {/* <img class="scale-75 w-full max-w-md ml-10 mr-auto" src={require('../../../assets/images/art/andie-app-screenshot.png')} alt="The Andie app helps restaruants drive real-time traffic to restaurants"/> */}
            {/* Ideally need to center image */}
          </div>
        </div>
        <div class="container mx-auto ">
          <div class="flex flex-wrap items-center -m-8 text-center justify-center md:items-left md:text-left md:justify-left">
            <div class="w-full md:w-1/2 p-8 justify-center flex items-center">
              <img
                class="hidden md:block transform md:hover:translate-x-16 transition ease-in-out duration-1000  w-1/2"
                src={AndieHeaderImage}
                alt=""
              />
            </div>
            <div class="w-full pb-10 md:w-1/2 xl:flex-1 md:p-8 xl:p-12">
              <div class="xl:max-w-2xl">
                <h2 class="mb-7 andie-sectionheader">The Andie app</h2>
                <p class=" font-normal text-gray-600 font-pj">
                  Direct real time traffic to your restaurant
                </p>
                <p class=" font-normal text-gray-600 font-pj mb-7">
                  The most expensive item at your restaurant <br /> is an empty
                  table
                </p>
                <div class="-mb-4 md:inline-block rounded-xl shadow-4xl flex justify-center items-center md:flex-none md:items-left ">
                  <div class="flex flex-wrap">
                    {/* <a
                    class="inline-block px-6 py-4 mb-3 mr-4 text-sm font-medium leading-normal bg-indigo-600 hover:bg-indigo-700 text-white rounded transition duration-200"
                    href="#"
                  >
                    Request Demo
                  </a> */}
                    <RequestADemoButton />
                    <a
                      class="inline-block px-6 py-2 ml-4 mb-3 text-sm font-medium leading-normal focus:ring focus:ring-gray-300 bg-slate-200 hover:text-white hover:bg-zinc-600 hover:border-10 rounded border transition duration-200"
                      href="#"
                    >
                      Get The App
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
