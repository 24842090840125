import React from "react";
import {
  PartnerCallToAction,
  PartnerFred,
  PartnersProgram,
  PartnersStats,
  PartnersTestimonials,
} from "../components/pages/partners";

export default function Landing() {
  return (
    <div className="andie-padding-horizontal">
      <PartnersProgram />
      <PartnersTestimonials />
      <PartnerFred />
      <PartnersStats />
      <PartnerCallToAction />
    </div>
  );
}
