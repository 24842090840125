import React from "react";

export default function BlogMediaHighlights() {
    const spotlights = [
        {
            "title": "Bet on Yourself with Fred VanVleet",
            "category": "Podcast",
            "image": require("../../../assets/images/Bet-on-yourself.jpg"),
            "url": "https://open.spotify.com/episode/6sXGquCGboVxrKMV2bzgzd?si=776de3a1ffde400c",
            "description": "In episode 11 of Bet On Yourself, Derek Folk and Fred VanVleet sit down with Baba Ajayi, the CEO and founder of andie.work - a live information app that uses artificial intelligence to help both businesses and customers. Andie.work enables user input settings to give suggestions on which restaurants to go to - all based on user preferences.",
            "time": "1h 22mins"
        },
        {
            "title": "Marketing News Canada",
            "category": "Podcast",
            "image": require("../../../assets/images/ted-lau-podcasts.jfif"),
            "url": "https://marketingnewscanada.com/news-show/abdullah-snobar-baba-ajayi-dmz-andie",
            "description": "In this episode of Marketing News Canada, Ted Lau interviews Abdullah Snobar, Executive Director of The DMZ, and Baba Ajay, Chief Executive Officer at andie.",
            "time": "1h 00mins"
        },
        {
            "title": "Hamilton Spectator",
            "category": "ARTICLE",
            "image": "https://images.thestar.com/ZAJf3HLPlGnEMQZ3LckUW3dWa2E=/1280x1024/smart/filters:cb(1616684726835)/https://www.thespec.com/content/dam/thespec/business/2021/03/25/tech-startup-finds-new-footing-in-pandemic-but-stays-grounded-from-black-innovation-program-support/_01art.jpg",
            "url": "https://www.thespec.com/business/2021/03/25/tech-startup-finds-new-footing-in-pandemic-but-stays-grounded-from-black-innovation-program-support.html",
            "description": "Tech startup finds new footing in pandemic, but stays grounded from Black Innovation Program support",
            "time": "08mins"
        }
    ]
    return (
        <section class="mt-8 p-4 md:p-6 lg:p-8 xl:px-12 xl:py-20 bg-black rounded-xl overflow-hidden">
            <div class="container px-4 mx-auto">
                <h2 class="mb-6 andie-section-title text-white">Media Spotlight</h2>
                <p class="mb-20 text-gray-400 andie-section-description md:max-w-md">Hear the story behind Andie and our mission to bring people and businesses together in real-time.</p>
                <div class="flex flex-wrap xl:w-[90%] -m-8">
                    {spotlights.map((spotlight) => (
                        <div class="w-full md:w-1/3 p-4">
                            <div class="p-4 h-full bg-gray-900 bg-opacity-70 rounded-xl">
                                <div class="flex flex-col justify-between h-full">
                                    <div class="mb-8">
                                        <div class="mb-9 w-full overflow-hidden rounded-2xl">
                                            <img class="w-full h-52 object-cover transition ease-in-out duration-1000" src={spotlight.image} alt=""/>
                                        </div>
                                        <p class="mb-3 text-sm text-gray-400 font-sf-medium uppercase">{spotlight.category}</p>
                                        
                                        <a class="inline-block text-white font-productsans-bold hover:text-blue-600" href={spotlight.url}>
                                            <h3 class="text-xl font-bold font-heading leading-normal">{spotlight.title}</h3>
                                        </a>
                                        <p className="andie-paragraph mt-4">{spotlight.description}</p>
                                    </div>
                                    <p class="text-sm text-gray-400 font-medium mb-4">{spotlight.time}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}