import React from "react";
import { RequestDemoButton } from "../../common";
import { Link } from "react-router-dom";

export default function PricingGetStarted() {
    const resources = [
        {
            "title": "The Andie Promise",
            "description": "We cherish bringing people and restaurants together for great dining experiences.",
            "to": "andie-for-restaurants"
        },
        {
            "title": "For restaurants",
            "description": "Explore how Andie can help your restaurant drive traffic and revenue in real-time.",
            "to": "andie-for-restaurants"
        },
        {
            "title": "Corporate guest experiences",
            "description": "Enjoy great corporate dining experiences at our partner restaurants.",
            "to": "andie-corporate-guest-experiences"
        },
        {
            "title": "Success stories",
            "description": "Hear how Andie is helping partner restaurants, corporates, and organizations.",
            "to": "andie-success-stories"
        },
        {
            "title": "Help & FAQ",
            "description": "Get instant support or access the latest answers to frequently asked questions.",
            "to": "customer-success-center"
        },
        {
            "title": "Blog",
            "description": "The latest news and insights on hospitality industry, corporate catering and dining.",
            "to": "pricing"
        },
    ]

    return (
        <section class="relative py-24 md:pb-32 bg-white overflow-hidden">
            <div class="relative container px-4 mx-auto">
                <div class="xl:max-w-3xl mb-18 md:mb-16 mx-auto text-center">
                    <span class="inline-block py-px px-2 mb-4 text-xs leading-5 text-blue-600 bg-blue-100 font-roboto-medium uppercase rounded-full shadow-sm">Get Started</span>
                    <h1 class="mb-4 andie-section-title">Interested in a 15 minute demo on how Andie could work for you?</h1>
                    <p class="mb-6 mx-auto andie-section-description">Discover how we can deliver great experiences in as little as four clicks.</p>
                    <div className="mx-auto w-32">
                        <RequestDemoButton />
                    </div>
                </div>
                <div class="relative max-w-4xl flex flex-col items-center mx-auto">
                    <img class="relative rounded-full mx-auto" src={require("../../../assets/images/corporate-cge/get-started-with-cge.jpg")} alt="" />
                    <div className="mt-20 flex mx-auto space-x-5">
                        <div className="h-4 w-4 bg-blue-200 rounded-full" />
                        <div className="h-4 w-4 bg-green-200 rounded-full" />
                        <div className="h-4 w-4 bg-yellow-200 rounded-full" />
                    </div>
                </div>
                <div class="pt-24 flex flex-wrap -m-8">
                    {resources.map((resource, index) => (
                        <div class="w-full md:w-1/3 p-8">
                            <div class="md:max-w-xs">
                                <div class="flex flex-col justify-between h-full">
                                    <div class="mb-7">
                                        <h3 class="mb-7 text-xl font-semibold leading-snug font-productsans-bold">{resource.title}</h3>
                                        <p class="text-gray-600 andie-paragraph">{resource.description}</p>
                                    </div>
                                    <Link class="inline-flex items-center max-w-max text-blue-600 hover:text-blue-700 pb-2 border-b-2 border-blue-600" to={resource.to}>
                                        <span class="mr-2 text-sm font-productsans-medium">Learn more</span>
                                        <svg width="18" height="18" viewbox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.5 3.75L15.75 9M15.75 9L10.5 14.25M15.75 9L2.25 9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}