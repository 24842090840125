import React from "react";
import { CheckIcon } from "@heroicons/react/solid";

export default function PricingHeader() {
  const benefits = [
    "Free to start",
    "End-to-end transactions",
    "Dedicated support"
  ]
  return (
    <section class="relative pb-24 overflow-hidden">
      <div class="mt-12 pt-24 pb-52 bg-blue-50 rounded-3xl">
        <div class="container px-4 mx-auto">
          <div class="max-w-2xl lg:max-w-3xl mx-auto mb-24 text-center">

            <h1 class="andie-page-title text-gray-900 mb-6">
              Pricing that works for everyone.
            </h1>
            <p class="andie-page-description max-w-lg mx-auto">Andie is priced to help our partners grow and thrive, and deliver great value and flexibility to their guests and customers.</p>
          </div>
        </div>
      </div>
      <div class="container px-4 mx-auto">
        <div class="max-w-md lg:max-w-4xl mx-auto">
          <div class="-mt-52 mb-16 lg:mb-8 py-9 px-8 sm:px-14 bg-white rounded-3xl shadow-xl">
            <div class="flex flex-wrap -mx-4 items-center">
              <div class="w-full lg:w-2/5 mb-8 lg:mb-0 px-4 pb-12 lg:py-4 border-b lg:border-0 lg:border-r border-gray-100">
                <div class="text-center">
                  <span class="block text-sm font-roboto-bold mb-8">One pricing approach for all.</span>
                  <span class="block text-5xl font-productsans-bold text-gray-900">$0.00*</span>
                  <span class="block text-xl font-roboto-bold mb-5">to start</span>
                  <a class="relative group inline-flex h-10 px-10 font-productsans-medium items-center text-orange-50 bg-blue-600 rounded-sm overflow-hidden transition duration-200" href="/request-a-demo">
                    <div class="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                    <span class="relative">Get Started</span>
                  </a>
                </div>
              </div>
              <div class="w-full lg:w-3/5 px-4 py-2">
                <div class="max-w-sm ml-auto">
                  <p class="text-gray-500 font-productsans-regular mb-8">No matter what brings you to the Andie platform or who you are, you can count on enjoying these benefits.</p>
                  <ul>
                    {benefits.map((benefit, index) => (
                      <li class="flex mb-6 items-center">
                        <div className="h-5 w-5 bg-green-200 rounded-full flex items-center justify-center">
                          <CheckIcon className="h-4 text-green-800" />
                        </div>
                        <span class="ml-2 andie-paragraph text-gray-900">{benefit}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="lg:flex items-center justify-center">
            <div class="flex mb-12 lg:mb-0 sm:mr-16 xl:pr-6 items-center max-w-sm">
              <div class="flex flex-shrink-0 w-12 h-12 mr-4 items-center justify-center rounded-full bg-blue-100">
                <svg width="64px" height="64px" className="h-8" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" fill="#2563eb"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Layer_2" data-name="Layer 2"> <g id="invisible_box" data-name="invisible box"> <rect width="48" height="48" fill="none"></rect> </g> <g id="Layer_7" data-name="Layer 7"> <g> <path d="M34.3,20.1h0a6.7,6.7,0,0,1-4.1-1.3,2,2,0,0,0-2.8.6,1.8,1.8,0,0,0,.3,2.6A10.9,10.9,0,0,0,32,23.8V26a2,2,0,0,0,4,0V23.8a6.3,6.3,0,0,0,3-1.3,4.9,4.9,0,0,0,2-4h0c0-3.7-3.4-4.9-6.3-5.5s-3.5-1.3-3.5-1.8.2-.6.5-.9a3.4,3.4,0,0,1,1.8-.4,6.3,6.3,0,0,1,3.3.9,1.8,1.8,0,0,0,2.7-.5,1.9,1.9,0,0,0-.4-2.8A9.1,9.1,0,0,0,36,6.3V4a2,2,0,0,0-4,0V6.2c-3,.5-5,2.5-5,5.2s3.3,4.9,6.5,5.5,3.3,1.3,3.3,1.8S35.7,20.1,34.3,20.1Z"></path> <path d="M42.2,31.7a5.2,5.2,0,0,0-4-1.1l-9.9,1.8a4.5,4.5,0,0,0-1.4-3.3L19.8,22H5a2,2,0,0,0-2,2v9a2,2,0,0,0,2,2H8.3l11.2,9.1,20.4-3.7a5,5,0,0,0,2.3-8.7Zm-3,4.8L20.5,39.9,10,31.2V26h8.2l5.9,5.9a.8.8,0,0,1-1.2,1.2l-3.5-3.5a2,2,0,0,0-2.8,2.8l3.5,3.5a4.5,4.5,0,0,0,3.4,1.4,5.7,5.7,0,0,0,1.8-.3h0l13.6-2.4a1.1,1.1,0,0,1,.8.2.9.9,0,0,1,.3.7A1,1,0,0,1,39.2,36.5Z"></path> </g> </g> </g> </g></svg>
              </div>
              <p class="text-base leading-tight font-roboto-bold text-gray-700">Pay-as-you-go. No monthly subscriptions or long-term contracts.</p>
            </div>
            <div class="flex items-center max-w-sm">
              <div class="flex flex-shrink-0 w-12 h-12 mr-4 items-center justify-center rounded-full bg-blue-100">
              <svg width="64px" height="64px" className="h-8" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" fill="#2563eb"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>certificate-star-solid</title> <g id="Layer_2" data-name="Layer 2"> <g id="invisible_box" data-name="invisible box"> <rect width="48" height="48" fill="none"></rect> <rect width="48" height="48" fill="none"></rect> </g> <g id="icons_Q2" data-name="icons Q2"> <g> <path d="M40,18A16,16,0,1,0,15,31.2V43.9a2,2,0,0,0,3.1,1.7L24,41l5.9,4.6A2,2,0,0,0,33,43.9V31.2A16,16,0,0,0,40,18ZM12,18A12,12,0,1,1,24,30,12,12,0,0,1,12,18Z"></path> <path d="M20.4,19.7l-.5,3.1a1.1,1.1,0,0,0,1.5,1.1L24,22l2.6,1.9a1.1,1.1,0,0,0,1.5-1.1l-.5-3.1,2.1-2a1.1,1.1,0,0,0-.6-1.8l-2.9-.4-1.3-2.9a1,1,0,0,0-1.8,0l-1.3,2.9-2.9.4a1.1,1.1,0,0,0-.6,1.8Z"></path> </g> </g> </g> </g></svg>
              </div>
              <p class="text-base leading-tight font-roboto-bold text-gray-700">Pay for value. We only believe in making money when you are happy.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
