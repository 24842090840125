import React from "react";
import {
  AndiePage,
  AndieTestimonials,
  AndieBenefits,
  AndieStoreApp,
} from "../../components/pages/general/app";

export default function AndieApp() {
  return (
    <div>
      <AndieStoreApp />
      <AndieBenefits />
      <AndieTestimonials />
      <AndiePage />
    </div>
  );
}
