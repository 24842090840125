import AndieHeaderImage from "../../../../assets/images/WhatsApp Image 2023-02-16 at 4.33.20 PM.jpeg";
import { RequestADemoButton } from "../../../common";

export default function AndiePage() {
  return (
    <div class="flex flex-wrap justify-center sm:bg-indigo-50">
      <section class="py-10 md:py-20 max-h-[700px] md:max-h-full md:max-w-7xl">
        {/* This image only shows when screen is resized. Changing the scale number at the front i.e., scale-50 should change the scaling. */}
        <div class="flex flex-col">
          <div class="block md:hidden pb-5">
            <img
              class="object-cover h-[22rem] sm:h-[22rem] w-full object-bottom"
              src={AndieHeaderImage}
              alt="The Andie app helps restaruants drive real-time traffic to restaurants"
            />
            {/* <img class="scale-75 w-full max-w-md ml-10 mr-auto" src={require('../../../assets/images/art/andie-app-screenshot.png')} alt="The Andie app helps restaruants drive real-time traffic to restaurants"/> */}
            {/* Ideally need to center image */}
          </div>
        </div>
        <div class="container px-4 mx-auto pt-2">
          <div class="flex flex-wrap items-center -mx-4 h-full">
            <div class="flex flex-wrap items-center w-full md:w-1/2 px-4 mb-6 md:mb-0">
              <div class="md:pl-10">
                <h2 class="mt-8 mb-4 lg:mb-7 andie-sectionheader">
                  The Andie app
                </h2>
                <div class="pl-2 max-w-lg mb-7 lg:mb-12">
                  <p class="mt-2 font-normal text-gray-600 font-pj max-w-md sm:mt-6 lg:mr-10 mb-7">
                    Direct real time traffic to your restaurant. <br /> The most
                    expensive item at your restaurant <br />
                    is an empty table.
                  </p>
                </div>
                {/* <a
                  class="inline-block px-6 py-4 mb-3 mr-4 text-sm font-medium leading-normal bg-indigo-600 hover:bg-indigo-700 text-white rounded transition duration-200"
                  href="#"
                >
                  Request a Demo
                </a> */}
                <div class="flex flex-wrap pl-2 sm:pl-0">
                  <RequestADemoButton />
                  <a
                    class="inline-block px-6 py-2 ml-4 mb-3 text-sm font-medium leading-normal focus:ring focus:ring-gray-300 bg-slate-200 hover:text-white hover:bg-zinc-600 hover:border-10 rounded border transition duration-200"
                    href="#"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
            <div class="collapse max-h-[700px] md:visible relative md:w-1/2 px-4 transform hover:-translate-x-16 transition ease-in-out duration-1000">
              <img class="rounded-lg shadow-md hover:shadow-lg shadow-slate-500 transition ease-in-out duration-1000 relative" src={AndieHeaderImage} alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
