import React from "react";
import { Link } from "react-router-dom";
import paymentmethod from "../../../../../assets/images/header-icons/payment-method.svg";
import dedicatedsupport from "../../../../../assets/images/header-icons/dedicated-support.svg";
import support from "../../../../../assets/images/header-icons/24support.svg"; 

export default function RestaurantCGEFeatures() {
    return (
        <section class="relative py-20 overflow-hidden">
            <div class="relative container px-4 mx-auto">
                <div class="max-w-3xl mx-auto mb-14 text-center">
                    <h1 class="andie-section-title mb-6">
                        24/7 Support and more
                    </h1>
                    <p class="max-w-2xl mx-auto andie-section-description text-gray-500">Executing big-ticket bookings can be challenging. That is why we provide our restaurant partners with everything they need to deliver a great experience.</p>
                </div>
                <div class="mx-auto px-8 py-18 xs:px-12 sm:p-16 bg-blue-50 rounded-3xl">
                    <div class="flex flex-wrap -mx-4">
                        <div class="w-full lg:w-1/2 px-4 order-last lg:order-first">
                            <div class="lg:max-w-md">
                                <div class="flex pb-8 mb-8 border-b border-blue-200 border-opacity-50">
                                    <div class="flex flex-shrink-0 w-14 h-14 mr-6 items-center justify-center bg-blue-100 rounded-full">
                                        <img src={paymentmethod} className="h-8" alt="" />
                                    </div>
                                    <div>
                                        <h3 class="andie-section-heading">Payment schedules</h3>
                                        <p class="andie-paragraph">Flexible payment options, including payment advances, ensure that you have the resources to execute.</p>
                                    </div>
                                </div>
                                <div class="flex pb-8 mb-8 border-b border-blue-200 border-opacity-50">
                                    <div class="flex flex-shrink-0 w-14 h-14 mr-6 items-center justify-center bg-blue-100 rounded-full">
                                        <img src={dedicatedsupport} className="h-7" alt="" />
                                    </div>
                                    <div>
                                        <h3 class="andie-section-heading">Dedicated growth specialist</h3>
                                        <p class="andie-paragraph">Your dedicated growth specialist works with you to ensure that you get the most out of each booking.</p>
                                    </div>
                                    {/* <div>
                                        <h3 class="andie-section-heading">Unlimited bookings and bidding</h3>
                                        <span class="andie-paragraph">The Andie CGE platform allows you to respond to more than one bid or win multiple bids at once.</span>
                                    </div> */}
                                </div>
                                <div class="flex">
                                    <div class="flex flex-shrink-0 w-14 h-14 mr-6 items-center justify-center bg-blue-100 rounded-full">
                                        <img src={support} className="h-7" alt="" />
                                    </div>
                                    <div>
                                        <h3 class="andie-section-heading">24/7 Support</h3>
                                        <p class="andie-paragraph">The Andie CGE team provides 24/7 support and constant touchpoints for all bookings.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-full lg:w-1/2 px-4 mb-20 lg:mb-0">
                        <div class="flex flex-col h-full justify-between lg:max-w-sm ml-auto">
                            <div>
                                <h3 class="andie-section-title mb-5">Focus on what you do best.</h3>
                                <p class="andie-section-heading font-productsans-regular">We'll help with the rest to us.</p>
                            </div>
                            <div class="flex flex-wrap items-center justify-between">
                                <Link to="/request-a-demo" class="relative group inline-flex w-full sm:w-auto h-9 items-center px-6 text-orange-50 bg-blue-600 rounded-sm overflow-hidden">
                                    <div class="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                                    <div class="relative flex items-center justify-center">
                                        <span class="mr-2 text-xs font-productsans-medium">Get your CGE account</span>
                                        <span>
                                            <svg width="13" height="13" viewbox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.9199 0.62C12.8185 0.375651 12.6243 0.181475 12.3799 0.0799999C12.2597 0.028759 12.1306 0.00157999 11.9999 0H1.99994C1.73472 0 1.48037 0.105357 1.29283 0.292893C1.1053 0.48043 0.999939 0.734784 0.999939 1C0.999939 1.26522 1.1053 1.51957 1.29283 1.70711C1.48037 1.89464 1.73472 2 1.99994 2H9.58994L1.28994 10.29C1.19621 10.383 1.12182 10.4936 1.07105 10.6154C1.02028 10.7373 0.994141 10.868 0.994141 11C0.994141 11.132 1.02028 11.2627 1.07105 11.3846C1.12182 11.5064 1.19621 11.617 1.28994 11.71C1.3829 11.8037 1.4935 11.8781 1.61536 11.9289C1.73722 11.9797 1.86793 12.0058 1.99994 12.0058C2.13195 12.0058 2.26266 11.9797 2.38452 11.9289C2.50638 11.8781 2.61698 11.8037 2.70994 11.71L10.9999 3.41V11C10.9999 11.2652 11.1053 11.5196 11.2928 11.7071C11.4804 11.8946 11.7347 12 11.9999 12C12.2652 12 12.5195 11.8946 12.707 11.7071C12.8946 11.5196 12.9999 11.2652 12.9999 11V1C12.9984 0.869323 12.9712 0.740222 12.9199 0.62V0.62Z" fill="#FFF2EE"></path>
                                            </svg>
                                        </span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}