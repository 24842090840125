import React from "react";

export default function HomeIntroducingAndie() {
    return (
        <section class="relative border-t-2 border-b-2 border-gray-100 border-dashed my-24 py-24 overflow-hidden">
  <img class="absolute top-0 right-0" src="saturn-assets/images/features/star-element-right.png" alt="" />
  <div class="relative container px-4 mx-auto">
    <div class="mx-auto">
      <div class="flex flex-wrap mb-18 justify-between">
        <div class="w-full lg:w-2/3 xl:w-1/2 mb-12 lg:mb-0">
            <div class="max-w-xl xl:mb-8">
                <span class="inline-block py-1 px-3 mb-4 text-xs font-semibold uppercase text-orange-900 bg-orange-50 rounded-full">Why Andie</span>
                <h1 class="andie-section-title">
                    Your restaurant's daily goals are simple
                </h1>
                <ul class="list-disc ml-6 my-2">
                    <li className="text-lg text-gray-500 font-productsans-medium">Get as many people as you can through the doors.</li>
                    <li className="text-lg text-gray-500 font-productsans-medium">Earn the most revenue possible.</li>
                </ul>
                <h4 className="font-productsans-medium text-blue-600 text-xl">Andie helps make that happen.</h4>
            </div>
        </div>
        <div class="w-full lg:w-1/3 xl:w-1/2 lg:pt-10">
        <div class="grid grid-cols-4 gap-4 lg:pl-14 xl:pl-28">
          <div class="w-auto">
              <img src={require("../../../assets/images/reslogos/hothouse.png")}  className="h-11 w-full grayscale object-contain hover:grayscale-0" alt="" />
          </div>
          <div class="w-auto">
              <img src={require("../../../assets/images/reslogos/la-diperie.webp")}  className="h-11 w-full grayscale object-contain hover:grayscale-0" alt="" />
          </div>
          <div class="w-auto">
              <img src={require("../../../assets/images/reslogos/bymark.jpg")}  className="h-14 -mt-1 w-full grayscale object-contain hover:grayscale-0" alt="" />
          </div>
          <div class="w-auto">
              <img src={require("../../../assets/images/reslogos/moon-palace.png")}  className="h-11 w-full grayscale object-contain hover:grayscale-0" alt="" />
          </div>
          <div class="w-auto">
              <img src={require("../../../assets/images/reslogos/brasa.jpg")}  className="h-11 w-full grayscale object-contain hover:grayscale-0" alt="" />
          </div>
          <div class="w-auto">
              <img src={require("../../../assets/images/reslogos/halal-guys.png")}  className="h-11 w-full grayscale object-contain hover:grayscale-0" alt="" />
          </div>
          <div class="w-auto">
              <img src={require("../../../assets/images/reslogos/ascari.png")}  className="h-11 w-full grayscale object-contain hover:grayscale-0" alt="" />
          </div>
          <div class="w-auto">
              <img src={require("../../../assets/images/reslogos/ono-poke.png")}  className="h-11 w-full grayscale object-contain hover:grayscale-0" alt="" />
          </div>
        </div>
        </div>
      </div>
      <div class="mt-10 grid grid-cols-3 gap-6 xl:gap-16">
        <div class="w-full mb-12 lg:mb-0">
          <div>
          <img class="block mb-6 w-full h-100 rounded-xl" src={require("../../../assets/images/home/drive-traffic-in-real-time.png")} alt="" />
            <div>
              <h3 class="andie-section-subheader leading-tight mb-5">Attract nearby and searching guests to your restaurant with live information.</h3>

            </div>
          </div>
        </div>
        <div class="w-full mb-12 lg:mb-0">
          <div>
            <img class="block mb-6 w-full h-100 rounded-xl" src={require("../../../assets/images/home/discover-revenue-opportunities.png")} alt="" />
            <div>
              <h3 class="andie-section-subheader leading-tight mb-5">Discover unique revenue opportunities with corporate catering, dining, and more.</h3>
            </div>
          </div>
        </div>
        <div class="w-full">
          <div>
            <img class="block mb-6 w-full h-100 rounded-xl" src={require("../../../assets/images/home/attract-nearby-guests-and-traffic.png")} alt="" />
            <div>
              <h3 class="andie-section-subheader leading-tight mb-5">Unlock real-time data and insights that help you make better business decisions.</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    )
}