import { Link } from "react-router-dom";

export default function RequestADemoButton() {
    return (
        <div>
           <Link to="/request-a-demo" className="inline-block px-6 py-2 mb-3 text-sm font-medium leading-normal focus:ring focus:ring-indigo-300 bg-blue-700 hover:bg-blue-800 text-white rounded transition duration-200 relative andie-dark-button" role="button">
                Request a demo
            </Link> 
        </div>
    )
}
