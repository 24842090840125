export default function Card({ prop }) {
  return (
    <div
      className="
        z-0
        flex
        justify-center
        w-full h-[500px] md:w-full md:h-[880px]
        items-center
        brightness-[0.3]
        contrast-125 
        "
    >
      <img class="object-cover w-full h-[500px] md:w-full md:h-[880px] opacity-75" src={prop} alt="" />
    </div>
  );
}
