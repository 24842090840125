import React from "react";

export default function PricingHighlights() {
    const points = [ 
        {
            "heading": "Reviews",
            "number": "4.9",
            "tag": "Average rating"
        },
        {
            "heading": "Cuisine",
            "number": "200+",
            "tag": "Partner restaurants"
        },{
            "heading": "Bookings",
            "number": "21,394+",
            "tag": "Guest experiences"
        }

    ]
    return (
        <section class="relative py-20 px-6 xl:px-12 bg-blue-600 overflow-hidden rounded-3xl">
            <div class="relative z-10 container px-4 mx-auto">
                <div class="mb-24 border-b border-white border-opacity-10">
                <h2 class="pb-24 andie-section-title text-white font-bold tracking-px-n leading-none md:max-w-xl">We are on a mission to bring restaurants and their guests together for great guest experiences.</h2>
                </div>
                <div class="flex flex-wrap -m-8">
                    {points.map((point, index) => (
                        <div class="w-full md:w-1/3 p-8">
                            <p class="mb-6 text-sm text-gray-200 font-semibold leading-normal">{point.heading}</p>
                            <h3 class="text-6xl md:text-8xl xl:text-10xl font-productsans-bold text-white font-bold tracking-px-n leading-none">{point.number}</h3>
                            <p class="text-gray-300 font-roboto-medium leading-relaxed md:w-56">{point.tag}.</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}