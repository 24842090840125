import React, { useState } from "react";
import PricingRestaurant from "./items/PricingRestaurant";
import PricingRestaurantFAQ from "./items/PricingRestaurantFAQ";
import PricingCorporateFAQ from "./items/PricingCorporateFAQ";
import PricingCorporate from "./items/PricingCorporate";

export default function PricingTabs() {
    const [selected, setSelected] = useState("Restaurants");
    const tabs = [
        "Restaurants", 
        "Corporate Guest Experiences"
    ]
    return (
        <div className="pt-20">
            <div className="flex space-x-7 border-b border-gray-100 mb-10">
                {tabs.map((tab, index) => (
                    <div onClick={() => setSelected(tab)} className={`cursor-pointer h-9 inline-flex text-gray-600 font-productsans-bold ${selected === tab && "text-blue-600 border-b-2 border-blue-600"}`}>
                        {tab}
                    </div>
                ))}
            </div>
            {selected === "Restaurants" && 
                <div>
                    <PricingRestaurant />
                    <PricingRestaurantFAQ />
                </div>
            }
            {selected === "Corporate Guest Experiences" && 
                <div>
                    <PricingCorporate />
                    <PricingCorporateFAQ />
                </div>
            }
        </div>
    )
}