import React from "react";
import { Link } from "react-router-dom";

export default function AndiePromiseAdditionalResources() {
    return (
      <section class="relative py-20">
        <div class="container px-4 mx-auto">
          <div class="max-w-2xl mb-28 mx-auto text-center">
            <span class="text-sm text-blue-600 font-roboto-medium uppercase">Additional Resources</span>
            <h2 class="mt-8 mb-10 andie-section-title">Explore how Andie delivers on our promise.</h2>
            <p class="max-w-lg mx-auto andie-section-description text-gray-500">Learn about how Andie brings restaurants, organizations, and guests together for great corporate guest experiences.</p>
          </div>
          <div class="flex flex-wrap -mx-3 -mb-10 lg:-mb-16">
            <div class="w-full md:w-1/2 lg:w-1/4 px-3 mb-10 lg:mb-16">
              <div class="relative pt-16 pb-12 px-8 border-2 border-gray-100 rounded-lg text-center hover:border-white hover:shadow-2xl">
                <span class="absolute top-0 inset-x-0 -mt-6 flex justify-center items-center w-12 h-12 font-roboto-medium mx-auto rounded-full bg-blue-400 text-white">1</span>
                <h3 class="mb-4 text-xl font-productsans-medium">Success stories</h3>
                <p class="andie-paragraph">Read success stories and case studies from our restaurant partners.</p>
                <Link to="/andie-success-stories" className="mt-7 text-sm font-productsans-medium text-blue-600 pb-1.5 inline-flex border-b-2 border-blue-600">
                  Learn more
                </Link>
              </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/4 px-3 mb-10 lg:mb-16">
              <div class="relative pt-16 pb-12 px-8 border-2 border-gray-100 rounded-lg text-center hover:border-white hover:shadow-2xl">
                <span class="absolute top-0 inset-x-0 -mt-6 flex justify-center items-center w-12 h-12 font-roboto-medium mx-auto rounded-full bg-teal-500 text-white">2</span>
                <h3 class="mb-4 text-xl font-productsans-medium">Frequently asked questions</h3>
                <p class="andie-paragraph">Get answers to key questions or reach out for additional support.</p>
                <Link to="/customer-success-center" className="mt-7 text-sm font-productsans-medium text-blue-600 pb-1.5 inline-flex border-b-2 border-blue-600">
                  Learn more
                </Link>
              </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/4 px-3 mb-10 lg:mb-16">
              <div class="relative pt-16 pb-12 px-8 border-2 border-gray-100 rounded-lg text-center hover:border-white hover:shadow-2xl">
                <span class="absolute top-0 inset-x-0 -mt-6 flex justify-center items-center w-12 h-12 font-roboto-medium mx-auto rounded-full bg-red-500 text-white">3</span>
                <h3 class="mb-4 text-xl font-productsans-medium">Pricing</h3>
                <p class="andie-paragraph">Learn about how we make money only when you're happy.</p>
                <Link to="/pricing" className="mt-7 text-sm font-productsans-medium text-blue-600 pb-1.5 inline-flex border-b-2 border-blue-600">
                  Learn more
                </Link>
              </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/4 px-3 mb-10">
              <div class="relative pt-16 pb-12 px-8 border-2 border-gray-100 rounded-lg text-center hover:border-white hover:shadow-2xl">
                <span class="absolute top-0 inset-x-0 -mt-6 flex justify-center items-center w-12 h-12 font-roboto-medium mx-auto rounded-full bg-yellow-400 text-white">4</span>
                <h3 class="mb-4 text-xl font-productsans-medium">Blog</h3>
                <p class="andie-paragraph">Stay up-to date on the latest resources and insights.</p>
                <Link to="/blog" className="mt-7 text-sm font-productsans-medium text-blue-600 pb-1.5 inline-flex border-b-2 border-blue-600">
                  Learn more
                </Link>
              </div>
            </div>
          </div>
          <div className="mt-10 max-w-3xl text-center mx-auto">
            <Link to="/cge" className="mt-7 text-sm font-productsans-medium text-blue-600 pb-1.5 inline-flex border-b-2 border-blue-600">
              Explore how Andie creates great guest experiences
            </Link>
          </div>
          <div className="max-w-3xl text-center mx-auto">
            <Link to="/restaurant" className="mt-7 text-sm font-productsans-medium text-blue-600 pb-1.5 inline-flex border-b-2 border-blue-600">
              Drive traffic to your restaurant with Andie
            </Link>
          </div>
        </div>
      </section>
    )
}