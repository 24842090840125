import { StarIcon } from "@heroicons/react/solid";
import React from "react";
import { RequestDemoButton, TalkToSalesButton } from "../../../../common";

export default function CGEHeader() {
    return (
      <section class="border-b overflow-hidden">
        <div class="relative container mx-auto px-4">
          <div class="relative z-10 flex flex-wrap justify-center items-center -m-6 lg:pt-32 lg:pb-28">
            <div class="w-full lg:w-auto p-6 self-start">
              <img class="xl:relative border-8 border-blue-100 xl:-top-20 h-52 w-52 object-cover rounded-full mx-auto" src={require("../../../../../assets/images/corporate-cge/cge-header-image-1.png")} alt="" />
            </div>
            <div class="flex-1 p-5">
              <div class="lg:max-w-3xl mx-auto">
                <div class="mb-6 font-heading flex items-center max-w-max mx-auto px-4 py-1.5 uppercase text-xs font-roboto-bold bg-yellow-50 rounded-full">
                  <StarIcon className="h-4 mr-0.5 text-yellow-400" />
                  <p>Corporate dining and catering</p>
                </div>
                <h1 class="mb-6 font-heading text-center andie-page-title">Great corporate guest experiences in just <span className="text-blue-600">four clicks</span>.</h1>
                <p class="mb-10 andie-page-description max-w-xl mx-auto text-center">Forward-thinking organizations trust Andie for their corporate dining and catering - office lunches, team outings, client meetings, corporate events, and more.</p>
                <div class="flex mx-auto justify-center flex-wrap -m-2.5">
                  <div class="w-full md:w-auto p-2.5">
                    <div class="block">
                      <RequestDemoButton />
                    </div>
                  </div>
                  <div class="w-full md:w-auto p-2.5">
                    <div class="block">
                      <TalkToSalesButton />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full lg:w-auto p-6 self-end">
              <img class="xl:relative border-8 border-blue-100 xl:-bottom-10 h-60 w-60 object-cover rounded-full mx-auto" src={require("../../../../../assets/images/corporate-cge/cge-header-image-2.png")} alt="" />
            </div>
          </div>
        </div>
      </section>
    );
}
