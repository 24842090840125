import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { VerifyUser } from '../../../api/Authentication';
import { useNavigate } from 'react-router-dom';

export default function Activate() {
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();
    const pageparams = useParams()
    useEffect(() => {
        Verify()
    }, []);
    async function Verify() {
        const verification = await VerifyUser(pageparams.uid, pageparams.token)
        if (verification === true){
            setLoaded(true);
        } else {
            alert(verification)
        }
    }

    const handleNavigation = () => {
        navigate('/login')
    }

    return (
        <>
            <div className="mx-4">
                {loaded
                    ?
                    <>
                        <div className="flex flex-col h-[80vh] w-full items-center justify-center gap-y-8">
                            <h1 className='text-4xl text-center font-bold'>
                                Account activated!
                            </h1>
                            <button onClick={handleNavigation} className='px-8 py-2 bg-green-600 text-2xl text-white rounded-lg font-semibold'>Continue</button>
                        </div>
                    </>
                    :
                    <>
                        <div className="flex flex-col h-[80vh] w-full items-center justify-center gap-y-8">
                            <div className="animate-spin rounded-full h-16 w-16">
                                <img
                                    src={require('../../../assets/images/andie-icon.png')}
                                />
                            </div>
                            <h1 className='text-4xl text-center font-bold'>
                                Activating...
                            </h1>
                        </div>
                    </>
                }
            </div>
        </>
    )
}