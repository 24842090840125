import React from "react";
import { MailIcon, PhoneIcon } from "@heroicons/react/solid";

export default function FAQHeader() {
    return (
        <section class="py-24 bg-white overflow-hidden">
            <div class="container px-4 mx-auto">
                <div class="flex flex-wrap -m-8">
                    <div class="w-full md:w-1/2 p-8">
                        <div className="border-l-8 border-blue-50 pl-4 lg:pl-10">
                            <div class="inline-block mb-6 px-2 py-1 font-semibold bg-blue-100 rounded-full">
                                <div class="flex flex-wrap items-center -m-1">
                                    <div class="w-auto p-1"><a class="text-sm" href="">&#x1F44B; No system updates or disruptions.</a></div>
                                </div>
                            </div>
                            <h2 class="mb-6 andie-page-title xl:max-w-lg">Support Center.</h2>
                            <h5 className="font-productsans-medium text-base text-gray-700 max-w-sm">For expedited support from our 24/7 Support Center, please contact us directly by telephone or email.</h5>
                        </div>
                        <div className="mt-24 px-4 xl:mt-12 shadow-xl border-gray-100 grid lg:grid-cols-2 gap-x-5 max-w-lg rounded-full h-16">
                            <div className="flex items-center">
                                <div className="h-8 w-8 flex items-center justify-center bg-blue-100 rounded-full">
                                    <MailIcon className="h-4 text-blue-600" />
                                </div>
                                <div className="ml-3 flex flex-col">
                                    <h6 className="font-productsans-medium text-[13px] text-gray-700">Email</h6>
                                    <p className="-mt-1 andie-paragraph">hospitality@andie.work</p>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <div className="h-8 w-8 flex items-center justify-center bg-blue-100 rounded-full">
                                    <PhoneIcon className="h-4 text-blue-600" />
                                </div>
                                <div className="ml-3 flex flex-col">
                                    <h6 className="font-productsans-medium text-[13px] text-gray-700">Phone number</h6>
                                    <p className="-mt-1 andie-paragraph">+1 647 948 9778</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-full md:w-1/2 px-8">
                        <div class="max-w-max mx-auto overflow-hidden rounded-4xl">
                            <img class="rounded-3xl border-8 border-blue-50" src={require("../../../../assets/images/andie-helps-drive-business-guaranteed.png")} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}