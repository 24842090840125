import React from "react";
import { RequestDemoButton } from "../../common";
import { CheckCircleIcon } from "@heroicons/react/solid";

export default function HomePricing() {
    return (
        <section class="pb-24 bg-blueGray-50 overflow-hidden">
            <div class="container px-4 mx-auto">
                <h2 class="mb-24 andie-section-title text-center md:max-w-3xl mx-auto">Drive as many guests as possible to your restaurant without breaking the bank.</h2>
                <div class="mb-16 md:max-w-6xl mx-auto">
                <div class="flex flex-wrap -m-4">
                    <div class="w-full md:w-1/3 p-4">
                    <div class="flex flex-col px-9 pt-8 pb-9 h-full bg-white bg-opacity-90 border-2 border-gray-100 rounded-2xl">
                        <div>
                        <span class="mb-9 inline-block text-sm text-indigo-600 font-semibold uppercase tracking-px leading-snug">Pay-as-you-go</span>
                        <h3 class="mb-1 text-4xl text-gray-900 font-productsans-bold leading-none">$1.00</h3>
                        <p class="mb-6 text-sm text-gray-600 font-roboto-medium leading-relaxed">per guest</p>
                        <p class="mb-4 andie-paragraph">Pay only when Andie sends you a guest. Our flexible pricing guarantees value at all times.</p>
                        </div>
                    </div>
                    </div>
                    <div class="w-full md:w-1/3 p-4">
                    <div class="relative flex flex-col px-9 pt-8 pb-9 h-full bg-white bg-opacity-90 border-2 border-gray-100 rounded-2xl">

                        <div class="relative z-10">
                        <span class="mb-9 inline-block text-sm text-indigo-600 font-semibold uppercase tracking-px leading-snug">Transaction fees</span>
                        <h3 class="mb-1 text-4xl text-gray-900 font-bold leading-none">%</h3>
                        <p class="mb-6 text-sm text-gray-600 font-roboto-medium leading-relaxed">billed instantly</p>
                        <p class="mb-9 andie-paragraph">All transactions on the Andie platform are subject to an applicable service fees.</p>
                        <RequestDemoButton />
                        </div>
                    </div>
                    </div>
                    <div class="w-full md:w-1/3 p-4">
                        <div class="flex flex-col px-9 pt-8 pb-9 h-full bg-green-50 rounded-2xl">
                            <div>
                            <span class="mb-9 inline-block text-sm text-green-600 font-semibold uppercase tracking-px leading-snug">No hidden fees</span>
                            <h3 class="mb-1 text-4xl text-gray-900 font-productsans-bold leading-none">$0</h3>
                            <p class="mb-6 text-sm text-gray-600 font-roboto-medium leading-relaxed">startup fees</p>
                            <p class="mb-4 andie-paragraph">Skip the startup fees, subscription fees, and more. Get started for free.</p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                
                <p class="mb-6 text-sm text-gray-500 text-center font-roboto-bold leading-relaxed">Trusted by over 200+ restaurants</p>
                <div class="flex flex-wrap justify-center space-x-4 xl:space-x-14">
                    <div class="w-auto">
                        <img src={require("../../../assets/images/reslogos/lobsterbar.png")}  className="h-11 w-full grayscale hover:grayscale-0" alt="" />
                    </div>
                    <div class="w-auto">
                        <img src={require("../../../assets/images/reslogos/rasta.png")}  className="h-11 w-full grayscale hover:grayscale-0" alt="" />
                    </div>
                    <div class="w-auto">
                        <img src={require("../../../assets/images/reslogos/colaba.jpg")}  className="h-11 w-full grayscale hover:grayscale-0" alt="" />
                    </div>
                    <div class="w-auto">
                        <img src={require("../../../assets/images/reslogos/freshiii.png")}  className="h-11 w-full grayscale hover:grayscale-0" alt="" />
                    </div>
                    <div class="w-auto">
                        <img src={require("../../../assets/images/reslogos/motorino.png")}  className="h-12 w-full grayscale hover:grayscale-0" alt="" />
                    </div>
                    <div class="w-auto">
                        <img src={require("../../../assets/images/reslogos/bbtease.png")}  className="h-9 w-full grayscale hover:grayscale-0" alt="" />
                    </div>
                    <div class="w-auto">
                        <img src={require("../../../assets/images/reslogos/jsan.webp")}  className="h-12 w-full grayscale hover:grayscale-0" alt="" />
                    </div>
                </div>
                <div className="flex justify-center">
                    <div className="inline-flex items-center px-6 mt-14 shadow-xl h-10 rounded-full mx-auto">
                        <CheckCircleIcon className="h-5 text-blue-600 mr-1" />
                        <h6 className="text-sm font-productsans-medium text-gray-500">No long-term contracts. Use Andie when you need to and for as long as you want to.</h6>
                    </div>
                </div>
            </div>
        </section>
    )
}