export default function AndieBenefits() {
  return (
    <div class="bg-white py-10 md:py-20 flex flex-wrap justify-center">
      <div class="container px-4 mx-auto max-w-7xl">
        <h3
          class="mb-10 text-center andie-sectionheader"
        >
          How the Andie app benefits you
        </h3>
        <div class="flex flex-wrap items-center justify-center gap-12 xl:gap-12 mb-12">
          <div class="max-w-sm h-auto md:h-[560px] bg-white border border-slate-600 rounded-lg shadow-md hover:shadow-lg shadow-slate-500 transform hover:scale-105 transition ease-in-out duration-1000">
            <img
              className="pt-5 px-5 rounded object-cover h-52 w-full"
              src={require("../../../../assets/images/Do what res cant do.jpg")}
              alt=""
            />

            <div class="p-6 md:p-8">
              <h5 class="mb-2 text-2xl md:text-3xl font-pj font-bold tracking-tight text-black">
                Do what reservation platforms can't do
              </h5>
              <p class="mb-3 font-normal text-lg font-pj pt-2 text-black">
                Attracts more guests, expand your average cheque size, and
                increase your profit margins - all in real-time.
              </p>
            </div>
          </div>
          <div class="max-w-sm h-auto md:h-[560px] bg-white border border-slate-600 rounded-lg shadow-md hover:shadow-lg shadow-slate-500 transform hover:scale-105 transition ease-in-out duration-1000">
            <img
              className="pt-5 px-5 object-cover rounded-t-lg h-52 w-full"
              src={require("../../../../assets/images/fill available seats in real time.jpg")}
              alt=""
            />
            <div class="p-6 md:p-8">
              <h5 class="mb-2 text-2xl md:text-3xl font-pj font-bold tracking-tight text-black">
                Fill available seats in real-time
              </h5>
              <p class="mb-3 font-normal text-lg font-pj pt-2 text-black">
                The most expensive item at a restaurant is an empty seat. From
                last minute cancellations to lull periods, Andie takes the
                stress out of filling your empty seats in the moment.
              </p>
            </div>
          </div>
          <div class="max-w-sm h-auto md:h-[560px] bg-white border border-slate-600 rounded-lg shadow-md hover:shadow-lg shadow-slate-500 transform hover:scale-105 transition ease-in-out duration-1000">
            <img
              className="pt-5 px-5 object-cover rounded-t-lg h-52 w-full"
              src={require("../../../../assets/images/pexels-kaique-rocha-109919 (1).jpg")}
              alt=""
            />
            <div class="p-6 md:p-8">
              <h5 class="mb-2 text-2xl md:text-3xl font-pj font-bold tracking-tight text-black">
                Attract nearby guests with ease
              </h5>
              <p class="mb-3 font-normal text-lg font-pj pt-2 text-black">
                Andie helps you entice potential new guests who are nearby with
                live information such as available tables, waitlists, and custom
                trial offers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
