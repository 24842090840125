import React from "react";
import { CGEFeatures, CGEGetStarted, CGEHeader, CGEHowItWorks, CGEPlatformHighlights, CGETestimonials } from "../../components/pages/general/cge/corporate";

export default function CGE() {
  const testimonials = [
    {
        "name": "Nikolina Susac",
        "title": "Events & Operations Manager",
        "company": "DMZ at TMU",
        "headline": "A huge timesaver for our operations and events team",
        "quote": "Andie has been a huge timesaver for our team every week. From sourcing and scheduling, to ordering and payment; Andie has taken care of everything we need for our weekly lunches.",
        "image": require('../../assets/images/corporate-cge/logo/nikolina.jpg')
    },
    {
        "name": "Alyssa Torres",
        "title": "Operations Manager",
        "company": "Autonomic",
        "headline": "Each time we use Andie, we order from multiple places at once with any issues.",
        "quote": "From coordinating with multiple restaurants at once and worrying about different delivery times and people, Andie takes care of everything. They are quick to respond and make sure that they accommodate all of our requests.",
        "image": require('../../assets/images/corporate-cge/logo/alyssa.png')
    },
    {
        "name": "Jaydeh Tarpeh",
        "title": "Co-founder & CEO - Avian",
        "company": "Avian",
        "headline": "This has helped me bring my team together more often than before",
        "quote": "Building a strong team culture is hard to achieve in a hybrid workplace. Andie's Private Dining experiences has given Avian the opportunity to treat our employees to customized meals at some of the best restaurants in the city",
        "image": require('../../assets/images/corporate-cge/logo/jaydeh.jpg')
    }
  ]
  return (
    <div className="andie-padding-horizontal">
      <CGEHeader />
      <CGEPlatformHighlights />
      <CGEHowItWorks />
      <CGETestimonials testimonials={testimonials} />
      <CGEFeatures />
      <CGEGetStarted />
    </div>
  );
}
