import React from "react";
import { RequestDemoButton, TalkToSalesButton } from "../../common";

export default function HomeHeader() {
    return (
        <section class="relative pt-10 overflow-hidden">
            <img class="absolute top-0 right-0 mt-64 sm:mt-80 lg:mt-44 w-2/5 animate-moveHand rounded-lg" src={require("../../../assets/images/home/empty-restauarant-table.png")} alt="" />
            <div class="relative container px-4 mx-auto">
                <div class="max-w-xl xl:max-w-2xl">
                    <h1 class="andie-page-title leading-0 text-gray-900 mb-6 sm:mb-8">
                        The most expensive item at a restaurant is
                        <span class="text-blue-600"> an empty table</span>
                    </h1>
                    <div class="md:flex mb-8 max-w-xs sm:max-w-sm md:max-w-none">
                        <div class="mb-6 md:mb-0 md:mr-8 pt-3">
                        <svg width="84" height="10" viewbox="0 0 84 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 4.25C0.585786 4.25 0.25 4.58579 0.25 5C0.25 5.41421 0.585786 5.75 1 5.75L1 4.25ZM84 5.00001L76.5 0.669879L76.5 9.33013L84 5.00001ZM1 5.75L77.25 5.75001L77.25 4.25001L1 4.25L1 5.75Z" fill="#1E254C"></path>
                        </svg>
                        </div>
                        <div class="max-w-md">
                        <p class="andie-page-description text-gray-600">Forward-thinking restaurants chose to drive as much daily traffic and revenue to their locations.</p>
                        </div>
                    </div>
                    <div className="flex space-x-4">
                        <RequestDemoButton />
                        <TalkToSalesButton />
                    </div>
                    <a href="https://open.spotify.com/episode/6sXGquCGboVxrKMV2bzgzd?si=776de3a1ffde400c" target="_blank" rel="noreferrer" className="mt-10 flex items-center space-x-4">
                        <img class="h-12 w-12 rounded-full" src={require("../../../assets/images/fred_vanvleet.jpg")} alt="" />
                        <div className="inline-block bg-blue-100 h-9 px-5 pt-2 rounded-full">
                            <h6 className="text-sm font-productsans-medium text-blue-600">Listen to our conversation with NBA Champion, Fred VanVleet.</h6>
                        </div>
                    </a>
                </div>
                
            </div>
        </section>
    )
}    