import React from "react";
import { Link } from "react-router-dom";

export default function PricingAndiePromise() {
    return (
        <section class="relative pt-24 md:pb-28 lg:pt-0 lg:pb-0 bg-blue-600 rounded-3xl p-6 xl:px-10 xl:py-20 overflow-hidden">
            <div class="relative z-10 container px-4 mx-auto">
                <div class="flex flex-wrap items-center -m-8">
                    <div class="w-full md:w-1/2 p-8">
                        <div class="md:max-w-lg">
                        <h2 class="mb-6 text-white andie-section-title max-w-sm">Backed by the Andie promise.</h2>
                        <p class="mb-8 text-white text-opacity-70 andie-section-description md:max-w-md">At Andie, we believe in only making money when our partners, customers, and users are happy and successful.</p>
                        <div class="flex flex-wrap -m-2">
                            <div class="w-full md:w-auto p-2">
                            <Link to="/the-andie-promise" class="h-10 px-6 w-full flex items-center font-medium rounded-sm text-sm font-productsans-medium shadow-6xl focus:ring focus:ring-gray-300 bg-white hover:bg-gray-100 transition ease-in-out duration-200">Learn about our guarantee</Link>
                            </div>
                            <div class="w-full md:w-auto p-2">
                            <Link to="/request-a-demo" class="inline-flex items-center font-productsans-medium text-sm justify-center h-10 px-6 w-full text-white font-medium border-2 border-white border-opacity-20 hover:border-opacity-40 rounded-sm focus:ring focus:ring-white focus:ring-opacity-40 bg-transparent hover:bg-black hover:bg-opacity-5 transition ease-in-out duration-200">
                                <svg class="mr-2.5" width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.75 3.75C2.75 2.92157 3.42157 2.25 4.25 2.25H6.70943C7.03225 2.25 7.31886 2.45657 7.42094 2.76283L8.5443 6.13291C8.66233 6.48699 8.50203 6.87398 8.1682 7.0409L6.47525 7.88737C7.30194 9.72091 8.77909 11.1981 10.6126 12.0247L11.4591 10.3318C11.626 9.99796 12.013 9.83767 12.3671 9.9557L15.7372 11.0791C16.0434 11.1811 16.25 11.4677 16.25 11.7906V14.25C16.25 15.0784 15.5784 15.75 14.75 15.75H14C7.7868 15.75 2.75 10.7132 2.75 4.5V3.75Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                                <span>Book a demo</span>
                            </Link>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="w-full md:w-1/2 grid grid-cols-2 gap-5 p-8">
                        <img class="h-full object-cover rounded-full xl:-mt-8" src={require("../../../assets/images/the-andie-promise-pricing.png")} alt="" />
                        <img class="h-full object-cover rounded-full xl:mt-10 xl:mb-8" src={require("../../../assets/images/the-andie-promise-pricing-2.png")} alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}