import React from "react";

export default function AndieTestimonials() {
  return (
    <div class="bg-white pb-16 xl:pb-20 flex flex-wrap justify-center">
      <div class="container mx-auto max-w-7xl">
        <section class="relative bg-white">
          <div class="relative z-10 container px-4 mx-auto">
            <h2 class="mb-10 andie-sectionheader text-center">
              What our partners have to say
            </h2>
            <div class="flex flex-wrap justify-center -m-3">
              <div class="w-full md:w-1/2 p-3 transform hover:scale-105 transition ease-in-out duration-1000">
                <div class="p-6 h-full bg-white bg-opacity-60 border border-black rounded-3xl">
                  <div class="flex flex-col justify-between h-full">
                    <div class="mb-5 block">
                      <div class="flex flex-wrap mb-4 -m-2">
                        <div class="w-[50px] h-[50px] mb-4 mx-2 pt-2">
                          <img
                            src={require("../../../../assets/images/fred_vanvleet.jpg")}
                            alt=""
                            class="rounded-full border-solid mb-6 mx-auto"
                          />
                        </div>
                        <div class="w-auto p-2">
                          <h3 class="font-semibold leading-normal font-pj">
                            Fred Vanvleet
                          </h3>
                          <p class="text-gray-500 font-pj">
                            NBA Champion - Toronto Raptors
                          </p>
                        </div>
                      </div>
                      <p class="text-lg font-medium font-pj">
                        I love this app. I can see how it makes life easier for
                        everyone - even me.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full md:w-1/2 p-3 transform hover:scale-105 transition ease-in-out duration-1000">
                <div class="p-6 h-full bg-white bg-opacity-60 border border-black rounded-3xl">
                  <div class="flex flex-col justify-between h-full">
                    <div class="mb-5 block">
                      <div class="flex flex-wrap mb-4 -m-2">
                        <div class="w-[50px] h-[50px] mb-4 mx-2 pt-2">
                          <img
                            src={require("../../../../assets/images/Cyrus Cooper Testimonial.jpg")}
                            alt=""
                            class="rounded-full border-solid mb-6 mx-auto"
                          />
                        </div>
                        <div class="w-auto p-2">
                          <h3 class="font-semibold leading-normal font-pj">
                            Cyrus Cooper
                          </h3>
                          <p class="text-gray-500 font-pj">
                            Cobalt Hospitality
                          </p>
                        </div>
                      </div>
                      <p class="text-lg font-medium font-pj">
                        The pandemic has taught us that we need new ways to
                        drive traffic, and Andie does that for us perfectly.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
