import React, { useState } from "react";
import { CheckCircleIcon, ChevronLeftIcon, ChevronRightIcon, StarIcon } from "@heroicons/react/solid";

export default function CGETestimonials({ testimonials }) {
    const [testimonial, setTestimonial] = useState(testimonials[0]);
    const [index, setIndex] = useState(0);

    const images = [
        { url: require('../../../../../assets/images/corporate-cge/logo/TMU.png') },
        { url: require('../../../../../assets/images/corporate-cge/logo/DMZ.png') },
        { url: require('../../../../../assets/images/corporate-cge/logo/CIBC.jpg') },
        { url: require('../../../../../assets/images/corporate-cge/logo/Lightster.png') },
        { url: require('../../../../../assets/images/corporate-cge/logo/Shiftride.png') },
        { url: require('../../../../../assets/images/corporate-cge/logo/worksimply.png') },
        { url: require('../../../../../assets/images/corporate-cge/logo/autonomic.avif') },
        { url: require('../../../../../assets/images/corporate-cge/logo/workhaus.png') },
        { url: require('../../../../../assets/images/corporate-cge/logo/softdrive.png') },
        { url: require('../../../../../assets/images/corporate-cge/logo/bluedoor.png') },
        { url: require('../../../../../assets/images/corporate-cge/logo/alli.png') },
        { url: require('../../../../../assets/images/corporate-cge/logo/dyne.png') },
        { url: require('../../../../../assets/images/corporate-cge/logo/smartterm.png') },
        { url: require('../../../../../assets/images/corporate-cge/logo/avian.jfif') },
        { url: require('../../../../../assets/images/corporate-cge/logo/ford.png') },
    ]

    function buttonLeft() {
        if (index > 0) {
            setTestimonial(testimonials[index - 1]);
            setIndex(index - 1);
        } else {
            setTestimonial(testimonials[testimonials.count() - 1]);
        }
    }

    return (
        <div>
            <section class="pb-16 bg-white overflow-hidden">
                <div class="container px-4 mx-auto">
                    <h2 class="mb-5 text-center andie-section-title">Trusted by companies like yours</h2>
                    <p class="mb-12 text-gray-600 text-center andie-section-description md:max-w-xl mx-auto">We are proud to be trusted by forward-thinking organizations across various sectors to manage their corporate guest experiences.</p>
                    <div class="pt-10 max-w-4xl mb-7 mx-auto">
                        <div class="grid grid-col-4 xl:grid-cols-6 gap-5 xl:gap-y-7 -m-6 lg:-m-14">
                            {images.map((image, index) => (
                                <img class="grayscale mx-auto h-10 object-contain" src={image.url} alt="" />
                            ))}
                        </div>
                    </div>
                    <div className="mt-14 flex justify-center">
                        <div className="inline-flex items-center px-6 mt-14 shadow-xl h-10 rounded-full mx-auto">
                            <CheckCircleIcon className="h-5 text-blue-600 mr-1" />
                            <h6 className="text-sm font-productsans-medium text-gray-500">Trusted for over 20,000+ guest experiences.</h6>
                        </div>
                    </div>
                </div>
            </section>

            <section class="relative pb-20 overflow-hidden">
                <div class="relative container px-4 mx-auto">
                    <div class="flex flex-wrap items-center -mx-4">
                        <div class="w-full lg:w-1/6 px-4 text-right">
                            <button onClick={() => buttonLeft(index)} class="hidden lg:inline-flex items-center justify-center w-9 h-9 bg-blue-500 hover:bg-blue-600 rounded-full">
                                <ChevronLeftIcon className="text-white h-6" />
                            </button>
                            <div class="lg:hidden text-center mb-10">
                                <button class="inline-flex items-center justify-center w-8 h-8 mx-1 bg-blue-500 hover:bg-blue-600 rounded-full">
                                    <ChevronLeftIcon className="text-white h-5" />
                                </button>
                                <button class="inline-flex items-center justify-center w-8 h-8 mx-1 bg-blue-500 hover:bg-blue-600 rounded-full">
                                    <ChevronRightIcon className="text-white h-5" />
                                </button>
                            </div>
                        </div>
                        <div class="w-full lg:w-4/6 px-4">
                            <div class="relative text-center mb-8">
                                <div class="relative">
                                    <span class="text-sm uppercase text-blue-500 font-roboto-bold">Hear what they have to say</span>
                                </div>
                            
                                <div class="max-w-xl mx-auto">
                                    <h2 class="my-16 text-4xl font-productsans-bold">{testimonial.headline}</h2>
                                    <p class="mb-8 text-lg andie-paragraph text-gray-500">{testimonial.quote}</p>
                                </div>
                            </div>
                            <div className="flex justify-between items-center">
                                <div className="w-1/4 border-b-2 border-blue-400" />
                                <div class="flex items-center justify-center">
                                    <div class="px-4">
                                        <img class="w-32 h-32 border-8 border-yellow-50 rounded-full object-cover" src={testimonial.image} alt="" />
                                    </div>
                                    <div class="px-4">
                                        <div class="flex mb-4">
                                            <StarIcon className="h-6 text-yellow-500" />
                                            <StarIcon className="h-6 text-yellow-500" />
                                            <StarIcon className="h-6 text-yellow-500" />
                                            <StarIcon className="h-6 text-yellow-500" />
                                            <StarIcon className="h-6 text-yellow-500" />
                                        </div>
                                        <div>
                                            <h3 class="mb-1 font-productsans-medium text-lg">{testimonial.name}</h3>
                                            <p class="text-blue-600 text-sm font-roboto-medium">{testimonial.title}</p>
                                            <p class="text-gray-600 text-xs font-roboto-medium">{testimonial.company}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-1/4 border-b-2 border-blue-400" />
                            </div>
                        </div>
                    
                        <div class="w-full lg:w-1/6 px-4">
                            <button class="hidden lg:inline-flex items-center justify-center w-9 h-9 bg-blue-500 hover:bg-blue-600 rounded-full">
                                <ChevronRightIcon className="text-white h-6" />
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}