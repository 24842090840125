import { CheckCircleIcon } from "@heroicons/react/outline";
import { CurrencyDollarIcon, ReceiptTaxIcon } from "@heroicons/react/solid";


export default function PricingRestaurant() {
    return (
        <section class="pt-12 pb-28 bg-white overflow-hidden">
            <div class="container mx-auto">
                <div class="flex flex-wrap -m-6">
                    <div class="w-full md:w-1/2 p-6">
                        <div class="max-w-md">
                            <h2 class="mb-5 andie-section-title">Pricing that works for your restaurant.</h2>
                            <p class="mb-11 andie">Andie is priced to help your business grow and thrive.</p>
                            <ul>
                                <li class="flex items-center mb-5 text-base">
                                    <CheckCircleIcon className="h-5 mr-1.5 text-gray-600" />
                                    <p className="andie-paragraph">Free mobile and web apps</p>
                                </li>
                                <li class="flex items-center mb-5 text-base">
                                    <CheckCircleIcon className="h-5 mr-1.5 text-gray-600" />
                                    <p className="andie-paragraph">End-to-end platform</p>
                                </li>
                                <li class="flex items-center mb-5 text-base">
                                    <CheckCircleIcon className="h-5 mr-1.5 text-gray-600" />
                                    <p className="andie-paragraph">Responsive customer support</p>
                                </li>
                            </ul>
                            <div className="mt-10 w-40">
                                
                            </div>
                        </div>
                    </div>
                    <div class="w-full md:w-1/2 p-6">
                        <div class="md:max-w-lg ml-auto">
                        
                            <div class="mb-5 p-px bg-gradient-cyan rounded-md">
                                <div class="p-4 bg-gray-900 rounded-md">
                                    <div class="flex flex-wrap justify-between -m-3">
                                        <div class="w-auto p-3">
                                            <div class="flex flex-wrap -m-3">
                                                <div class="w-auto p-3">
                                                    <CurrencyDollarIcon className="h-7 text-white" />
                                                </div>
                                                <div class="w-auto p-3">
                                                    <h3 class="mb-1 andie-section-subheader text-white">Pay-as-you go</h3>
                                                    <p class="text-gray-400 andie-paragraph">Starting as low as $1.00/guest</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-auto p-3">
                                            <p class="font-heading px-3 py-1.5 font-roboto-regular text-[10px] text-gray-300 bg-gray-800 tracking-px uppercase rounded-full">Best value</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="mb-5 p-4 bg-black border border-gray-800 rounded-md">
                                <div class="flex flex-wrap justify-between -m-3">
                                    <div class="w-auto p-3">
                                    <div class="flex -m-3">
                                        <div class="w-auto p-3">
                                            <ReceiptTaxIcon className="h-7 text-white" />
                                        </div>
                                        <div class="w-auto max-w-sm p-3">
                                            <h3 class="mb-1 andie-section-subheader text-white">Transaction fees</h3>
                                            <p class="text-gray-400 andie-paragraph mr-4">All transactions on the Andie platform are subject to an applicable transaction fee.</p>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                       
                        </div>
                    </div>
                </div>
                <div class="mt-8 xl:mt-12 grid gap-4 xl:gap-7 grid-cols-2 xl:grid-cols-4">
                    <div class="mt-4">
                        <div class="mw-md-xs">
                            <div className="w-12 border-2 mb-7 border-gray-500" />
                            <h2 class="andie-section-subheader mb-3">Flexible pricing</h2>
                            <p class="andie-paragraph text-muted mb-0 xl:mr-10">Pay only when Andie sends you a guest. Our flexible pricing guarantees value at all times.</p>
                        </div>
                    </div>
                    <div class="mt-4">
                        <div class="mw-md-xs">
                            <div className="w-12 border-2 mb-7 border-gray-500" />
                            <h2 class="andie-section-subheader mb-3">No hidden fees</h2>
                            <p class="andie-paragraph text-muted mb-0 xl:mr-10">Skip the startup fees, subscription fees, and more. Get started for free.</p>
                        </div>
                    </div>
                    <div class="mt-4">
                        <div class="mw-md-xs">
                            <div className="w-12 border-2 mb-7 border-gray-500" />
                            <h2 class="andie-section-subheader mb-3">No long-term contracts</h2>
                            <p class="andie-paragraph text-muted mb-0 xl:mr-10">Use Andie when you need to, and for as long as you want to.</p>
                        </div>
                    </div>
                    <div class="mt-4">
                        <div class="mw-md-xs">
                            <div className="w-12 border-2 mb-7 border-gray-500" />
                            <h2 class="andie-section-subheader mb-3">Quick onboarding</h2>
                            <p class="andie-paragraph text-muted mb-0">Andie's setup process takes as little as 2 business days, ensuring a quick and efficient onboarding.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
