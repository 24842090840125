import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function FAQBlog({ blogData }) {
    const [featured, setFeatured] = useState(blogData[0]);

    return (
        <section class="relative py-20 overflow-hidden">
            <div class="relative container px-4 mx-auto">
                <div class="max-w-xl lg:max-w-7xl mx-auto">
                <div class="max-w-2xl mx-auto mb-15 text-center">
                    <span class="inline-block py-1 px-5 mb-4 text-xs uppercase font-roboto-medium text-orange-900 bg-orange-50 rounded-full">Additional Knowledge and Insights</span>
                    <h1 class="andie-section-title">
                        The Andie Blog
                    </h1>
                </div>
                <div class="mt-8 xl:mt-14 flex flex-wrap -mx-4 mb-18">
                    <div class="w-full lg:w-1/2 xl:w-1/3 px-4 xl:px-6 mb-12 lg:mb-0">
                        <a class="block group w-full" href="#">
                            <img class="block w-full mb-5 xl:h-60 object-cover rounded-xl" src={featured?.coverImage?.url} alt="" />
                            <span class="block font-roboto-medium text-[13px] text-gray-500 mb-2">{featured.category}</span>
                            <h4 class="text-xl leading-tight font-productsans-black text-gray-900 group-hover:text-blue-600 mb-5">{featured.title}</h4>
                            <p class="max-w-xl andie-paragraph line-clamp-2">{featured.content.html.replace('<p>', "")}.</p>
                        </a>
                    </div>
                    <div class="w-full lg:w-1/2 xl:w-1/3 px-4 xl:px-6">
                        {blogData.slice(1,4).map((blog, index) => (
                            <a class="md:flex group mb-8" href="#">
                                <img class="w-36 h-28 rounded-xl object-cover" src={blog?.coverImage?.url} alt="" />
                                <div class="mt-4 md:mt-0 md:ml-6 pt-2">
                                    <span class="block font-roboto-medium text-[13px] text-gray-500 mb-2">{blog.category}</span>
                                    <h4 class="text-base leading-tight font-productsans-black text-gray-900 group-hover:text-blue-600">{blog.title}</h4>
                                </div>
                            </a>
                        ))}
                    </div>
                    <div class="hidden xl:inline-block xl:w-1/3 xl:px-6">
                        {blogData.slice(5,8).map((blog, index) => (
                            <a class="md:flex group mb-8" href="#">
                                <img class="w-36 h-28 rounded-xl" src={blog?.coverImage?.url} alt="" />
                                <div class="mt-4 md:mt-0 md:ml-6 pt-2">
                                    <span class="block font-roboto-medium text-[13px] text-gray-500 mb-2">{blog.category}</span>
                                    <h4 class="text-base leading-tight font-productsans-black text-gray-900 group-hover:text-blue-600 line-clamp-3">{blog.title}</h4>
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
                <div class="text-center mt-16">
                    <Link class="relative group inline-flex h-10 px-7 justify-center items-center text-blue-600 hover:text-orange-50 rounded-full bg-orange-50 transition duration-300 overflow-hidden" to="/blog">
                    <div class="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                    <span class="relative text-sm font-productsans-medium">See More Articles</span>
                    </Link>
                </div>
                </div>
            </div>
        </section>
    )
}