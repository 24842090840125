import React from "react";
import { RequestDemoButton, TalkToSalesButton } from "../../../../common";
import { CheckCircleIcon } from "@heroicons/react/solid";

export default function CGEFeatures() {
    const features = [
        "Flexible payment options",
        "Unlimited bid responses",
        "Integrated delivery coordination",
        "Dedicated support specialist"
    ]
    return (
        <section class="border-t border-gray-100 py-24 bg-white">
            <div class="container px-4 mx-auto mb-10">
                <div class="mx-auto">
                    <div class="flex flex-wrap -mx-4 mb-12">
                        <div class="w-full xl:w-1/2 px-4 mb-16 flex xl:mb-0">
                            <div className="flex space-x-2 xl:mr-6">
                                <div className="h-6 w-6 bg-blue-300 rounded-full" />
                                <div className="h-6 w-6 bg-blue-300 rounded-full" />
                            </div>
                            <div class="sm:pl-20 xl:pl-0 max-w-xl">
                                <h2 class="andie-section-title mb-8">Everything you need to host a great corporate experience.</h2>
                                <p class="max-w-md andie-section-description mb-5 text-gray-500">From flexible payment options to a dedicated support specialist, Andie CGE provides everything for you to execute your booking - no matter the size.</p>
                                <p class="max-w-md andie-paragraph mb-5 text-gray-500">Book from one restaurant for your catering or dining experiences, or combine multiple restaurants into one booking with Catering Plus.</p>
                                <div className="mt-8 flex space-x-4">
                                    <RequestDemoButton />
                                    <TalkToSalesButton />
                                </div>
                            </div>
                        </div>
                        <div class="w-full xl:w-1/2 px-4">
                            <div class="pl-20 relative">
                                <img class="absolute top-0 left-0 transform img-fluid w-12 md:w-auto" src="shopal-assets/background/circle-orange-green-part.svg" alt="" />
                                <img class="img-fluid w-full h-67 border-8 border-gray-50 rounded-md shadow" src={require("../../../../../assets/images/corporate-cge/andie-cge-screenshot.png")} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="py-7 bg-blue-50 rounded-full">
                <div class="container px-4 mx-auto">
                    <div class="px-6 xl:max-w-7xl mx-auto">
                        <div className="grid grid-cols-3 gap-5 xl:grid-cols-4">
                            {features.map((feature, index) => (
                                <div class="w-full">
                                    <div class="flex items-center space-x-2">
                                        <CheckCircleIcon className="h-7 text-blue-600" />
                                        <h4 class="text-base font-roboto-bold text-gray-700">{feature}</h4>
                                    </div>
                                </div>
                            ))}  
                        </div> 
                    </div>
                </div>
            </div>
        </section>
    )
}