import React from "react";
import { PricingAndiePromise, PricingGetStarted, PricingHeader, PricingHighlights, PricingTabs } from "../../components/pages/pricing";

export default function Pricing() {
    return (
        <div className="andie-padding-horizontal mx-4">
            <PricingHeader />
            <PricingAndiePromise />
            <PricingTabs />
            <PricingHighlights />
            <PricingGetStarted />
        </div>
    )
}