import { Link } from "react-router-dom";

export default function RequestDemoButton() {
    return (
        <div>
           {/* <Link to="https://cge.alwaysandie.com/signup" className="andie-dark-button" role="button">
                Request demo
            </Link>  */}
            <a className="andie-dark-button" role="button" rel='noopener noreferrer' href="https://cge.alwaysandie.com/signup">Request demo</a>
        </div>
    )
}
