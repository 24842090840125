import { Link } from "react-router-dom"

export default function HomeBlog({ blogData }) {
    return (
        <div>
            <section class="border-t-2 border-b-2 border-dashed border-gray-100 py-12 bg-white sm:py-16 lg:py-20">
                <div class="px-4 mx-auto">
                    <div class="max-w-md mx-auto text-center">
                        <h2 class="andie-section-title">
                            Read our insights
                        </h2>
                        <p class="mt-5 font-normal text-gray-600 andie-paragraph mb-16">
                        Stay up-to-date with the latest news and trends in corporate catering, event planning, and culinary innovations with our blog
                        </p>
                    </div>
                    {blogData.slice(0, 1).map((item, index) => (
                        <div class="bg-black bg-no-repeat bg-center bg-cover rounded-2xl h-[450px] overflow-hidden" style={{ backgroundImage: `url(${item?.coverImage?.url})` }}>
                            <div class="container px-4 mx-auto">
                                <div class="px-12 xl:ml-10 pt-12 pb-9 mt-5 md:mt-10 md:max-w-lg bg-black bg-opacity-80 rounded-2xl">
                                    <p class="mb-7 max-w-max px-3 py-1.5 text-xs text-white font-roboto-bold uppercase border border-gray-700 rounded-md">Featured</p>
                                    <h2 class="mb-4 text-4xl text-white andie-section-title line-clamp-3">{item.title}</h2>
                                    <p class="mb-11 text-lg text-gray-400 font-medium leading-normal">{item.summary}</p>
                                    <Link to={"/blog/post/?title=" + item.title} class="inline-flex items-center mt-4 font-bold text-blue-600 rounded hover:text-gray-600 font-productsans-regular group focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2">
                                        Continue Reading
                                        <svg class="w-5 h-5 ml-2 transition-all duration-200 group-hover:translate-x-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))
                    }

                    <div class="grid grid-cols-1 mx-auto mt-8 text-center sm:mt-16 sm:text-left sm:grid-cols-2 md:grid-cols-4 gap-y-8 gap-x-8 lg:gap-x-10">
                        {blogData.slice(0, 5).map((item, index) => {
                            if (index === 0) {
                                return <></>
                            } else {
                                return (
                                    <div class="relative group">
                                        <div class="overflow rounded-lg aspect-[16/9]">
                                            {item?.coverImage == null ?
                                                <div className="bg-gray-100 h-20 w-full rounded-xl" />
                                                :
                                                <img class="object-cover w-full h-48 rounded-2xl" src={item?.coverImage?.url} alt="" />
                                            }
                                        </div>
                                        <p class="mt-6 text-xs font-semibold text-gray-600 font-pj">
                                            {item.date}
                                        </p>
                                        <p class="mt-2 font-productsans-medium line-clamp-2 leading-tight">
                                            {item.title}
                                        </p>
                                        <Link to={"/blog/post/?title=" + item.title}>
                                            <span class="absolute inset-0" aria-hidden="true"></span>
                                        </Link>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>
            </section>
        </div>
    )
}
