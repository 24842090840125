import React from "react";
import { Link } from "react-router-dom";

export default function FAQAndiePromise() {
    const reasons = [
        {
            "reason": "We always, and we will, answer any time and every time you call.",
            "explanation": "The Andie is always available to answer questions, troubleshoot issues, and help our guests, partner restaruants, and organizations."
        },
        {
            "reason": "We provide easy-to-use products and clear steps.",
            "explanation": "We don't believe in making our applications, software, or transactions complicated. That is why everything is clear."
        },
        {
            "reason": "We believe your time is valuable.",
            "explanation": "From guests to restaurants and organizations, we beleive your time valuable. That is why we take care of mundane steps in your dining process."
        },
        {
            "reason": "We believe in letting our experiences do the talking.",
            "explanation": "We believe the best way to grow Andie is to deliver a great experience that you would want to talk about to your friends, family, and colleagues."
        }
    ]
    return (
        <section class="py-24 border-t border-gray-100 overflow-hidden">
            <div class="container mx-auto px-4">
                <div class="relative z-10 mx-auto">
                    <div className="flex justify-between">
                        <div className="md:max-w-4xl">
                            <div class="max-w-3xl">
                                <h2 class="mb-6 andie-section-title">At Andie, we believe in only making money when our customers are <span className="text-blue-600">happy</span> and <span className="text-green-600">successful</span>.</h2>
                                <p class="mb-8 andie-section-description text-gray-500 md:max-w-xl">Thousands of guests and hundreds of restaurants – from small pizzerias to fine dining establishments – rely on Andie to create and enjoy great dining experiences.</p>
                                <p className="andie-section-description text-blue-600">The name Andie comes from our believe that our platfrom should always be handy (Or Andie) when you need us.</p>
                            </div>
                            <div class="md:max-w-3xl mb-10">
                                {/* <span class="text-sm text-blue-600 uppercase mb-5 font-roboto-bold">Our promise to you - our customer</span> */}
                                {/* <h2 class="mt-5 text-gray-900 andie-section-title">Why individuals, restaurants, and organizations choose Andie</h2> */}
                            </div>
                            <div class="w-full space-y-7">
                                {reasons.map((item, index) => (
                                    <div class="px-9 py-6 bg-blue-100 rounded-full">
                                        <div class="flex flex-wrap -m-2">
                                            <div class="w-full md:w-2/5 p-2">
                                                <h3 class="text-lg text-gray-900 font-productsans-medium leading-none">{item.reason}</h3>
                                            </div>
                                            <div class="w-full md:w-3/5 p-2">
                                                <p class="xl:ml-3 andie-paragraph">{item.explanation}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="xl:flex xl:flex-col xl:items-end xl:justify-between">
                            <div className="space-y-5">
                                <div className="bg-blue-50 h-4 w-4 rounded-full" />
                                <div className="bg-blue-50 h-10 w-10 rounded-full" />
                                <div className="bg-blue-50 h-4 w-4 rounded-full" />
                            </div>
                            <div className="flex flex-col items-end">
                                <span class="text-sm text-gray-600 uppercase mb-3 font-roboto-bold">See our promises in action</span>
                                <Link to="/andie-success-stories" className="text-sm font-productsans-medium text-blue-600 pb-1.5 inline-flex border-b-2 border-blue-600">
                                    Hear success stories from our customers
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}