import LoginForm from '../../components/pages/login/LoginForm'


export default function Login() {
    return (
        <div className="flex h-screen relative">
            <img
                className="absolute h-full w-full object-cover scale-x-[-1] hidden md:flex"
                src={require('../../assets/images/corporate-cge/cge-how-it-works.png')}
                alt="Background"
            />
            {/* Content */}
            <div className='z-10 flex items-center md:justify-end justify-center w-full'>
                <LoginForm />
            </div>
        </div>
    )
}