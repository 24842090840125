import { useState } from 'react';
import formAPI from "../../api/FormAPI";

export default function RequestDemo() {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [position, setPosition] = useState("");
    const [number, setNumber] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [website, setWebsite] = useState("");
    const [category, setCategory] = useState("");

    const subscribe = (e) => {
        const data = {
            email: email,
            name: name,
            position: position,
            category: category,
            phone_number: number,
            company_name: companyName,
            website: website,
            form: 'request private demo'
        }
        formAPI.subscribe(e, data, "Requested a demo!")
    }

    return (
        <div >
            <section class="py-12 bg-gray-50 sm:py-16 lg:py-20">
                <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div class="text-center mx-auto max-w-7xl mb-8">
                        <h1 class="text-3xl font-bold text-gray-900 sm:text-4xl font-pj">
                            Request a private Andie demo
                        </h1>
                        <p class="mt-4 andie-page-text text-gray-600 font-pj">
                            Share a few details and we'll get in touch.
                        </p>
                    </div>
                    
                    <div class="relative max-w-md mx-auto lg:max-w-lg">
                        {/* <VerticalCustomDivider /> */}
                        <div class="absolute -inset-2">
                            <div class="w-full h-full mx-auto rounded-3xl opacity-30 blur-lg filter" style={{ background: 'linear-gradient(90deg, #44FF9A -0.55%, #44B0FF 22.86%, #8B44FF 48.36%, #FF6644 73.33%, #EBFF70 99.34%);' }}></div>
                        </div>

                        <form action="#" method="POST" class="relative bg-white shadow-xl rounded-2xl" onSubmit={(e) => subscribe(e)}>
                            <div class="p-8 sm:px-10">
                            <div class="space-y-5">
                                <div className="md:flex md:space-x-4">
                                    <div className="md:w-[65%]">
                                        <label for="" class="font-bold text-sm text-gray-900 font-pj">
                                            Your name
                                        </label>
                                        <div class="mt-2.5">
                                            <input type="text" name="" id="" onChange={(e) => setName(e.target.value)}  placeholder="Enter your full name" class="block w-full px-4 py-2.5 text-sm font-normal text-gray-900 placeholder-gray-600 bg-white border border-gray-300 rounded focus:border-gray-900 focus:ring-1 focus:ring-gray-900 font-pj focus:outline-none" required />
                                        </div>
                                    </div>
                                    <div className="md:w-[35%] mt-2.5 md:mt-0">
                                        <label for="" class="font-bold text-sm text-gray-900 font-pj">
                                            Position
                                        </label>
                                        <div class="mt-2.5">
                                            <input type="text" name="" id="" placeholder="e.g. CEO" onChange={(e) => setPosition(e.target.value)} class="block w-full px-4 py-2.5 text-sm font-normal text-gray-900 placeholder-gray-600 bg-white border border-gray-300 rounded focus:border-gray-900 focus:ring-1 focus:ring-gray-900 font-pj focus:outline-none" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="md:flex md:space-x-4">
                                    <div className="md:w-3/5 mt-2.5 md:mt-0">
                                        <label for="" class="font-bold text-sm text-gray-900 font-pj">
                                            Business email
                                        </label>
                                        <div class="mt-2.5">
                                            <input type="text" name="" id="" onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email address" class="block w-full px-4 py-2.5 text-sm font-normal text-gray-900 placeholder-gray-600 bg-white border border-gray-300 rounded focus:border-gray-900 focus:ring-1 focus:ring-gray-900 font-pj focus:outline-none" required />
                                        </div>
                                    </div>
                                    <div className="md:w-2/5 mt-2.5 md:mt-0">
                                        <label for="" class="font-bold text-sm text-gray-900 font-pj">
                                            Phone Number
                                        </label>
                                        <div class="mt-2.5">
                                            <input type="text" name="" id="" onChange={(e) => setNumber(e.target.value)} placeholder="###-###-####" class="block w-full px-4 py-2.5 text-sm font-normal text-gray-900 placeholder-gray-600 bg-white border border-gray-300 rounded focus:border-gray-900 focus:ring-1 focus:ring-gray-900 font-pj focus:outline-none" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="md:flex md:space-x-4">
                                    <div className="md:w-1/2 mt-2.5 md:mt-0">
                                        <label for="" class="font-bold text-sm text-gray-900 font-pj">
                                            Company Name
                                        </label>
                                        <div class="mt-2.5">
                                            <input type="text" name="" id="" onChange={(e) => setCompanyName(e.target.value)} placeholder="e.g. Andie Partner Inc." class="block w-full px-4 py-2.5 text-sm font-normal text-gray-900 placeholder-gray-600 bg-white border border-gray-300 rounded focus:border-gray-900 focus:ring-1 focus:ring-gray-900 font-pj focus:outline-none" required />
                                        </div>
                                    </div>
                                    <div className="md:w-1/2 mt-2.5 md:mt-0">
                                        <label for="" class="font-bold text-sm text-gray-900 font-pj">
                                            Website
                                        </label>
                                        <div class="mt-2.5">
                                            <input type="text" name="" id="" onChange={(e) => setWebsite(e.target.value)} placeholder="Website" class="block w-full px-4 py-2.5 text-sm font-normal text-gray-900 placeholder-gray-600 bg-white border border-gray-300 rounded focus:border-gray-900 focus:ring-1 focus:ring-gray-900 font-pj focus:outline-none" />
                                        </div>
                                    </div>
                                </div>

                                <div>
                                <label for="" class="font-bold text-sm text-gray-900 font-pj">
                                    What does your business do?
                                </label>
                                <div class="mt-2.5">
                                    <select name="" id="" onChange={(e) => setCategory(e.target.value)} class="block w-full py-2.5 pl-4 pr-12 text-sm font-normal text-black placeholder-gray-600 bg-white border border-gray-300 rounded focus:border-black focus:ring-1 focus:ring-black font-pj focus:outline-none" required>
                                        <option value="" disabled selected>
                                            Select
                                        </option>
                                        <option value="Restaurant">
                                            Restaurant
                                        </option>
                                        <option value="Retail">
                                            Retail
                                        </option>
                                        <option value="Grocery">
                                            Grocery
                                        </option>
                                        <option value="Hospitality">
                                            Hospitality
                                        </option>
                                        <option value="Salon">
                                            Salon
                                        </option>
                                        <option value="Barber">
                                            Barber
                                        </option>
                                        <option value="Medical and Health services">
                                            Medical and Health services
                                        </option>
                                        <option value="Gyms and fitness">
                                            Gyms and fitness
                                        </option>
                                        <option value="Spas and relaxation">
                                            Spas and relaxation
                                        </option>
                                        <option value="Services">
                                            Services
                                        </option>
                                    </select>
                                </div>
                                </div>

                                <div>
                                <button type="submit" class="inline-flex andie-dark-button">
                                    Get in touch
                                </button>
                                </div>
                            </div>
                            </div>
                        </form>

                        {/* <VerticalCustomDivider /> */}
                    </div>
                </div>
            </section>
        </div>
    )
}
