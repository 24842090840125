import React from "react";
import { Link } from "react-router-dom";

export default function PricingCorporateFAQ() {
    const faqs = [
        {
            "question": "Can I have multiple open bids?",
            "answer": "You can have multiple open bid requests at the same time. Duplicate bid requests are not permitted for same opportunity.",
            
        }, 
        {
            "question": "Do I need to pay right away?",
            "answer": "A deposit or payment in full is required to lock-in your Andie CGE booking.",
            "important_point": "However, if you regularly book on the platform, we can offer additional flexible payment options."
        },
        {
            "question": "Can I combine multiple bids responses from multiple restaurants?",
            "answer": "Yes. Our Catering Plus option allows you to combine food items from multiple restaurants into one booking easily."
        },
        {
            "question": "What happens if a booking does not go as planned?",
            "answer": "We are always in communication with restaurants on the day of a booking. If something goes wrong, the Andie Promise will make it right.",
        }
    ]
    return (
        <section class="pb-32 bg-white overflow-hidden">
            <div class="container mx-auto">
                <p class="mb-5 text-sm text-blue-600 font-roboto-bold uppercase tracking-px">Frequently Asked questions</p>
                <h2 class="mb-16 andie-section-title leading-none">What you need to know</h2>
                <div class="mb-8 md:max-w-5xl">
                <div class="flex flex-wrap -m-4">
                    {faqs.map((faq, index) => (
                        <div class="w-full md:w-1/2 p-4">
                            <div class="p-6 border border-gray-50 rounded-2xl shadow-xl h-full">
                                <h3 class="mb-4 text-lg font-productsans-medium leading-normal">{index + 1}. {faq.question}</h3>
                                <p class="andie-paragraph text-gray-600 mr-5">{faq.answer} <span className="font-semibold text-gray-600">{faq.important_point}</span></p>
                            </div>
                        </div>
                    ))}
                </div>
                </div>
                <Link to="/customer-success-center" class="font-productsans-medium text-blue-600 hover:text-blue-700 border-b border-blue-600 hover:border-blue-700">See all questions &amp; answers</Link>
            </div>
        </section>
    )
}