import React from "react";
import { RequestDemoButton } from "../../common";
import { CheckCircleIcon } from "@heroicons/react/solid";

export default function PartnerCallToAction() {
    return (
        <section class="py-24 relative">
            <div class="container px-4 mx-auto">
                <div class="mx-auto py-12 p-6 md:p-12 bg-blue-100">
                <div class="flex flex-wrap -mx-4 items-center">
                    <div class="w-full md:w-1/2 lg:w-5/12 px-4 mb-6 md:mb-10 lg:mb-0">
                        <h2 class="text-3xl sm:text-4xl font-productsans-bold text-gray">Interested in a 15 minute demo on how Andie could work for your restaurant?</h2>
                    </div>
                    <div className="hidden lg:inline-block lg:w-4/12" />
                        <div class="w-full lg:w-3/12 xl:pl-20 px-4 text-center lg:text-right">
                            <RequestDemoButton />
                            <div className="flex flex-col mt-5 items-center">
                                <div className="flex">
                                    <CheckCircleIcon className="h-5 text-blue-600 mr-1.5" />
                                    <p className="text-sm text-gray-700 font-roboto-medium mr-1.5">Get started for free</p>
                                </div>
                                <div className="flex mt-2">
                                    <CheckCircleIcon className="h-5 text-blue-600 mr-1.5" />
                                    <p className="text-sm text-gray-700 font-roboto-medium">Quick setup process</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}