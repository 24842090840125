import React from "react";
import { Link } from "react-router-dom";
import Slideshow from './items/Slideshow.js'

export default function LandingHeader() {
  return (
    <div>
      <section>
      <div class="object-fill items-center justify-center flex">
        <Slideshow />
        <div class="absolute z-10 overflow-hidden w-full top-[200px] md:top-[300px] lg:top-[330px] ">
          <div class="container px-4 mx-auto">
            <div class="text-center">
              <div class="mb-16 2xl:block hidden"></div>
              <h1 class="mb-9 text-4xl md:text-5xl xl:text-6xl font-pj font-bold font-heading tracking-px-n leading-none text-white">
                Helping businesses grow and <br /> creating great corporate experiences
              </h1>

              <div class="mb-7 mt-16 lg:mt-10 md:inline-block">
                <div class="flex flex-wrap justify-between sm:space-x-96">
                  <Link to="/andie-app">
                    <a
                      class="py-2 px-7 w-full text-white font-medium text-sm rounded focus:ring focus:ring-indigo-300 bg-blue-700 hover:bg-blue-800 text-white rounded transition relative duration-200 w-36"
                      href="#"
                    >
                      The Andie App
                    </a>
                  </Link>
                  <Link to="/CGE">
                    <a
                      class="py-2 px-10 w-full text-white font-medium text-sm rounded focus:ring focus:ring-indigo-300 bg-blue-700 hover:bg-blue-800 text-white rounded transition relative duration-200 w-36"
                      href="#"
                    >
                      Andie CGE
                    </a>
                  </Link>
                </div>
              </div>
              <div class="mb-16 block"></div>
            </div>
          </div>
        </div>
        </div>
        </section>
    </div>
  );
}
