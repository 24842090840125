export default function AndieStoreApp() {
  return (
    <div class="pb-10 bg-indigo-50">
      <section class="pb-16 md:py-20 max-h-[700px] lg:max-h-[900px]">
        {/* This image only shows when screen is resized. Changing the scale number at the front i.e., scale-50 should change the scaling. */}
        <div class="flex flex-col">
          <div class="block md:hidden pb-5">
            <img
              class="object-cover h-[22rem] sm:h-[22rem] w-full"
              src={require("../../../../assets/images/pexels-lawrence-suzara-1581554.jpg")}
              alt="The Andie app helps restaruants drive real-time traffic to restaurants"
            />
            {/* <img class="scale-75 w-full max-w-md ml-10 mr-auto" src={require('../../../assets/images/art/andie-app-screenshot.png')} alt="The Andie app helps restaruants drive real-time traffic to restaurants"/> */}
            {/* Ideally need to center image */}
          </div>
        </div>
        <div class="container px-4 mx-auto pt-2">
          <div class="flex flex-wrap items-center -mx-4 h-full">
            <div class="flex flex-wrap items-center w-full md:w-1/2 px-4 mb-6 md:mb-0">
              <div class="md:pl-10">
                <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold lg:max-w-lg leading-tight text-gray-900 sm:leading-tight lg:leading-tight font-pj" >
                  Put your business on the{" "}
                  <span className="text-blue-700">Andie app</span>
                </h1>
                <div class="max-w-lg lg:mb-12">
                  <p className="mt-2 font-normal text-gray-600 font-pj sm:mt-6 lg:mr-10">
                    The Andie app, on web and mobile, offers real-time traffic
                    and trial solutions to help you reach nearby customers,
                    attract new guests, and grow your sales.
                  </p>
                </div>
                <div class="flex flex-wrap justify-center sm:justify-end sm:inline sm:order-2 sm:py-12">
                  <div class="px-4 mx-auto max-w-7xl sm:p-0">
                    <div class="mt-8 sm:flex sm:items-center lg:mt-0 sm:space-x-6">
                      <div class="inline-flex mr-7 md:mr-0">
                        <a href="https://apps.apple.com/ca/app/andie/id1514834765">
                          <img
                            class="w-auto h-10 mx-auto rounded-full"
                            src={
                              require("../../../../assets/images/app-store-logo.svg")
                                .default
                            }
                            alt=""
                          />
                        </a>
                      </div>

                      <div class="inline-flex sm:mt-0">
                        <a href="https://play.google.com/store/apps/details?id=com.andie.andie">
                          {" "}
                          <img
                            class="w-auto h-10 mx-auto rounded-full"
                            src={
                              require("../../../../assets/images/play-store-logo.svg")
                                .default
                            }
                            alt=""
                          />{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="collapse md:visible relative md:w-1/2 px-4 transform hover:-translate-x-16 transition ease-in-out duration-1000">
              <img class="relative rounded-lg shadow-md hover:shadow-lg shadow-slate-500 transition ease-in-out duration-1000" src={require("../../../../assets/images/pexels-lawrence-suzara-1581554.jpg")} alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
