import React from "react";
import LearnMore from "../../../common/LearnMore";

export default function Highlight3() {
    return (
        <section class="py-36 bg-white overflow-hidden">
  <div class="container mx-auto px-4">
    <div class="flex flex-wrap xl:items-center w-full">
      <div class="w-full md:w-1/2">
        <img class="mx-auto rounded-xl" src={require("../../../../assets/images/home/gain-new-traffic-insights.jpeg")} alt="" />
      </div>
      <div class="w-full md:w-1/2 px-8 xl:px-16">
        <div class="md:max-w-xl">
          <p class="mb-7 text-sm text-gray-600 font-semibold uppercase tracking-px">&#x1F44B; Leverage data like never before.</p>
          <h2 class="mb-14 andie-section-title xl:mr-10">Gain valuable insights from Andie's <span className="text-blue-600">Audience Awareness system</span>.</h2>
          <div class="grid grid-cols-2 gap-6 mb-8">
            <div class="w-full">
              <div class="md:max-w-xs">
                <div class="flex flex-wrap -m-2">
                  <div class="w-auto p-2">
                    <svg class="mt-1" width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 3V5M15 3V5M9 19V21M15 19V21M5 9H3M5 15H3M21 9H19M21 15H19M7 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19ZM9 9H15V15H9V9Z" stroke="#4F46E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </div>
                  <div class="flex-1 p-2">
                    <h3 class="mb-2 andie-section-heading">Traffic patterns</h3>
                    <p class="text-gray-600 andie-paragraph">View real-time and historical data on how guests are moving in and around your restaurant.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full">
              <div class="md:max-w-xs">
                <div class="flex flex-wrap -m-2">
                  <div class="w-auto p-2">
                    <svg class="mt-1" width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16 8V16M12 11V16M8 14V16M6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20Z" stroke="#4F46E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </div>
                  <div class="flex-1 p-2">
                    <h3 class="mb-2 andie-section-heading">Potential opportunities</h3>
                    <p class="text-gray-600 andie-paragraph">Get suggestions on offers, campaigns, and strategies that you can use to engage nearby guests.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <LearnMore title="Learn more" />
        </div>
      </div>
    </div>
  </div>
</section>
    )
}