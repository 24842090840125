import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import 'react-responsive-modal/styles.css';
import { Landing, AndieApp, CGE, RequestDemo, FAQForm, Blog, Partners, BlogView, ContactPageForm, RestaurantCGE, Home, FAQ, Pricing, AndiePromise, Tou, Privacy, Signup, Login, Redirect, Activate, GoogleLogin } from './pages';
import {Footer, Header} from "../src/components/navigation";
import {
  gql
} from "@apollo/client";
import { useEffect, useState } from 'react';
import formAPI from './api/FormAPI';
import PartnerStoryPage from './components/pages/partners/items/PartnerStoryPage';
import OrganizationIformation from './components/pages/login/OrganizationInformation';
// import pages
// eslint-disable-next-line
const POSTS = gql`
    query {
        posts (where: {industry: Auto}) {
        id
        content {
          raw
          html
          markdown
          text
        }
        title
        summary
        industry
        category
        author
        createdAt
        coverImage {
            id
            url
        }
        trending
        }
    }`
;
function App({ client }) {
  const [blogData, setBlogData] = useState([]);
  useEffect(() => {
    // const { loading, error, data } = useQuery(POSTS);
    // setBlogData(data);
    formAPI.setClient(client)
    client.query({
      query: POSTS
    }).then((result) => setBlogData(result.data.posts))
    // console.log(client)
  }, [])
  return (
    <Router basename="/">
      <Header />
      <Routes>
        {/* <Route path={"/search/"} exact component={Search} /> */}
        <Route path="/blog" exact element={<Blog blogData={blogData}/>} />
        <Route path="/andie-success-stories" element={<Partners/>} />
        <Route path="/customer-success-center" element={<FAQ blogData={blogData} />} />
        <Route path="/pricing" element={<Pricing />} />
        {/* <Route path="/" element={< CGE/>} /> */}
        <Route path="/" element={<Home blogData={blogData}/>} />
        <Route path="/landing" element={< Landing blogData={blogData}/>} />
        <Route path="/restaurant" element={<RestaurantCGE blogData={blogData} />} />
        <Route path="/success-stories" element={<PartnerStoryPage />} />
        <Route path="/the-andie-promise" element={<AndiePromise />} />
        <Route path={"/blog/post"} exact element={<BlogView blogData={blogData} />} />
        <Route path="/andie-app" element={< AndieApp/>} />
        <Route path="/cge" element={<CGE />} />
        <Route path={"/request-a-demo"} exact element={<RequestDemo />} />
        <Route path={"/contact-us"} exact element={<ContactPageForm />} />
        <Route path="/terms-and-conditions" element={<Tou />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/signup" exact element={<Signup />} />
        <Route path="/activate/:uid/:token" element={<Activate />} />
        <Route path="/auth/google/callback/" element={<GoogleLogin />} />
        <Route path="/redirect" element={<Redirect />} />
        <Route path="/login" element={<Login />} />
        <Route path='/complete-login' element={<OrganizationIformation />} />
      </Routes>
      <Footer />
    </Router>
  );
}
export default App;