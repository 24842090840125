import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { GetJWTFromGoogleLogin } from '../../../api/Authentication';
import { useNavigate } from 'react-router-dom';

export default function LoginWithGoogle() {
    const [loaded, setLoaded] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        Login()
    }, []);
    
    async function Login() {
        const JWTAuth = await GetJWTFromGoogleLogin(location.search)

        if (JWTAuth === true){
            setLoaded(true);
            handleNavigation()
        }
    }

    const handleNavigation = () => {
        navigate('/terms-and-conditions')
    }

    return (
        <>
            <div className="mx-4">
                {loaded
                    ?
                    <>
                        <div className="flex flex-col h-[80vh] w-full items-center justify-center gap-y-8">
                            <h1 className='text-4xl text-center font-bold'>
                                Logged In!
                            </h1>
                            <button onClick={handleNavigation} className='px-8 py-2 bg-green-600 text-2xl text-white rounded-lg font-semibold'>Continue</button>
                        </div>
                    </>
                    :
                    <>
                        <div className="flex flex-col h-[80vh] w-full items-center justify-center gap-y-8">
                            <div className="animate-spin rounded-full h-16 w-16">
                                <img
                                    src={require('../../../assets/images/andie-icon.png')}
                                />
                            </div>
                            <h1 className='text-4xl text-center font-bold'>
                                Logging In...
                            </h1>
                        </div>
                    </>
                }
            </div>
        </>
    )
}