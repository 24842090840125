import React from "react";
import { AndiePromiseAdditionalResources, AndiePromiseCallToAction, AndiePromiseHeader, AndiePromiseValues } from "../../components/pages/general/promise";
import FAQMoneyBack from "../../components/pages/general/faq/FAQMoneyBack";

export default function AndiePromise() {
    return (
        <div>
            <AndiePromiseHeader />
            <div className="andie-padding-horizontal">
                <AndiePromiseValues />
                <FAQMoneyBack />
                <AndiePromiseAdditionalResources />
                <AndiePromiseCallToAction />
            </div>
        </div>
    )
}