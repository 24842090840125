import React from "react";

export default function AndiePromiseRestaurants() {
    const promises = [
        "We know cashflow is important. Your funds will never be late.",
        "We will always be a great partner to your business.",
        "All bookings are always guaranteed.",
        "We value transparency. We'll always overshare and we expect the same.",
        "Your dedicated growth associate is always looking for opportunities that match your restaurant."
    ]
    return (
        <div className="bg-opacity-50 bg-blue-700 xl:h-[420px] rounded-sm flex p-8">
            <div className="lg:w-[45%] pr-5">
                <h3 className="andie-section-title text-white">Our promise to restaurants.</h3>
            </div>
            <div className="lg:w-[55%] xl:pl-7">
                {promises.map((promise, index) => (
                    <div className="flex mb-7">
                        <div className="bg-blue-200 h-9 w-9 shrink-0 mr-4 rounded-full flex items-center justify-center">
                            <h6 className="text-blue-700 font-productsans-black text-base">{index + 1}</h6>
                        </div>
                        <div className="text-lg leading-tight xl:mr-5 font-productsans-regular text-white">
                            {promise}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}