import React from 'react'
import { LandingBlog, LandingApp, LandingMeetCGE, LandingHeader } from '../../components/pages/general/Landing'

export default function Landing({blogData}) {
  return (
    <div>
        <LandingHeader />
        <LandingApp />
        <LandingMeetCGE />
        <LandingBlog blogData={blogData} />
    </div>
  )
}
