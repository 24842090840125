import { CheckCircleIcon, GiftIcon } from "@heroicons/react/solid";
import React from "react";

export default function Highlight1() {
    return (
        <>
            <section class="relative pb-24 md:pb-20 overflow-hidden">
                <div class="relative container px-4 mx-auto">
                    <div class="max-w-7xl mx-auto">
                    <div class="flex flex-wrap items-center -mx-4">
                        <div class="w-full lg:w-1/2 px-4 order-last lg:order-first">
                        <div class="relative pb-20 lg:pb-0">
                            <div class="absolute top-0 left-0 -mt-8 md:mt-9 inline-flex p-4 bg-white rounded-xl shadow-xl">
                            <div>
                                
                            </div>
                            <div class="mx-3">
                                <span class="text-sm font-productsans-black">Jerry's Bistro & Eats</span>
                                <span class="block text-sm text-gray-500">Open until 1.00am</span>
                            </div>
                            <div class="items-start">
                                <CheckCircleIcon className="h-4 text-green-500" />
                            </div>
                            </div>
                            <div class="absolute bottom-0 right-0 max-w-xs lg:-mb-16">
                            <div class="px-6 pt-6 pb-6 lg:pr-16 bg-white rounded-3xl shadow-xl">
                                <div class="inline-flex mb-6">
                                <div class="flex items-center justify-center w-11 h-11 rounded-full bg-red-200">
                                    <svg width="64px" height="64px" className="h-6" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill="#000000" d="M36.31,176c0.674,0.386 24.255,13.789 43.69,13.789c19.435,0 43.826,-13.403 44.524,-13.789l9.047,0c14.641,0.044 26.429,11.859 26.429,26.429l0,101.571c0,17.673 -14.327,32 -32,32l0,120c0,13.255 -10.745,24 -24,24l-48,0c-13.255,0 -24,-10.745 -24,-24l0,-120c-17.673,0 -32,-14.327 -32,-32l0,-100.738c0,-15.028 12.16,-27.216 27.262,-27.262l9.048,0Zm176,0c0.674,0.386 24.256,13.789 43.69,13.789c19.434,0 43.826,-13.403 44.524,-13.789l9.047,0c14.641,0.044 26.429,11.859 26.429,26.429l0,101.571c0,17.673 -14.327,32 -32,32l0,120c0,13.255 -10.745,24 -24,24l-48,0c-13.255,0 -24,-10.745 -24,-24l0,-120c-17.673,0 -32,-14.327 -32,-32l0,-100.738c0,-15.028 12.16,-27.216 27.262,-27.262l9.048,0Zm243.69,304l-48,0c-13.255,0 -24,-10.745 -24,-24l0,-120c-17.673,0 -32,-14.327 -32,-32l0,-100.738c0,-15.056 12.206,-27.262 27.262,-27.262l9.048,0c0,0 23.978,13.789 43.69,13.789c19.712,0 44.524,-13.789 44.524,-13.789l9.047,0c14.597,0 26.429,11.832 26.429,26.429l0,101.571c0,17.673 -14.327,32 -32,32l0,120c0,13.222 -10.691,23.946 -24,24Zm-376,-320c35.346,0 64,-28.654 64,-64c0,-35.346 -28.654,-64 -64,-64c-35.346,0 -64,28.654 -64,64c0,35.346 28.654,64 64,64Zm176,0c35.346,0 64,-28.654 64,-64c0,-35.346 -28.654,-64 -64,-64c-35.346,0 -64,28.654 -64,64c0,35.346 28.654,64 64,64Zm240,-64c0,35.346 -28.654,64 -64,64c-35.346,0 -64,-28.654 -64,-64c0,-35.346 28.654,-64 64,-64c35.346,0 64,28.654 64,64Z"></path></g></svg>
                                </div>
                                <div class="mx-3">
                                    <span class="andie-section-heading text-sm text-gray-600">Capacity</span>
                                    <span class="block andie-paragraph text-gray-500">70% full</span>
                                </div>
                                </div>
                                <div class="inline-flex mb-6">
                                <div class="flex items-center justify-center w-11 h-11 rounded-full bg-yellow-200">
                                <svg fill="#000000" width="64px" height="64px" className="h-6" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M9,6a1,1,0,0,1,1-1h4a1,1,0,0,1,0,2H10A1,1,0,0,1,9,6ZM23,2a1,1,0,0,1-1,1H21V22a1,1,0,0,1-2,0V11H5V22a1,1,0,0,1-2,0V3H2A1,1,0,0,1,2,1H22A1,1,0,0,1,23,2ZM19,3H5V9H19Z"></path></g></svg>
                                </div>
                                <div class="mx-3">
                                    <span class="andie-section-heading text-sm text-gray-600">Tables</span>
                                    <span class="block andie-paragraph text-gray-500">12 available</span>
                                </div>
                                </div>
                                <div class="inline-flex">
                                <div class="flex items-center justify-center w-11 h-11 rounded-full bg-green-200">
                                    <GiftIcon className="h-6 text-black" />
                                </div>
                                <div class="mx-3">
                                    <span class="andie-section-heading text-sm text-gray-600">Offers</span>
                                    <span class="block andie-paragraph text-gray-500">2 specials</span>
                                </div>
                                </div>
                            </div>
                            </div>
                            <img class="mx-auto rounded-xl" src={require("../../../../assets/images/home/andie-app-overview.png")} alt="" />
                        </div>
                        </div>
                        <div class="w-full lg:w-1/2 px-4 mb-24 lg:mb-0">
                        <div class="max-w-lg mx-auto lg:mr-0">
                            <h1 class="andie-section-title text-gray-900 mb-6">
                                Share what is happening at your restaurant right now 
                            </h1>
                            <p class="andie-image-heading mb-6">The Andie app allows you to share live information from your business directly on the web, mobile, social, and search .</p>
                            <ul className="space-y-5">
                                <li className="flex space-x-2 w-2/3 andie-paragraph">
                                    <CheckCircleIcon className="text-blue-600 h-6 shrink-0" />
                                    <p><strong>Share live capacity</strong> so guests can wait conveniently elsewhere when you're full.</p>
                                </li>
                                <li className="flex space-x-2 w-2/3 andie-paragraph">
                                    <CheckCircleIcon className="text-blue-600 h-6 shrink-0" />
                                    <p><strong>Provide latest walk-in availabilities</strong> and allow potential guests to grab available spots.</p>
                                </li>
                                <li className="flex space-x-2 w-2/3 andie-paragraph">
                                    <CheckCircleIcon className="text-blue-600 h-6 shrink-0" />
                                    <p><strong>Turn daily specials and limited time offers </strong>into instant traffic and trial.</p>
                                </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        </>
    )
}