import React, { useState } from "react";
import headerbackground from "../../../../../assets/images/corporate-cge/cge-how-it-works.png";
import image2 from "../../../../../assets/images/corporate-cge/image-food-section.png";
import { TalkToSalesButton } from "../../../../common";


export default function CGEHowItWorks() {
    const [sent, setSent] = useState(false);

    return (
        <>
            <section class="pt-8 bg-white overflow-hidden">
                <div class="container px-4 mx-auto">
                    <h2 class="mb-5 andie-section-title text-center">How it works</h2>
                    <p class="mb-20 andie-section-description text-center md:max-w-lg mx-auto">Andie CGE delivers great dining and catering experiences in just four clicks, and in less time than before.</p>
                    <div class="relative bg-no-repeat bg-center bg-cover overflow-hidden rounded-3xl" style={{ height: "648px", backgroundImage:`url(${[headerbackground]})` }}>
                        <div class="absolute top-0 left-0 p-14 md:p-20 md:pb-0 overflow-y-auto h-full">
                            <div class="flex flex-wrap">
                                <div class="w-full">
                                    <div class="flex flex-wrap -m-3">
                                        <div class="w-auto p-3">
                                            <svg width="35" height="35" viewbox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="17.5" cy="17.5" r="17.5" fill="#4F46E5"></circle>
                                                <path d="M11.667 18.3333L15.0003 21.6666L23.3337 13.3333" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            <img class="mx-auto" src="flaro-assets/images/how-it-works/line3.svg" alt="" />
                                        </div>
                                        <div class="flex-1 p-3">
                                            <div class="md:max-w-xs pb-8">
                                                <p class="mb-5 text-sm text-blue-200 font-roboto-medium uppercase tracking-px">Step 1</p>
                                                <h3 class="text-white andie-section-subheader">Open a bid</h3>
                                                <p class="text-gray-200 andie-paragraph">Create a bid with all the essential details about your upcoming event, including your preferred cuisines and other relevant information.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full">
                                    <div class="flex flex-wrap -m-3">
                                        <div class="w-auto p-3">
                                            <svg width="35" height="35" viewbox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="17.5" cy="17.5" r="17.5" fill="#4F46E5"></circle>
                                                <path d="M11.667 18.3333L15.0003 21.6666L23.3337 13.3333" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            <img class="mx-auto" src="flaro-assets/images/how-it-works/line3.svg" alt="" />
                                        </div>
                                        <div class="flex-1 p-3">
                                            <div class="md:max-w-xs pb-8">
                                                <p class="mb-5 text-sm text-blue-200 font-roboto-medium uppercase tracking-px">Step 2</p>
                                                <h3 class="text-white andie-section-subheader">Restaurants submit bid responses</h3>
                                                <p class="text-gray-200 andie-paragraph">Receive bid responses from CGE partners that match the information provided in your open bid.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full">
                                    <div class="flex flex-wrap -m-3">
                                        <div class="w-auto p-3">
                                            <svg width="35" height="35" viewbox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="17.5" cy="17.5" r="17" stroke="#CBD5E1"></circle>
                                                <path d="M11.667 18.3333L15.0003 21.6666L23.3337 13.3333" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </div>
                                        <div class="flex-1 p-3">
                                            <div class="md:max-w-xs pb-8">
                                                <p class="mb-5 text-sm text-blue-200 font-roboto-medium uppercase tracking-px">Step 3</p>
                                                <h3 class="text-white andie-section-subheader">Select your favourite response</h3>
                                                <p class="text-gray-200 andie-paragraph">Pick your favourite response.</p>
                                                <p class="text-gray-200 andie-paragraph">Andie will take care of the rest.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="relative mx-4 mt-14 mb-28 rounded-3xl py-14 bg-black bg-no-repeat bg-cover overflow-hidden" style={{ height: "520px", backgroundImage:`url(${[image2]})` }}>
                <div class="relative z-10 container px-4 mx-auto">
                    <div class="flex flex-wrap items-center">
                        <div class="w-full md:w-1/2 p-8">
                            <div class="md:max-w-md">
                                <h2 class="text-gray-900 andie-section-title mb-3">No event?<br/> No problem.</h2>
                                <p class="max-w-2xl text-xl font-productsans-black text-blue-600 mb-6">Try our CGE Templates to get started.</p>
                                <p class="mb-8 andie-paragraph"><strong>CGE Templates</strong> are customizable, limited-time, dining and catering offerings curated by the Andie team to provide inspirations.</p>
                            </div>
                            <div className="w-1/4">
                                <TalkToSalesButton />
                            </div>
                        </div>
                        <div class="w-full md:w-1/2 p-8"/>
                    </div>
                </div>
            </section>
        </>
    )
}