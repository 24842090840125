import axios from 'axios';
import jwt_decode from 'jwt-decode';
const BASE_URL = 'http://localhost:8000'

export function SignupGenericUser(formData) {
  return new Promise((resolve, reject) => {
    let data = JSON.stringify({
      "email": formData.email,
      "first_name": formData.first_name,
      "last_name": formData.last_name,
      "password": formData.password,
      "re_password": formData.re_password
    });
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: BASE_URL + '/auth/users/',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        console.log(response.status);
        resolve(true); // Resolve the promise with the success value
      })
      .catch((error) => {
        console.log(error);
        alert('An error occured');
      });
  });
}

export async function InitiateGoogleLoginSignup(){
  let data = '';
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: 'http://localhost:8000/auth/o/google-oauth2/?redirect_uri=http://localhost:3000/auth/google/callback',
    headers: { 
      'Content-Type': 'application/json', 
      'Cookie': 'sessionid=zcego80ydzqcdyf9mf2nhlqx3fijx1v9'
    },
    data : data
  };
  
  axios.request(config)
  .then((response) => {
    window.location.replace(response.data.authorization_url);
  })
  .catch((error) => {
    console.log(error);
  });
  
}

export async function GetJWTFromGoogleLogin(searchval){
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: 'http://localhost:8000/auth/google/callback'+searchval,
  };

  return await axios.request(config)
  .then((response) => {
    setToken(response.data.jwt_token)
    return true

  })
  .catch((error) => {
    return false
  });

}

export async function VerifyUser(uid, token) {
  let data = JSON.stringify({
    "uid": uid,
    "token": token
  });
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: BASE_URL + '/auth/users/activation/',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data
  };
  return await axios.request(config)
    .then((response) => {
      console.log("verified")
      return true
    })
    .catch((error) => {
      console.log(error)
      return error.response.data.detail
    });
}

export async function LoginUser(formData) {
  let data = JSON.stringify({
    email: formData.email,
    password: formData.password
  });

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'http://localhost:8000/auth/jwt/create/',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  // console.log(formData);

  return await axios.request(config)
    .then(async (response) => {
      console.log(response)
      return await VerifyUserOnLogin(response.data.access)
    })
    .catch((error) => {
      console.log(error)
    })
}

export async function VerifyUserOnLogin(bearer) {
  //console.log(bearer)
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: 'http://localhost:8001/auth/test/',
    headers: {
      'Authorization': 'Bearer ' + bearer
    }
  };

  // return await axios.request(config)
  //   .then(async (response) => {
  //     console.log('Verify Test User', JSON.stringify(response.data));
  //     return 1
  //   })
  //   .catch((error) => {
  //     if(error.status === 403) {
  //     } else {
  //       return 0
  //     }
  //     console.log(error);
  //   });
  try {
    const response = await axios(config);
    console.log('Verify Test User', JSON.stringify(response.data));
    setToken(bearer);
    return 1
  } catch (error) {
    if (error.response && error.response.status === 403) {
      return -1
    } else {
      // Handle other errors
      console.log('An error occurred:', error.message);
      return 0
    }
  }
}

export async function Register_CGE(formData) {
  let data = JSON.stringify({
    "email": formData.email,
    "first_name": "Victor",
    "last_name": "Singh",
    "organization": formData.organization,
    "industry": formData.industry,
    "phone": formData.phone,
    "additional_users": formData.additional_users,
    "job_title": formData.job_title,
    "stripe_token": "xxxxxxxxxxxxxxxxxxxx",
    "authorized": true
  });

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'http://localhost:8001/auth/register_cge/',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjg2NjgzOTM1LCJpYXQiOjE2ODY2ODAzMzUsImp0aSI6IjBhNWEyMjdkZDliNTQyYzM5NDI2YjcxZjU4ZjdmMTM3IiwidXNlcl9pZCI6NDl9.rJvEs-kRMKIlbAuxN6xQZUfhPV2z6dy7GfPfnyCxfos',
      'Cookie': 'sessionid=ic1ewj0pja5zbl9hfucfngwuo5yduuyp'
    },
    data: data
  };

  axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
}

export function setToken(token) {
  sessionStorage.setItem('andie_token', token);
}

export function getToken() {
  try {
    return sessionStorage.getItem('andie_token');
  } catch (err) {
    return null;
  }
}

export function removeToken() {
  sessionStorage.removeItem('andie_token');
}

export function readToken() {
  try {
    const token = getToken();
    return token ? jwt_decode(token) : null;
  } catch (err) {
    return null;
  }
}

export function isAuthenticated() {
  const token = readToken();
  return token ? true : false
}
