import React from "react";
import aovicon from "../../../../../assets/images/header-icons/aov-icon.svg";
import aovpeople from "../../../../../assets/images/header-icons/aov-people.svg";
import { PlayIcon } from "@heroicons/react/solid";

export default function RestaurantCGEPlatform() {
  return (
    <section class="mt-20 pt-16 pb-20 px-8 bg-blue-50 rounded-2xl">
      <div class="container px-4 mx-auto">
        <div class="flex w-full justify-between mb-12">
          <div class="w-full lg:w-1/2 mb-6 lg:mb-0">
            <h2 class="andie-section-title max-w-xl xl:mr-10">
              <span>A bidding platform built to take your restaurant to the next level. </span>
            </h2>
            <p class="mt-6 andie-section-description">Andie CGE is an easy-to-use web application that connects restaurants like yours to unique opportunities through an open bidding system.</p>
          </div>
          <div class="flex flex-col items-end space-y-4">
            
            <div className="inline-flex h-8 items-center bg-blue-100 px-3 rounded-full">
              <img src={aovpeople} className="h-5" />
              <h6 className="text-sm font-productsans-medium ml-1"> 65 guests / order</h6>
            </div>
            <div className="inline-flex h-8 items-center bg-blue-100 px-3 w-32 rounded-full">
              <img src={aovicon} className="h-5" />
              <h6 className="text-sm font-productsans-medium ml-1">AOV. $1650</h6>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 -mb-6">
          <div class="w-full md:w-1/2 lg:w-1/4 px-3 mb-6">
            <div class="pt-8 px-6 pb-6 bg-white text-center rounded shadow">
              <svg class="w-10 h-10 mx-auto mb-4 text-blue-600" fill="none" stroke="currentColor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"></path>
              </svg>
              <h3 class="mb-2 andie-section-subheader">Access great opportunities</h3>
              <p class="andie-paragraph">Andie CGE allows you to bid on any opportunity as long as you meet the bid's criteria.</p>
            </div>
          </div>
          <div class="w-full md:w-1/2 lg:w-1/4 px-3 mb-6">
            <div class="pt-8 px-6 pb-6 bg-white text-center rounded shadow">
              <svg class="w-10 h-10 mx-auto mb-4 text-blue-600" fill="none" stroke="currentColor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2"></path>
              </svg>
              <h3 class="mb-2 andie-section-subheader">Showcase your restauarant</h3>
              <p class="andie-paragraph">The emphasis of each bid is on what you can provide for prospective guests regardless of your brand name.</p>
            </div>
          </div>
          <div class="w-full md:w-1/2 lg:w-1/4 px-3 mb-6">
            <div class="pt-8 px-6 pb-6 bg-white text-center rounded shadow">
              <svg class="w-10 h-10 mx-auto mb-4 text-blue-600" fill="none" stroke="currentColor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path>
              </svg>
              <h3 class="mb-2 andie-section-subheader">Increase your reach</h3>
              <p class="andie-paragraph">Turn mouthwatering and memorable dining and catering experiences into new fans and repeat guests.</p>
            </div>
          </div>
          <div class="w-full md:w-1/2 lg:w-1/4 px-3 mb-6">
            <div class="pt-8 px-6 pb-6 bg-blue-600 flex justify-center items-center h-full rounded shadow">
              <div className="h-12 w-12 border-4 flex justify-center items-center border-white rounded-full">
                <PlayIcon className="h-10 text-white" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
