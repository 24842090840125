import { CheckIcon } from "@heroicons/react/solid";
import React from "react";
import { Link } from "react-router-dom";

export default function BlogResources() {
    const resources = [
        {
            "title": "The Andie Promise",
            "description": "We cherish bringing people and restaurants together for great dining experiences.",
            "to": "andie-for-restaurants"
        },
        {
            "title": "For restaurants",
            "description": "Explore how Andie can help your restaurant drive traffic and revenue in real-time.",
            "to": "andie-for-restaurants"
        },
        {
            "title": "Corporate guest experiences",
            "description": "Enjoy great corporate dining experiences at our partner restaurants.",
            "to": "andie-corporate-guest-experiences"
        },
        {
            "title": "Success stories",
            "description": "Hear how Andie is helping partner restaurants, corporates, and organizations.",
            "to": "andie-success-stories"
        },
        {
            "title": "Help & FAQ",
            "description": "Get instant support or access the latest answers to frequently asked questions.",
            "to": "customer-success-center"
        },
        {
            "title": "Pricing",
            "description": "Learn about how commitment to only making money when our partners and guests are happy.",
            "to": "pricing"
        },
    ]
    return (
        <section class="relative py-20 overflow-hidden">
            <div class="container px-4 mx-auto">
                <div class="mb-16 pb-20 border-b border-gray-200">
                    <h2 class="andie-section-title leading-none md:max-w-xl">Learn more about our products and solutions.</h2>
                </div>
                <div class="flex flex-wrap -m-8">
                    {resources.map((resource, index) => (
                        <div class="w-full md:w-1/3 p-8">
                            <div class="md:max-w-xs">
                                <div class="flex flex-col justify-between h-full">
                                    <div class="mb-7">
                                        <h3 class="mb-7 text-xl font-semibold leading-snug font-productsans-bold">{resource.title}</h3>
                                        <p class="text-gray-600 andie-paragraph">{resource.description}</p>
                                    </div>
                                    {resource === "Social" ? 
                                        <a class="inline-flex items-center max-w-max text-blue-600 hover:text-blue-700" target="_blank" to={resource.to}>
                                            <span class="mr-2 text-sm font-productsans-medium">Learn more</span>
                                            <svg width="18" height="18" viewbox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.5 3.75L15.75 9M15.75 9L10.5 14.25M15.75 9L2.25 9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </a>
                                    :
                                        <Link class="inline-flex items-center max-w-max text-blue-600 hover:text-blue-700 pb-2 border-b-2 border-blue-600" to={resource.to}>
                                            <span class="mr-2 text-sm font-productsans-medium">Learn more</span>
                                            <svg width="18" height="18" viewbox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.5 3.75L15.75 9M15.75 9L10.5 14.25M15.75 9L2.25 9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </Link>
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}