import React, { useState } from "react";
import FAQItems from "./items/FAQItems";

export default function FAQSection() {
    const [selected, setSelected] = useState("Restaurants");

    const tabs = [
        "Restaurants", 
        "Corporates and Organizations",
        // "General App Users",
        // "Other"
    ]

    const restaurantfaqs = [
        {
            "question": "Do I need to purchase any hardware to use Andie?",
            "answer": "No. Our current restaurant solutions - Andie CGE, Host Hub, Opportunity Awareness System, and the Partner Dashboard can be accessed from any computer device you currently own."
        }, 
        {
            "question": "Can I submit a bid on Andie CGE without an account?",
            "answer": "Restaurants need to be verified to bid on corporate guest experiences that match their profile."
        },
        {
            "question": "How fast will I get paid?",
            "answer": "With Andie, you can send your money to an external bank account in two to three business days for free or instantly for a fee. Payment advances are available to support the execution of big ticket bookings such as corporate guest experiences."
        },
        {
            "question": "Can I choose which Andie products to use?",
            "answer": "Yes. While we typically advice restaurants to use our entire suite of solutions for complete data, you can choose which Andie solutions you would like to utlize."
        },
        {
            "question": "Do I have to sign any long term contracts?",
            "answer": "Most Andie solutions are pay-as-you-go so that you only pay when you've received value from the Andie platform. No long term commitments or contracts."
        },
        {
            "question": "Can Andie help us improve our menu and pricing strategy?",
            "answer": "Yes. We provide menu pricing insights and competitive analysis to parnter restaurants on how to improve their restaurant's offerings, deliver great value, and earn as much revenue possible."
        },
        {
            "question": "Is Andie easy to integrate with our existing systems?",
            "answer": "We are continually working on ensuring that Andie intergrates directly into your POS infrastructure."
        },
        {
            "question": "What kind of insights can Andie provide about my restaurant?",
            "answer": "Andie provides restaurants with real-time insights on nearby traffic patterns, opportunities, restaurant traffic and revenue performance."
        }
    ]

    const corporatefaqs = [
        {
            "question": "Do I need an account to create a bid request?",
            "answer": "Yes. A verified and approved account is required to create a bid on the Andie CGE platform."
        }, 
        {
            "question": "How many bid responses are allowed for a bid request?",
            "answer": "As many restaurants as possible can submit a response to your bid requests. The objective of each bid is to deliver as much value and variety possible."
        },
        {
            "question": "Do I have to prepay for my CGE experiences?",
            "answer": "A deposit or payment in full is required to lock-in your Andie CGE booking. However, if you regularly book on the platform, we can offer additional flexible payment options. "
        },
        {
            "question": "Can I combine bid responses from multiple restaurants?",
            "answer": "Yes. Our Catering Plus option allows you to combine food items from multiple restaurants into one booking easily."
        },
        {
            "question": "Do I have to sign any long term contracts?",
            "answer": "No long term commitments or contracts."
        },
        {
            "question": "Can I open multiple bid requests at once?",
            "answer": "You can have multiple open bid requests at the same time. Duplicate bid requests are not permitted for same potential booking."
        },
        {
            "question": "How long does it take to approve a CGE account?",
            "answer": "CGE accounts are approved within 24 hours."
        },
        {
            "question": "What support does Andie provide for bid requests and bookings?",
            "answer": "Once a bid request is created, a dedicated associate is assigned to assist with the bid response process and the execution of the booking. We also provide on-site support on the day of the booking."
        }
    ]
    return (
        <section class="relative pt-20 pb-12">
            <div class="container px-4 mx-auto">
                <div class="max-w-2xl mx-auto text-center mb-12">
                <h2 class="mt-8 mb-10 andie-section-title">Frequently Asked Questions</h2>
                <p class="andie-section-description text-center mx-auto">We've compiled this list to help you better understand the capabilities of Andie, its benefits, and more.</p>
                </div>
                <div class="max-w-2xl flex space-x-4 xl:space-x-6 mb-6 md:mb-10 mx-auto text-center md:border-b">
                    {tabs.map((tab, index) => (
                        <div onClick={() => setSelected(tab)} class={`cursor-pointer text-sm pb-4 mb-2 md:mb-0 mr-4 text-gray-500 font-semibold border-b-2 border-transparent hover:border-gray-500 ${selected === tab && "border-b-2 border-green-600"}`}>{tab}</div>
                    ))}
                </div>
                {/* <FAQItems faqs={restaurantfaqs} /> */}
                <FAQItems faqs={corporatefaqs} />
                <div className="mt-9 flex justify-center">
                    <a href="mailto:hospitality@andie.work" className="andie-dark-button inline-flex w-auto">
                        Can't find what you're looking for?
                    </a>
                </div>
            </div>
        </section>
    )
}