
export default function Redirect() {
    return (
        <>
            <div className="flex flex-col justify-center items-center h-[80vh] mx-4 gap-y-8">
                <h1 className="text-6xl font-bold text-green-500 text-center">Welcome to Andie!</h1>
                <p className="text-xl font-semibold text-center">An activation link has been sent to your email address. To activate your account, click the link provided in the email.</p>
                <p className="text-xl font-semibold text-center">If you don't see the email in your inbox, please check your spam folder as well.</p>
            </div>
        </>
    )
}