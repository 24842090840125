import React from "react";
import { Link } from "react-router-dom";

export default function RestaurantCGETestimonial() {
  return (
    <section class="pt-10 overflow-hidden">
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap -m-6">
          <div class="w-full md:w-1/2 p-6">
            <div class="h-full md:max-w-md">
              <div class="flex flex-wrap h-full -m-6">
                <div class="w-full p-6">
                  <p class="mb-5 font-roboto-regular max-w-max px-4 py-1 uppercase text-xs tracking-wider text-white bg-blue-600 rounded-sm">testimonials</p>
                  <h2 class="mb-6 andie-section-title text-gray-900">Trusted by restaurants like yours.</h2>
                  <p class="andie-section-description text-gray-500">From fine dining to Asian bistro, Andie CGE is redefining how restaurants like yours connect with corporate guests.</p>
                </div>
                <div class="w-full self-end p-6">
                  <Link class="group inline-block font-productsans-medium border-b-2 pb-1 border-blue-600 text-blue-600 text-sm overflow-hidden" to="/andie-success-stories">
                    <p class="mb-1">Hear from more partners</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/2 p-6">
            <div class="flex flex-wrap justify-center md:max-w-lg md:mx-auto divide-y divide-gray-200">
              <div class="w-full lg:w-auto">
                <div class="pb-7">
                  <p class="mb-6 andie-paragraph">&ldquo;It was a great experience collaborating with the Andie Team. The company's ability to connect and provide businesses like ours with the opportunity to offer delicious menus for corporate clients, has resulted in an increase in orders for us, and customer satisfaction for our clients.&rdquo;</p>
                  <div class="flex flex-wrap -m-1.5">
                    <div class="w-auto p-1.5">
                      <div className="w-10 h-10 flex items-center justify-center rounded-full bg-blue-50">
                        <h6 className="text-base font-productsans-black">TP</h6>
                      </div>
                    </div>
                    <div class="w-auto p-1.5">
                      <h3 class="andie-section-heading">Tommy Pong</h3>
                      <p class="-mt-0.5 text-xs font-roboto-medium text-gray-600">Si Lom Thai</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-auto">
                <div class="py-7">
                  <p class="mb-6 andie-paragraph">&ldquo;We are very happy with Andie CGE. They
                        have given us another outlet to get our tasty, healthy
                        juices in front of a larger audience! We are constantly
                        looking for opportunities to get into corporate spaces
                        and Andie does just that!.&rdquo;</p>
                  <div class="flex flex-wrap -m-1.5">
                    <div class="w-auto p-1.5">
                      <div className="w-10 h-10 flex items-center justify-center rounded-full bg-blue-50">
                        <h6 className="text-base font-productsans-black">CS</h6>
                      </div>
                    </div>
                    <div class="w-auto p-1.5">
                      <h3 class="andie-section-heading">Corey Glass Smith</h3>
                      <p class="-mt-0.5 text-xs font-roboto-medium text-gray-600">Ase Juicery</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-auto">
                <div class="py-7">
                  <p class="mb-6 andie-paragraph">&ldquo;We really appreciate the opportunity to serve you.
                        Collaborating with Andie and the team was an amazing
                        experience. The communication was commendable and all
                        requests were clearly articulated, making the order
                        execution seamless.&rdquo;</p>
                  <div class="flex flex-wrap -m-1.5">
                    <div class="w-auto p-1.5">
                      <div className="w-10 h-10 flex items-center justify-center rounded-full bg-blue-50">
                        <h6 className="text-base font-productsans-black">KD</h6>
                      </div>
                    </div>
                    <div class="w-auto p-1.5">
                      <h3 class="andie-section-heading">Kanishk Dilawari</h3>
                      <p class="-mt-0.5 text-xs font-roboto-medium text-gray-600">La Diperie</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
