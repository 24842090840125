export const initalState = [
    { id: 1, imgUrl: require('../../../../../assets/images/landingslideshow/pexels-fauxels-3184177.jpg')},
    { id: 2, imgUrl: require('../../../../../assets/images/landingslideshow/pexels-fauxels-3184180.jpg')},
    { id: 3, imgUrl: require('../../../../../assets/images/landingslideshow/pexels-luna-lovegood-4087621.jpg')},
    { id: 4, imgUrl: require('../../../../../assets/images/landingslideshow/pexels-mikhail-nilov-8102225.jpg')},
    { id: 5, imgUrl: require('../../../../../assets/images/landingslideshow/pexels-naim-benjelloun-2291367.jpg')},
    { id: 6, imgUrl: require('../../../../../assets/images/landingslideshow/pexels-aayush-rawat-8414639.jpg')},
    { id: 7, imgUrl: require('../../../../../assets/images/landingslideshow/pexels-angela-roma-7363753.jpg')},

   
    
    ];