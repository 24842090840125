import { useState } from "react"
import formAPI from "../../../api/FormAPI"

export default function BlogHeader() {
    const [email, setEmail] = useState("");

    const subscribe = (e) => {
        const data = {
            email: email,
            form: 'get started'
        }
        formAPI.subscribe(e, data, 'Successfully signed up!')
    }

    return (
        <div>
            <section class="py-12 border-b border-gray-100 bg-white sm:py-16">
                <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div class="max-w-2xl mx-auto text-center">
                        <h2 class="andie-section-title">
                            Blog
                        </h2>
                        <p class="max-w-md mx-auto mt-5 andie-paragraph">
                            Stay up-to-date with the latest news and trends in corporate catering, event planning, and culinary innovations with our blog
                        </p>
                    </div>

                    <div class="relative max-w-lg mx-auto mt-14">
                        {/* <div class="absolute -inset-x-2 -inset-y-5">
                            <div class="w-full h-full mx-auto rotate-180 opacity-30 blur-lg filter" style={{ background: 'linear-gradient(90deg, #44FF9A -0.55%, #44B0FF 22.86%, #8B44FF 48.36%, #FF6644 73.33%, #EBFF70 99.34%)' }}></div>
                        </div> */}

                        <form action="#" method="POST" class="relative" onSubmit={(e) => subscribe(e)}>
                            <input type="email" name="" id="" onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email address" class="block w-full px-4 py-4 text-sm font-normal text-black placeholder-gray-600 bg-white border border-gray-300 rounded focus:border-black focus:ring-1 focus:ring-black font-productsans-medium focus:outline-none" required />

                            <div class="mt-4 sm:mt-0 sm:absolute sm:inset-y-0 sm:right-0 sm:flex sm:items-center sm:pr-3">
                            <button type="submit" class="-mr-1 andie-dark-button">
                                Subscribe
                            </button>
                            </div>
                        </form>
                    </div>

                    <p class="mt-6 text-xs font-normal text-center text-gray-500 font-roboto-regular">
                        You can unsubscribe at any time. Read our privacy policy.
                    </p>
                </div>
            </section>
        </div>
    )
}
