import { CheckIcon, InformationCircleIcon } from "@heroicons/react/solid";
import React from "react";

export default function RestaurantCGECorporates() {
    const reasons = [
        "Dining and catering in as little as four clicks",
        "Amazing restaurants and cuisine options",
        "Personalized and customizable menus",
        "Seamless execution"
    ]
    return (
        <section class="border-t border-b border-gray-100 py-20 overflow-hidden">
            <div class="container mx-auto px-4">
                <p class="mb-5 inline-block font-roboto-bold text-green-600 uppercase text-xs">15,000+ Guest Experiences</p>
                <div class="flex flex-wrap -m-6">
                    <div class="w-full md:w-1/2 xl:w-[30%] p-6 xl:pr-0">
                        <div class="max-w-xl">
                        <h2 class="andie-section-title text-gray-900 xl:mr-10">Industry leading organizations choose Andie.</h2>
                        </div>
                    </div>
                    <div class="w-full md:w-1/2 xl:w-[40%] p-6 xl:px-8">
                        <div class="grid grid-cols-4 -m-5">
                            <div class="w-auto p-5">
                                <img src={require("../../../../../assets/images/logos/blue.png")}  className="h-10 w-full grayscale object-contain hover:grayscale-0" alt="" />
                            </div>
                            <div class="w-auto p-5">
                                <img src={require("../../../../../assets/images/logos/dmz.png")}  className="h-10 w-full grayscale object-contain hover:grayscale-0" alt="" />
                            </div>
                            <div class="w-auto p-5">
                                <img src={require("../../../../../assets/images/logos/tmu.png")}  className="h-10 w-full grayscale object-contain hover:grayscale-0" alt="" />
                            </div>
                            <div class="w-auto p-5">
                                <img src={require("../../../../../assets/images/logos/shiftride.png")}  className="h-10 w-full grayscale object-contain hover:grayscale-0" alt="" />
                            </div>
                            <div class="w-auto p-5">
                                <img src={require("../../../../../assets/images/logos/Worksimply.png")}  className="h-10 w-full grayscale object-contain hover:grayscale-0" alt="" />
                            </div>
                            <div class="w-auto p-5">
                                <img src={require("../../../../../assets/images/logos/ford.png")}  className="h-10 w-full grayscale object-contain hover:grayscale-0" alt="" />
                            </div>
                            <div class="w-auto p-5">
                                <img src={require("../../../../../assets/images/logos/lightster.png")}  className="h-10 w-full grayscale object-contain hover:grayscale-0" alt="" />
                            </div>
                            <div class="w-auto p-5">
                                <img src={require("../../../../../assets/images/logos/cibc.png")}  className="h-10 w-full grayscale object-contain hover:grayscale-0" alt="" />
                            </div>
                            <div class="w-auto p-5">
                                <img src={require("../../../../../assets/images/logos/Softdrive.png")}  className="h-10 w-full grayscale object-contain hover:grayscale-0" alt="" />
                            </div>
                            <div class="w-auto p-5">
                                <img src={require("../../../../../assets/images/logos/Avian.jpg")}  className="h-14 w-full grayscale object-contain hover:grayscale-0" alt="" />
                            </div>
                            <div class="w-auto p-5">
                                <img src={require("../../../../../assets/images/logos/Wokrhaus.png")}  className="h-14 w-full grayscale object-contain hover:grayscale-0" alt="" />
                            </div>
                            <div class="w-auto p-5">
                                <img src={require("../../../../../assets/images/logos/autonomic.png")}  className="h-10 w-full grayscale object-contain hover:grayscale-0" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="hidden xl:inline-flex xl:w-[30%] pt-10 pr-5 justify-end">
                        <div className="flex flex-col space-y-7">
                            {reasons.map((why, index) => (
                                <div className="flex space-x-2">
                                    <CheckIcon className="h-5 text-green-500" />
                                    <h6 className="font-productsans-medium text-sm text-gray-500">{why}.</h6>
                                </div>
                            ))}
                            <div className="h-2 w-32 bg-blue-100" />
                        </div>
                    </div>
                </div>
                <div className="flex justify-center">
                    <div className="inline-flex items-center px-6 mt-14 shadow-xl h-10 rounded-full mx-auto">
                        <InformationCircleIcon className="h-5 text-blue-600 mr-1" />
                        <h6 className="text-sm font-productsans-medium text-gray-500">76% of corporate users use Andie CGE at least 3 times every quarter.</h6>
                    </div>
                </div>
            </div>
        </section>
    )
}