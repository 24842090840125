import React from "react";
import { RequestDemoButton } from "../../common";
import { PlayIcon } from "@heroicons/react/solid";

export default function HomeCallToAction() {
    return (
        <section class="my-24 relative py-10 bg-blue-100 rounded-2xl overflow-hidden">
            <div class="relative z-10 container px-4 mx-auto">
                <div class="flex flex-wrap">
                <div class="w-full md:w-1/3 p-8">
                    <img class="mx-auto transform hover:translate-y-4 h-52 rounded-full transition ease-in-out duration-1000" src={require("../../../assets/images/Bet-on-yourself.jpg")} alt="" />
                </div>
                <div class="w-full md:flex-1 p-6">
                    <div class="md:max-w-4xl mx-auto text-center">
                    <h2 class="mb-10 andie-section-title">Get closer to your daily traffic and revenue goals.</h2>
                    <div class="mb-12 md:inline-block">
                        <RequestDemoButton />
                    </div>
                    <div class="md:max-w-sm mx-auto">
                        <div class="flex flex-wrap -m-2">
                            <div class="w-auto p-2">
                                <svg class="mt-1" width="26" height="20" viewbox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 20V14.2777C0 12.6321 0.306867 10.921 0.920601 9.14446C1.55293 7.34923 2.40844 5.65685 3.48712 4.06732C4.58441 2.45909 5.81187 1.10332 7.16953 0L11.8562 3.0575C10.7589 4.72183 9.83834 6.46096 9.09442 8.2749C8.3691 10.0701 8.01574 12.0524 8.03433 14.2216V20H0ZM14.1438 20V14.2777C14.1438 12.6321 14.4506 10.921 15.0644 9.14446C15.6967 7.34923 16.5522 5.65685 17.6309 4.06732C18.7282 2.45909 19.9557 1.10332 21.3133 0L26 3.0575C24.9027 4.72183 23.9821 6.46096 23.2382 8.2749C22.5129 10.0701 22.1595 12.0524 22.1781 14.2216V20H14.1438Z" fill="#000000"></path>
                                </svg>
                            </div>
                            <div class="flex-1 p-2">
                                <p class="mb-4 text-base font-roboto-medium leading-normal text-left">I love this app. I can see how it makes life easier for everyone - even me.</p>
                                <h3 class="font-bold text-left">- Fred VanVleet, NBA Champion</h3>
                            </div>
                        </div>
                        <a href="https://open.spotify.com/episode/6sXGquCGboVxrKMV2bzgzd?si=776de3a1ffde400c" target="_blank" rel="noreferrer">
                            <div className="mt-5 inline-flex h-9 px-6 items-center bg-blue-200 rounded-full text-sm font-productsans-medium text-blue-600">
                                <PlayIcon className="text-blue-700 h-6 mr-1" />
                                <p className="text-sm font-productsans-medium text-blue-600">Listen to the conversation</p>
                            </div>
                        </a>
                    </div>
                    </div>
                </div>
                <div class="w-full md:w-1/3 self-end p-8">
                    <img class="mx-auto transform hover:-translate-y-4 h-52 rounded-full transition ease-in-out duration-1000" src={require("../../../assets/images/Baba-Ajayi-on-Fred-Vanvleet.jpg")} alt="" />
                </div>
                </div>
            </div>
        </section>
    )
}