import React from "react";

export default function AndiePromiseGuests() {
    const promises = [
        "Your experience is our number priority. Expect a memorable time. Always.",
        "We expect you to be on time.",
        "Your offers from Andie are guaranteed. So far as you show up on time.",
        "Showing up is important to our restaurant partners. If something changes let us know."
    ]
    return (
        <div className="bg-opacity-50 bg-blue-700 xl:h-[420px] rounded-sm flex p-8">
            <div className="lg:w-[45%] pr-5">
                <h3 className="andie-section-title text-white">Our promise to guests.</h3>
            </div>
            <div className="lg:w-[55%] xl:pl-7">
                {promises.map((promise, index) => (
                    <div className="flex mb-7">
                        <div className="bg-blue-200 h-9 w-9 shrink-0 mr-4 rounded-full flex items-center justify-center">
                            <h6 className="text-blue-700 font-productsans-black text-base">{index + 1}</h6>
                        </div>
                        <div className="text-lg leading-tight xl:mr-5 font-productsans-regular text-white">
                            {promise}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}