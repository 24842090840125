import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { RichText } from "@graphcms/rich-text-react-renderer";
import { ArrowCircleLeftIcon } from "@heroicons/react/solid";
import moment from "moment";

export default function BlogView({ blogData }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    const url = new URLSearchParams(window.location.search);
    const title = url.get("title");
    blogData.find((blog) => {
      if (blog.title === title) {
        setData(blog);
        setLoading(false);
        return true;
      }
    });
  });

  if (loading) {
    return <p>Loading...</p>;
  }
  // else if (error) {
  //   console.log(error)
  //     return <p>Error :(</p>;
  // }
  else {
    // console.log(data)
    return (
      <div>
        <section class="py-12 bg-white sm:py-16 lg:py-20">
          <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="max-w-3xl mx-auto lg:ml-20 xl:max-w-2xl">
              <Link to="/blog" className="flex">
                <ArrowCircleLeftIcon className="h-7 w-7 text-indigo-600 mb-8" />
              </Link>
              <p class="text-sm font-bold tracking-widest text-gray-400 uppercase font-pj">
                Blog
              </p>
              <h1 class="text-3xl font-bold text-gray-800 mt-7 sm:text-4xl xl:text-5xl font-pj">
                {data.title}
              </h1>
              <div class="flex flex-wrap pt-7">
                <div class="w-[50px] h-[50px] mb-4 mx-2 pt-2">
                  <img
                    src={require("../../assets/images/D65ED023-A249-4133-B894-85BAD88C59EF (1) (1).jpg")}
                    alt=""
                    class="rounded-full border-solid mb-6 mx-auto"
                  />
                </div>
                <div>
                  <h6 class="text-base font-bold text-gray-600 mt-5 font-pj">
                    {data.author}
                  </h6>
                </div>
              </div>
              <p class="text-xs font-semibold text-gray-600 mt-7 font-pj">
                {moment(data.createdAt).format("MMMM Do, YYYY")}
              </p>
              {/* <blockquote class="pl-5 mt-8 border-l-2 border-gray-900">
                            <p class="text-lg italic font-medium text-gray-900 font-pj">
                                “{data.summary}”
                            </p>
                            </blockquote> */}
              <div class="relative mt-16">
                <div class="absolute -inset-2">
                  <div
                    class="w-full h-full mx-auto opacity-30 blur-lg filter"
                    style={{
                      background:
                        "linear-gradient(90deg, #44FF9A -0.55%, #44B0FF 22.86%, #8B44FF 48.36%, #FF6644 73.33%, #EBFF70 99.34%)",
                    }}
                  ></div>
                </div>

                <img
                  class="relative rounded-xl"
                  src={data.coverImage.url}
                  alt={data.title}
                />
              </div>
              {/* <div class="mt-10">
                                <svg class="w-auto h-4 text-gray-300" width="172" height="16" viewBox="0 0 172 16" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 11 1)" />
                                <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 46 1)" />
                                <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 81 1)" />
                                <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 116 1)" />
                                <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 151 1)" />
                                <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 18 1)" />
                                <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 53 1)" />
                                <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 88 1)" />
                                <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 123 1)" />
                                <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 158 1)" />
                                <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 25 1)" />
                                <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 60 1)" />
                                <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 95 1)" />
                                <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 130 1)" />
                                <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 165 1)" />
                                <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 32 1)" />
                                <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 67 1)" />
                                <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 102 1)" />
                                <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 137 1)" />
                                <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 172 1)" />
                                <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 39 1)" />
                                <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 74 1)" />
                                <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 109 1)" />
                                <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 144 1)" />
                                </svg>
                            </div> */}

              <RichText
                content={data.content.raw}
                renderers={{
                  h1: ({ children }) => <h1 className="text-gray-900"> </h1>,
                  h2: ({ children }) => (
                    <h2 className="text-lg font-bold mt-7 sm:text-xl xl:text-2xl font-pj">
                      {children}
                    </h2>
                  ),
                  h6: ({ children }) => (
                    <h1 className="mt-6 font-pj font-medium">{children}</h1>
                  ),
                  p: ({ children }) => (
                    <p className="mt-6 text-base font-normal text-gray-700 font-pj">
                      {children}
                    </p>
                  ),
                  ul: ({ children }) => (
                    <ul className="ml-5 pl-5 mt-6 space-y-1 text-sm font-semibold text-gray-700 list-disc list-outside font-pj">
                      {children}
                    </ul>
                  ),
                  bold: ({ children }) => <strong>{children}</strong>,
                  img: ({ src, altText, height, width }) => (
                    <img
                      className="relative rounded-xl mt-8 max-h-96"
                      src={src}
                      alt={data.title}
                    />
                  ),
                }}
              />
              {/* <div class="mt-10">
                            <p class="text-base font-normal leading-7 text-gray-700 font-pj">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa risus sed eget ornare posuere turpis vitae. Id elementum velit posuere vestibulum lectus id eu sollicitudin. Tellus amet bibendum sit mus phasellus quis duis lacus. Enim eget sagittis, auctor iaculis nulla semper. In vitae neque integer integer metus, est, tristique fermentum tristique.
                            </p>

                            <h2 class="mt-12 text-3xl font-bold text-gray-900 font-pj">
                            Get everything from scratch
                            </h2>
                            <p class="mt-6 text-base font-normal leading-7 text-gray-700 font-pj">
                            Massa risus sed eget ornare posuere turpis vitae. Id elementum velit posuere vestibulum lectus id eu sollicitudin. Tellus amet bibendum sit mus phasellus quis duis lacus. Enim eget sagittis, auctor iaculis nulla semper. In vitae neque integer integer metus, est, tristique fermentum tristique.
                            </p>
                            <ul class="pl-5 mt-6 space-y-1 text-base font-normal leading-7 text-gray-700 list-disc list-outside font-pj">
                            <li>
                                Id elementum velit posuere vestibulum lectus id eu sollicitudin.
                            </li>
                            <li>
                                Tellus amet bibendum sit mus phasellus quis duis lacus.
                            </li>
                            <li>
                                Enim eget sagittis, auctor iaculis nulla semper.
                            </li>
                            </ul>

                            <h2 class="mt-12 text-3xl font-bold text-gray-900 font-pj">
                            Choose from 120+ UI blocks
                            </h2>
                            <p class="mt-6 text-base font-normal leading-7 text-gray-700 font-pj">
                            Massa risus sed eget ornare posuere turpis vitae. Id elementum velit posuere vestibulum lectus id eu sollicitudin. Tellus amet bibendum sit mus phasellus quis duis lacus. Enim eget sagittis, auctor iaculis nulla semper. In vitae neque integer integer metus, est, tristique fermentum tristique.
                            </p>
                            <blockquote class="pl-5 mt-8 border-l-2 border-gray-900">
                            <p class="text-lg italic font-medium text-gray-900 font-pj">
                                “Tellus amet bibendum sit mus phasellus quis duis lacus. Enim eget sagittis, auctor iaculis nulla semper. In vitae neque integer integer metus, est, tristique fermentum tristique.”
                            </p>
                            </blockquote>

                            <div class="relative mt-16">
                            <div class="absolute -inset-2">
                                <div class="w-full h-full mx-auto opacity-30 blur-lg filter" style="background: linear-gradient(90deg, #44FF9A -0.55%, #44B0FF 22.86%, #8B44FF 48.36%, #FF6644 73.33%, #EBFF70 99.34%);"></div>
                            </div>

                            <img class="relative rounded-xl" src={require('../../assets/images/art/img-blog.png')} alt="">
                            </div>

                            <h2 class="mt-16 text-3xl font-bold text-gray-900 font-pj">
                            Create your first landing page
                            </h2>
                            <p class="mt-6 text-base font-normal leading-7 text-gray-700 font-pj">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus enim id habitasse nunc at turpis vestibulum pretium ridiculus. Tempus, amet mattis purus molestie urna. Tellus amet nisl, vel eget tincidunt. Viverra libero, etiam rhoncus amet, vulputate et sodales.
                            </p>
                            <p class="mt-6 text-base font-normal leading-7 text-gray-700 font-pj">
                            Cras hendrerit porta donec tincidunt sed elit dictum tellus. Quam aenean elit pretium risus. Ullamcorper proin interdum dui amet eleifend. Justo, morbi elit amet sollicitudin eget felis amet leo.
                            </p>
                            <p class="mt-6 text-base font-normal leading-7 text-gray-700 font-pj">
                            Ac pretium tellus id ut. Netus sed consequat aliquet sed sed. Platea ac nunc ut curabitur et. In tristique montes, aenean nibh tempus viverra.
                            </p>
                        </div> */}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
