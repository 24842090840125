import axios from 'axios';
import { toast } from 'react-toastify';
import { apiEndPoint } from './config';

class FormAPI {

    client = null

    setClient(clientInstance) {
        this.client = clientInstance;
    }
    getClient() {
        return this.client
    }

    async subscribe(e, data, msg) {
        e.preventDefault();
        var instance = axios.create({
            baseURL: apiEndPoint,
            // baseURL: 'http://127.0.0.1:8000/',
            // headers: {
            //     'Authorization': 'Bearer ' + CredsStore.token
            // }
        });
        // const data = {
        //     email: email,
        //     form: 'get started'
        // }
        try {
            const response = await instance.post('curbside/signup-trial/', data)
            // .then((val) => console.log(val)).catch((error) => console.log(error.request))
            toast.dark(msg)
            // console.log(data);
        } catch (e) {
            console.log(e)
            toast.error('Error while signing up. Please try again later.')
        }

    };

}
const formAPI = new FormAPI();
export default formAPI;