import { Link } from 'react-router-dom';
// import Modal from 'react-bootstrap/Modal';

export default function BlogGrid({ data }) {
    return (
        <div>
            <section class="pb-12 bg-white sm:pb-16 lg:pb-20">
                <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div class="grid max-w-5xl grid-cols-1 mx-auto mt-8 text-center sm:mt-16 sm:text-left sm:grid-cols-2 lg:grid-cols-3 gap-y-8 gap-x-8 lg:gap-x-14">
                        {data.map((post, index) => {
                            if (index === 0){
                                return <></>
                            } else {
                                return (
                                    <div class="relative group">
                                        <div class="overflow-hidden rounded-xl aspect-w-16 aspect-h-9 ">
                                            {post?.coverImage == null ?
                                            <img class="object-cover w-full h-full transition-all duration-300 transform group-hover:scale-125" src={require('../../../../assets/images/art/3-old-women-staring-at-phone-blog-1.png')} alt="" />
                                            :
                                            <img class="object-cover w-full h-48 transition-all duration-300 transform group-hover:scale-125" src={post?.coverImage?.url} alt="" />
                                            }
                                        </div>
                                        <p class="mt-6 text-sm font-roboto-medium text-gray-600">
                                            {post.category}
                                        </p>
                                        <p class="mt-2 text-lg line-clamp-2 leading-tight text-gray-900 font-productsans-bold">
                                            {post.title}
                                        </p>
                                        {/* <a href={"/blog/" + index} title="">
                                            <span class="absolute inset-0" aria-hidden="true"></span>
                                        </a> */}
                                        <Link to={"/blog/post/?title=" + post.title}>
                                            <span class="absolute inset-0" aria-hidden="true"></span>
                                        </Link>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>
            </section>
        </div>
    )
}
