import React from "react";
import { RequestDemoButton } from "../../../common";
import { StarIcon } from "@heroicons/react/solid";

export default function AndiePromiseCallToAction() {
    return (
        <section class="py-24 bg-blue-300 mb-28 rounded-3xl overflow-hidden">
            <div class="container max-w-4xl mx-auto px-4">
                <p class="mb-16 andie-section-title text-center">The Andie promise has been delighting great organizations, guests, and hospitality partners.</p>
                <div className="w-40 mx-auto">
                    <RequestDemoButton />
                </div>
                <div class="border-t border-blue-400 mt-16 pt-8 max-w-2xl mx-auto text-center">
                <div class="flex items-center justify-center -m-1 mb-6">
                    <StarIcon className="h-7 text-yellow-500" />
                    <StarIcon className="h-7 text-yellow-500" />
                    <StarIcon className="h-7 text-yellow-500" />
                    <StarIcon className="h-7 text-yellow-500" />
                    <StarIcon className="h-7 text-yellow-500" />
                </div>
                <p class="mb-5 text-base xl:mx-16 font-roboto-medium">&ldquo;Andie has been a huge timesaver for our team every week. From sourcing and scheduling, to ordering and payment; Andie has taken care of everything we need for our weekly lunches.&rdquo;</p>
                <p class="font-productsans-medium text-base">Nikolina Susac</p>
                <p class="font-productsans-medium text-sm text-blue-600">Events & Operations Manager</p>
                <p class="font-roboto-medium text-xs">DMZ @ TMU</p>
                </div>
            </div>
        </section>
    )
}