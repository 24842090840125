import { useState, useEffect } from "react";
import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import AndieLogo from "../../assets/images/andielogo.png";
import { Link } from 'react-router-dom';
import { RequestDemoButton } from '../common';
import DropdownMenu from './DropdownMenu';
import AlternateDropdown from "./AlternateDropdown";
import AndieLogoBlack from '../../assets/images/AndieLogoBlack.svg';
import {
  BookOpenIcon,
  CalendarIcon,
  DeviceMobileIcon,
  DocumentTextIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  SupportIcon,
  XIcon,
  BriefcaseIcon
} from '@heroicons/react/solid';

import restocge from "../../assets/images/header-icons/restaurant-dining.svg";
import cgerestaurant from "../../assets/images/header-icons/restaurant-menu.svg";
import restaurantapp from "../../assets/images/header-icons/restaurant-app.svg";

const solutions = [
  // {
  //   type: '2',
  //   name: 'Drive real-time traffic',
  //   description: 'Connect with nearby customers and potential guests through the Andie app.',
  //   href: '/andie-app',
  //   icon: restaurantapp,
  // },
  {
    type: '1',
    name: 'Corporate guest experiences',
    description: "Enjoy corporate dining and catering experiences in as little as four clicks.",
    href: '/cge',
    icon: cgerestaurant,
    image: true
  },
  {
    type: '2',
    name: 'Earn bigger check sizes',
    description: "Create great corporate experiences and earn more with Andie CGE.",
    href: '/restaurant',
    icon: restocge,
    image: true
  },
]
const resources = [
  {
    name: 'Blog',
    description: 'Read the latest Andie insights and product announcements.',
    href: '/blog',
    icon: BookOpenIcon,
  },
  {
    name: 'Success Stories and Partner Opportunities',
    description: 'Check out our past events and learn more about the benefits of being our restaurant partners',
    href: '/andie-success-stories',
    icon: DocumentTextIcon,
  },
  {
    name: 'Help and FAQs',
    description: 'Get all of your questions answered in our faqs or contact support.',
    href: '/customer-success-center',
    icon: SupportIcon,
  },
]
// const contact = [
//   {
//     name: 'FAQs',
//     description: 'Get all of your questions answered in our faqs or contact support.',
//     href: '/faqform',
//     icon: SupportIcon,
//   },
//   {
//     name: 'Contact Information',
//     description: 'Your all in one stop where you can find all the answers you need',
//     href: '/speak-to-us',
//     icon: PhoneIcon,
//   },
// ]



export default function Header() {
  const [link, setLink] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [link])
  return (
    <>
      <Popover className="top-0 py-2.5 z-20 bg-white andie-page-horizontal shadow-md sticky">
        <div className="container px-4 md:px-12 xl:px-16 mx-auto">
          <div className="flex items-center justify-between">
            <div className="flex-shrink-0">
              <Link onClick={() => setLink("")} to="/" title="logo" className="flex">
                <img className="w-auto h-7" src={AndieLogoBlack} alt="" />
                {/* <img className="w-auto h-9" src={require('../../assets/images/andielogo.png')} alt="" /> */}
              </Link>
            </div>

            <div className="-mr-2 -my-2 lg:hidden">
              <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-700">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>

            <Popover.Group as="nav" className="hidden max-w-2xl lg:flex lg:items-center lg:justify-center lg:space-x-9">
              {/* ^Changed lg:space-x-10 to lg:space-x-9 */}
              <AlternateDropdown title="Solutions" submenuitems={solutions} setLink={(e) => setLink(e)} />
              <Link onClick={() => setLink("promise")} to='/the-andie-promise' className="text-sm font-roboto-medium text-gray-600 transition-all duration-200 hover:text-blue-700">
                The Andie Promise
              </Link>
              <Link onClick={() => setLink("pricing")} to='/pricing' className="text-sm font-roboto-medium text-gray-600 transition-all duration-200 hover:text-blue-700">
                Pricing
              </Link>
              <div class="w-px h-5 bg-gray-300"></div>
              <DropdownMenu title="Resources" submenuitems={resources} onClick={() => setLink("promise")} />
              {/* <Link to='/pricing' className="text-sm font-roboto-medium text-gray-600 transition-all duration-200 hover:text-blue-700">
                Pricing
              </Link>

              <div class="w-px h-5 bg-gray-300"></div> */}
              {/* <Link to='/contact-us' className="text-sm font-roboto-medium text-gray-600 transition-all duration-200 hover:text-blue-700">
                Contact Us
              </Link> */}
              {/* <DropdownMenu title="Contact Us" submenuitems={contact}/> */}
              {/* <Link to='/andie-at-collision' className="text-sm font-roboto-medium text-gray-900 transition-all duration-200 font-pj hover:text-indigo-600">
                      Collision 2022
                  </Link> */}

            </Popover.Group>

            <nav className="hidden lg:flex">
              <RequestDemoButton />
            </nav>

          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 z-50 bg-white transition transform origin-top-right lg:hidden">
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <img
                      className="h-8 w-auto"
                      src={AndieLogo}
                      alt="Andie"
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid sm:grid-cols-2 gap-y-8">
                    <div>
                      <p className="text-sm uppercase font-bold mb-8">Solutions</p>
                      <div className="grid grid-cols-1 gap-y-8">
                        {solutions.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            onClick={() => setLink(item.name)}
                            className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                          >
                            <item.icon className="flex-shrink-0 h-6 w-6 text-blue-700" aria-hidden="true" />
                            <span className="ml-3 text-base font-medium text-gray-900">{item.name}</span>
                          </Link>
                        ))}
                      </div>
                    </div>
                    <div>
                      <p className="text-sm uppercase font-bold mb-8">Resources</p>
                      <div className="grid grid-cols-1 gap-y-8">
                        {resources.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            onClick={() => setLink(item.name)}
                            className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                          >
                            <item.icon className="flex-shrink-0 h-6 w-6 text-blue-700" aria-hidden="true" />
                            <span className="ml-3 text-base font-medium text-gray-900">{item.name}</span>
                          </Link>
                        ))}
                      </div>
                    </div>

                  </nav>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">
                <div className="grid grid-cols-2 gap-y-8 gap-x-8">
                  {/* {
                    <Link to='/pricing' className="text-base font-medium text-gray-900 hover:text-gray-700">
                      Pricing
                    </Link>
                  }
                  {
                    <Link to='/speak-to-us' className="text-base font-medium text-gray-900 hover:text-gray-700">
                      Contact us
                    </Link>
                  } */}
                  {/* {contact.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className="text-base font-medium text-gray-900 hover:text-gray-700"
                    >
                      {item.name}
                    </Link>
                  ))} */}

                  {/* {
                    <Link to='/andie-at-collision' className="text-sm font-roboto-medium text-gray-900 transition-all duration-200 font-pj hover:text-gray-700">
                    Collision 2022
                    </Link>
                  } */}
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  );
}
