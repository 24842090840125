import React from 'react';
import {RestaurantCGEHeader, RestaurantCGEPlatform, RestaurantCGETestimonial, RestaurantCGEList, RestaurantCGEFeatures, RestaurantCGECorporates, RestaurantCGEHowItWorks, RestaurantCGEBlog } from '../../components/pages/general/cge/restaurant';
import { ContactUsForm } from "..";

export default function RestaurantCGE({ blogData }) {
  return (
    <div className="andie-padding-horizontal">
        <RestaurantCGEHeader />
        <RestaurantCGEPlatform />
        <RestaurantCGEHowItWorks />
        <RestaurantCGECorporates />
        <RestaurantCGEFeatures />
        <RestaurantCGETestimonial />
        <RestaurantCGEList />
        <RestaurantCGEBlog blogData={blogData} />
        <ContactUsForm />
    </div>
  )
}
