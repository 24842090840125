import React from "react";
import { CheckIcon } from "@heroicons/react/solid";
import { RequestDemoButton } from "../../../../common";



export default function RestaurantCGEList() {
  const values = ["No hidden or subscription fees", "Quick setup process", "Unlimited bookings and responses"]
  return (
    <section class="border-t border-b border-gray-100 relative mt-24 py-24 overflow-hidden">
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap items-center -m-6">
          <div class="w-full lg:w-1/2 p-6">
            <div class="lg:max-w-lg">
              <h2 class="andie-section-title mb-3 xl:mr-14">Join our network of 200+ partners.</h2>
              <p class="mb-9 andie-section-description text-gray-500">Start earning big with Andie CGE today.</p>
              <ul class="mb-11">
                {values.map((value, index) => (
                  <li class="mb-3 flex items-center space-x-2 andie-paragraph">
                    <CheckIcon className="h-5 text-green-500" />
                    <span>{value}</span>
                  </li>
                ))}
              </ul>
              <div className="w-1/3">
                <RequestDemoButton />
              </div>
            </div>
          </div>
          <div class="w-full lg:w-1/2 p-6">
            <div class="flex flex-wrap justify-center -mx-3 mb-6">
              <div class="w-auto px-3">
                <div class="w-64 p-3 bg-white rounded-2xl shadow-xl">
                  <div class="flex flex-wrap -m-2">
                    <div class="w-auto p-2">
                      <img className="w-12 h-12 p-1 rounded-full object-contain border-4 border-blue-50" src={require("../../../../../assets/images/reslogos/ascari.png")} alt="" />
                    </div>
                    <div class="w-auto p-2">
                      <p class="font-heading text-sm font-productsans-regular text-gray-900">Ascari</p>
                      <div class="flex items-center px-2 py-0.5 mt-1 bg-gray-100 rounded-full">
                        <div className="h-2 w-2 mr-1 bg-blue-600 rounded-full" />
                        <p class="text-xs font-roboto-medium text-gray-700">Fine dining</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap justify-center -mx-3 mb-6">
              <div class="w-auto px-3">
                <div class="w-64 p-3 bg-white rounded-2xl shadow-xl">
                  <div class="flex flex-wrap -m-2">
                    <div class="w-auto p-2">
                      <img className="w-12 h-12 p-1 rounded-full object-contain border-4 border-blue-50" src={require("../../../../../assets/images/reslogos/bymark.jpg")} alt="" />
                    </div>
                    <div class="w-auto p-2">
                      <p class="font-heading text-sm font-productsans-regular text-gray-900">Bymark</p>
                      <div class="flex items-center px-2 py-0.5 mt-1 bg-gray-100 rounded-full">
                        <div className="h-2 w-2 mr-1 bg-blue-600 rounded-full" />
                        <p class="text-xs font-roboto-medium text-gray-700">Fine dining</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-auto px-3">
                <div class="w-64 p-3 bg-white rounded-2xl shadow-xl">
                  <div class="flex flex-wrap -m-2">
                    <div class="w-auto p-2">
                      <img className="w-12 h-12 p-1 rounded-full object-contain border-4 border-blue-50" src={require("../../../../../assets/images/reslogos/ono-poke.png")} alt="" />
                    </div>
                    <div class="w-auto p-2">
                      <p class="font-heading text-sm font-productsans-regular text-gray-900">Ono Poke</p>
                      <div class="flex items-center px-2 py-0.5 mt-1 bg-gray-100 rounded-full">
                        <div className="h-2 w-2 mr-1 bg-blue-600 rounded-full" />
                        <p class="text-xs font-roboto-medium text-gray-700">Bowls</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap justify-center -mx-3 mb-6 xl:-ml-36">
              <div class="w-auto px-3">
                <div class="w-64 p-3 bg-white rounded-2xl shadow-xl">
                  <div class="flex flex-wrap -m-2">
                    <div class="w-auto p-2">
                      <img className="w-12 h-12 p-1 rounded-full object-contain border-4 border-blue-50" src={require("../../../../../assets/images/reslogos/halal-guys.png")} alt="" />
                    </div>
                    <div class="w-auto p-2">
                      <p class="font-heading text-sm font-productsans-regular text-gray-900">The Halal Guys</p>
                      <div class="flex items-center px-2 py-0.5 mt-1 bg-gray-100 rounded-full">
                        <div className="h-2 w-2 mr-1 bg-blue-600 rounded-full" />
                        <p class="text-xs font-roboto-medium text-gray-700">Wraps</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-auto px-3">
                <div class="w-64 p-3 bg-white rounded-2xl shadow-xl">
                  <div class="flex flex-wrap -m-2">
                    <div class="w-auto p-2">
                      <img className="w-12 h-12 p-1 rounded-full object-contain border-4 border-blue-50" src={require("../../../../../assets/images/reslogos/lobsterbar.png")} alt="" />
                    </div>
                    <div class="w-auto p-2">
                      <p class="font-heading text-sm font-productsans-regular text-gray-900">Lobster Burger Bar</p>
                      <div class="inline-flex items-center px-2 py-0.5 mt-1 bg-gray-100 rounded-full">
                        <div className="h-2 w-2 mr-1 bg-blue-600 rounded-full" />
                        <p class="text-xs font-roboto-medium text-gray-700">Seafood</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap justify-center -mx-3">
              <div class="w-auto px-3">
                <div class="w-64 p-3 bg-white rounded-2xl shadow-xl">
                  <div class="flex flex-wrap -m-2">
                    <div class="w-auto p-2">
                      <img className="w-12 h-12 p-1 rounded-full object-contain border-4 border-blue-50" src={require("../../../../../assets/images/reslogos/jsan.webp")} alt="" />
                    </div>
                    <div class="w-auto p-2">
                      <p class="font-heading text-sm font-productsans-regular text-gray-900">Jsan Sushi Bar</p>
                      <div class="flex items-center px-2 py-0.5 mt-1 bg-gray-100 rounded-full">
                        <div className="h-2 w-2 mr-1 bg-blue-600 rounded-full" />
                        <p class="text-xs font-roboto-medium text-gray-700">Sushi</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-auto px-3">
                <div class="w-64 p-3 bg-white rounded-2xl shadow-xl">
                  <div class="flex flex-wrap -m-2">
                    <div class="w-auto p-2">
                      <img className="w-12 h-12 p-1 rounded-full object-contain border-4 border-blue-50" src={require("../../../../../assets/images/reslogos/casatropical.jpg")} alt="" />
                    </div>
                    <div class="w-auto p-2">
                      <p class="font-heading text-sm font-productsans-regular text-gray-900">Casa Tropical</p>
                      <div class="flex items-center px-2 py-0.5 mt-1 bg-gray-100 rounded-full">
                        <div className="h-2 w-2 mr-1 bg-blue-600 rounded-full" />
                        <p class="text-xs font-roboto-medium text-gray-700">Brazilian</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

