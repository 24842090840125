import { AiOutlineClose } from 'react-icons/ai'
import { useState, useEffect } from 'react';
export default function AdditionalUsersModal({ toggle, data, setData }) {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');

    const handleSubmit = () => {
        let newData = {
            first_name: firstName,
            last_name: lastName,
            phone: phone,
            email: email
        }
        data.additional_users.push(newData)
        setData(data)
        toggle();
    };

    useEffect(() => {
        // Disable scrolling when the modal is open
        document.body.style.overflow = 'hidden';

        // Re-enable scrolling when the modal is closed
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <>
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-black/70 min-h-screen flex items-center justify-center w-full">
                <div className="bg-white andie-padding mx-auto ring-1 rounded-2xl">
                    <div className='flex flex-col space-y-4 w-full'>
                        {/* Close button */}
                        <div className='flex justify-end py-2 cursor-pointer'>
                            <AiOutlineClose type='button' onClick={toggle} size={36} />
                        </div>
                        {/* Title */}
                        <div className='flex flex-col justify-center space-y-2'>
                            <h1 className='andie-form-title'>Add additional user</h1>
                            <p className='andie-section-description'>Add an additional user to your company.</p>
                        </div>
                        {/* First name */}
                        <div className='flex flex-col'>
                            <span className='font-semibold'>First name</span>
                            <input onChange={(e) => { setFirstName(e.target.value) }} className='p-2 w-full ring-1' type='text' />
                        </div>
                        {/* Last name */}
                        <div className='flex flex-col'>
                            <span className='font-semibold'>Last name</span>
                            <input onChange={(e) => { setLastName(e.target.value) }} className='p-2 w-full ring-1' type='text' />
                        </div>
                        {/* Phone */}
                        <div className='flex flex-col'>
                            <span className='font-semibold'>Phone</span>
                            <input onChange={(e) => { setPhone(e.target.value) }} className='p-2 w-full ring-1' type='tel' />
                        </div>
                        {/* Email */}
                        <div className='flex flex-col'>
                            <span className='font-semibold'>Email</span>
                            <input onChange={(e) => { setEmail(e.target.value) }} className='p-2 w-full ring-1' type='email' />
                        </div>
                        {/* Buttons */}
                        <div className='flex justify-between'>
                            <button className='px-8 py-2 bg-red-500 text-white ring-1 ring-slate-600 hover:bg-red-700 transition-all duration-200' type='button' onClick={toggle}>Cancel</button>
                            <button className='px-8 py-2 bg-green-500 text-white ring-1 ring-slate-600 hover:bg-green-700 transition-all duration-200' type='button' onClick={handleSubmit}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
