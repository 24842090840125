import React from "react";
import { Link } from "react-router-dom";

export default function PartnerStory({ story }) {
    return (
        <div class="w-full p-4">
            <div class="p-6 h-full bg-blue-50 rounded-xl">
                <div class="flex flex-wrap -m-3">
                    <div class="w-auto p-3"><img className="h-44 w-44 rounded-md object-cover" src={story.image} alt=""/></div>
                    <div class="flex-1 p-3">
                    <div class="flex flex-col justify-between h-full">
                        <div class="flex-initial mb-8">
                            <p class="mb-3 text-xs text-gray-500 font-roboto-bold">{story.cuisine}</p>
                            <Link class="group inline-block mb-4" to={`/success-stories/?=${story.restaurant}`}>
                                <h3 class="line-clamp-3 font-productsans-bold text-xl leading-tight text-gray-900 hover:text-gray-700 xl:w-[90%]">{story.success}</h3>
                            </Link>
                        </div>
                        <div class="flex justify-between items-center">
                            <p class="text-base text-gray-500 font-productsans-regular">{story.restaurant}</p>
                            <Link to={`/success-stories/?=${story.restaurant}`} state={{ story: story }} className="bg-blue-200 h-7 px-4 rounded-full text-sm text-blue-600 font-productsans-medium flex items-center justify-center">
                                Learn more
                            </Link>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}