import React from 'react'
import { Field, ErrorMessage } from 'formik'

export const FormikField = ({ type, name, fieldName, fieldInformation }) => {

    return (
        <>
            <div className='flex items-center'>
                <p className='andie-form-header'>{fieldName}</p>
                <p className='px-2 font-thin mt-1'>{fieldInformation || ''}</p>
            </div>
            <Field
                className='w-full ring-1 ring-slate-200 px-2 py-1'
                type={type || 'text'}
                name={name}
            />
            <div className='text-sm text-red-400 flex gap-x-1 items-center'><ErrorMessage name={name} /></div>
        </>
    )
}
