import { useState } from 'react';
import { Link } from 'react-router-dom';
export default function PrivacyHeader() {
    return (
        <div>
            <section class="andie-padding-horizontal xl:w-3/4 xl:border-r">
                <h1 class="text-center text-2xl andie-section-title mt-8">Privacy Policy</h1>
                <h1 class="text-center font-bold text-2xl">&nbsp;</h1>
                <p class="ml-2 mr-2"><em>Last updated: 2020-08-13</em></p>
                <p className="andie-paragraph"><strong><span>&nbsp;</span></strong></p>
                <p class="text-center">Andie Connected Technologies Inc. (the &ldquo;<strong>Company</strong>&rdquo; or &ldquo;<strong>We</strong>&rdquo;) respect your privacy and are committed to protecting it by complying with this policy.</p>
                <p className="andie-paragraph">&nbsp;</p>
                <p className="andie-paragraph">This policy describes:</p>
                <p className="andie-paragraph">&nbsp;</p>
                <ul class="list-disc ml-10">
                    <li className="andie-paragraph">how we collect, use, disclose and protect the personal information of our customers and website users (&ldquo;<strong>you</strong>&rdquo;);</li>
                    <li className="andie-paragraph">the type of information we may collect from you or that you may provide when you visit the website <Link to="/" title="link"><span class="text-blue-600">www.andie.work&nbsp;</span></Link> (the <strong>&ldquo;Site&rdquo;</strong>) and mobile or desktop applications you download from the Site, or third party applications; and</li>
                    <li className="andie-paragraph">our practices for collecting, using, maintaining, protecting and disclosing that information.</li>
                </ul>
                <p className="andie-paragraph">&nbsp;</p>
                <p className="andie-paragraph">This policy applies:</p>
                <p className="andie-paragraph">&nbsp;</p>
                <ul class="list-disc ml-10">
                    <li className="andie-paragraph">to information we collect, use, or disclose about you on the Site, in email, text and other electronic messages between you and the Site;</li>
                    <li className="andie-paragraph">to mobile and desktop applications you download from the Site, which provide dedicated non-browser-based interaction between you and the Site;</li>
                    <li className="andie-paragraph">when you interact with our advertising and applications on third-party websites and services if those applications or advertising include links to this policy.</li>
                </ul>
                <p className="andie-paragraph">&nbsp;</p>
                <p className="andie-paragraph">The Site may include links to third-party websites, plug-ins, services, social networks, or applications. Clicking on those links or enabling those connections may allow the third party to collect or share data about you. If you follow a link to a third-party website or engage a third-party plugin, please note that these third parties have their own privacy policies and we do not accept any responsibility or liability for these policies. We do not control these third-party websites, and we encourage you to review the privacy policy of every website you visit.</p>
                <p className="andie-paragraph">&nbsp;</p>
            </section>
        </div>
    )
}
