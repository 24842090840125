import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useNavigate, useLocation } from 'react-router-dom';
import { FormikField } from './FormikField';
import { ListView } from './ListView';
import { useState } from 'react';
import { Register_CGE } from '../../../api/Authentication';

export default function OrganizationIformation({ }) {

    const location = useLocation();
    const { email, password } = location.state || {};

    const [data, setData] = useState({
        email: email,
        first_name: 'Victor',
        last_name: 'Singh',
        password: password,
        organization: '',
        industry: '',
        phone: '',
        additional_users: [],
        job_title: '',
        stripe_token: 'xxxxxxxxxxxxxxxxxxxx',
        authorized: true
    })

    const navigate = useNavigate();

    const handleSubmit = async (values) => {
        data.organization = values.organization
        data.industry = values.industry
        data.phone = values.phone
        data.job_title = values.job_title

        await Register_CGE(data);

        console.log(data);
    }

    const OrganizationInformationValidationSchema = Yup.object({
        organization: Yup.string().required().label('Organization'),
        industry: Yup.string().required().label('Industry'),
        additional_users: Yup.array().label('Additional Users'),
        phone: Yup.number().required().label('Phone'),
        job_title: Yup.string().required().label('Job title')
    });

    return (
        <>
            <div className="andie-padding-horizontal flex flex-col justify-center md:mx-[15%] mx-4 my-20">
                <h1 className="andie-section-title">Organization information needed,</h1>
                <p className="andie-section-description andie-padding-vertical">We still require some information about your organization to finish your registration.</p>
                <Formik
                    validationSchema={OrganizationInformationValidationSchema}
                    initialValues={data}
                    onSubmit={handleSubmit}
                >
                    {( ) => (
                        <Form>
                            <FormikField fieldName={'Organizaiton'} name={'organization'} />
                            <FormikField fieldName={'Industry'} name={'industry'} />
                            <FormikField fieldName={'Phone'} name={'phone'} type={'tel'} />
                            <FormikField fieldName={'Job title'} name={'job_title'} />
                            <ListView data={data} setData={setData} />

                            <div className="flex justify-between py-4">
                                <button className='px-6 py-2 bg-green-600 hover:bg-green-900 hover:shadow-md text-white transition duration-200 w-full font-semibold' type='submit'>Finish Registration</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    )
}


