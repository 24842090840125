import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { LoginUser, InitiateGoogleLoginSignup, readToken } from '../../../api/Authentication';
import { FormikField } from '../signup/FormikField';
import { useNavigate } from 'react-router-dom'


export default function LoginForm() {

    const navigate = useNavigate();

    const LoginInformationValidationSchema = Yup.object({
        email: Yup.string().required().email().label('Email'),
        password: Yup.string().required().label("Password"),
    })

    const handleSubmit = async (values) => {
        // console.log(values);
        var loginStatus = await LoginUser(values)

        if (loginStatus === 1) {
            var token = readToken();
            console.log(token);
        } else if (loginStatus === -1) {
            navigate('/complete-login', { state: { email: values.email, password: values.password } });
        }
    }
    return (
        <>
            <div className="ring-2 ring-slate-500 shadow-lg andie-padding bg-white md:absolute md:top-[10%] md:right-[10%] lg:top-[20%] lg:right-[20%]">

                {/* Andie Logo */}
                <img
                    className='w-16 mb-10'
                    src={require('../../../assets/images/andielogo.png')}
                />

                <div className='andie-form-title'>
                    Sign in to your account
                </div>
                <Formik
                    initialValues={{
                        email: '',
                        password: ''
                    }}
                    validationSchema={LoginInformationValidationSchema}
                    onSubmit={handleSubmit}
                >
                    <Form>
                        <FormikField fieldName={'Email'} name={'email'} type={'email'} />
                        <FormikField fieldName={'Password'} name={'password'} type={'password'} />
                        <div className="flex mt-8 py-4">
                            <button className='px-8 py-2 font-semibold bg-green-600 hover:bg-green-900 hover:shadow-md text-white transition duration-200 w-full' type='submit'>
                                Sign in
                            </button>
                        </div>
                        <div className="flex mt-8 py-4">
                            <button onClick={() => InitiateGoogleLoginSignup()} className='px-8 py-2 font-semibold bg-green-600 hover:bg-green-900 hover:shadow-md text-white transition duration-200 w-full' type='button'>
                                Sign in with Google
                            </button>
                        </div>

                    </Form>
                </Formik>
                <div className="mt-16">
                    <div className='text-sm font-thin flex justify-center gap-x-1'>
                        Dont have an account?
                        <a className='font-normal text-blue-600' href='/signup'>Sign up</a>
                    </div>
                </div>
            </div>
        </>
    )
}