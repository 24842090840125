import React, { useState } from "react";
import Card from "./SlideshowCards";
import { initalState } from "./SlideshowItems";
import Slider from "react-slick";

// Carousel slick imports
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function LogoCarousel() {
  const [cards, setCards] = useState(initalState);
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    fade: true,
    arrows: false,
    responsive: [],
  };

  return (
    <div class="w-full h-full">
      <Slider {...settings}>
        {cards.map((card, index) => (
          <Card key={index} prop={card.imgUrl} />
        ))}
      </Slider>
    </div>
  );
}
